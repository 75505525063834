import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../../hooks/useAuthContext'
import { useFetch } from '../../../hooks/useFetch'
import { useEffect, useState } from 'react'
import { Container, Box, Grid, TextField, Typography, Button, Divider, Card, Paper, CardActions, CardContent } from '@mui/material/'
import format from 'date-fns/format'
import DashboardIcon from '@mui/icons-material/Dashboard';
import ViewAccStatus from '../../../components/accounts/ViewAccStatus'
import SelectStudentSharedAccount from '../../../components/SelectStudentSharedAccount'
import EditIcon from '@mui/icons-material/Edit';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ViewSummary from '../../../components/accounts/ViewSummary'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';
function AccountStatus() {



    const [studentId, setStudentId] = useState('')

    const [statusId, setStatusId] = useState('')
    const [fname, setFname] = useState('')
    const [lname, setLname] = useState('')
    const { getData, error, isPending, data: studentAccount } = useFetch(`https://parentapi.bi-oss.co.za/students/accountstatus/${studentId}`, 'GET')
    const navigate = useNavigate()
    const [view, setView] = useState(0)

    useEffect(() => {
        if (studentId) {
            getData()
        }
    }, [studentId])


    useEffect(() => {

        if (studentAccount) {
            studentAccount.map(data => {
                setFname(data.fname)
                setLname(data.lname)
                setStatusId(data.accStatus)
            })


        }
    }, [studentAccount])




    return (
        <>
            {!view && (


                <Container maxWidth="md" >

                    <Box mb={5} />
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} sx={{ border: 'solid', borderWidth: '3px', borderColor: "primary.main", borderRadius: 2 }}>


                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"

                            >
                                <Typography variant="h3" component="h3" color="primary.main" sx={{ textAlign: 'center' }}>
                                    Accounts Status
                                </Typography>

                            </Box>
                        </Grid>




                        <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <SelectStudentSharedAccount studentId={studentId} setStudentId={setStudentId} />

                            </Box>


                        </Grid>

                        {studentId && isPending && (

                            <>
                                <Grid item xs={12}>
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"

                                    >
                                        <Typography variant="h6" component="h3" color="primary">
                                            Fetching account data ...
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} >
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"

                                    >

                                        <CircularProgress />
                                        <Typography variant="h6" component="h3" color="secondary">

                                        </Typography>

                                    </Box>
                                </Grid>

                            </>
                        )}
                        {studentId && !isPending && (



                            < Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <ViewAccStatus statusId={statusId} />

                                </Box>


                            </Grid>
                        )}



                        {studentId && !isPending && (



                            <Grid item xs={12}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >

                                    <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        >

                                            {studentId && (
                                                <Button fullWidth variant="contained" onClick={(e) => setView(1)}>View Summary <SummarizeIcon /></Button>


                                            )}
                                            {!studentId && (
                                                <Button fullWidth variant="contained" disabled>View Summary <DashboardIcon /></Button>


                                            )}

                                        </Box>

                                    </Grid>

                                </Box>

                            </Grid>
                        )}

                        <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >


                            </Box>

                            <Box mb={5} />

                        </Grid>
                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{

                                            maxWidth: '95%',
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}
                                    >
                                        <Button fullWidth variant="contained" onClick={(e) => navigate('/accounts')}><ArrowBackIcon /> Back</Button>


                                    </Box>

                                    <Box mb={3} />

                                </Grid>

                            </Box>

                            <Box mb={3} />

                        </Grid>


                    </Grid>
                </Container >


            )}

            {view === 1 && (

                <ViewSummary setView={setView} studentId={studentId} fname={fname} lname={lname} />
            )}
        </>
    )
}

export default AccountStatus