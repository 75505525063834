import { useState, useEffect } from 'react'
import { Container, Grid, TextField, Button, Typography, createTheme, Box, Link } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import AppAppBar2 from '../components/AppBar2'
import ProductHeroLayout from '../components/ProductHeroLayout';
import { palette } from '@mui/system'
import { useFetchNoUser } from '../hooks/useFetchNoUser'
import CircularProgress from '@mui/material/CircularProgress';
export default function RegisterUser() {


    const images = ['../img/BannerDistance.jpg', '../img/BannerBryanston.jpg', '../img/BannerDistance.jpg', '../img/BannerBryanston.jpg', '../img/BannerDistance.jpg', '../img/BannerBryanston.jpg'];


    const [backgroundImage, setBackgroundImage] = useState('../img/BannerBryanston.jpg')
    const { postData, error, errorMessage, data, isPending } = useFetchNoUser('https://parentapi.bi-oss.co.za/user/create', 'POST')


    useEffect(() => {
        const intervalId = setInterval(() => {
            setBackgroundImage(images[Math.floor(Math.random() * images.length)]);
        }, 9000)

        return () => clearInterval(intervalId);
    }, [])

    const navigate = useNavigate()
    const [email, setEmail] = useState('')

    const [succ, setSucc] = useState('')





    const handleSubmit = (e) => {
        e.preventDefault()
        setSucc('')
        postData({
            email,

        })
    }

    useEffect(() => {
        if (data) {


            setSucc('Account creation successful. An email containing your login credentials has been sent to: ' + email)
            setTimeout(() => { navigate('/login') }, 8000)
        }
    }, [data])




    return (

        <>

            <AppAppBar2 />

            <ProductHeroLayout
                fullWidth
                sxBackground={{
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundColor: '#7fc7d9', // Average color of the background image.
                    fullWidth: '90vh',
                    transition: 'background 3s linear',
                }}
            >
                {/* Increase the network loading priority of the background image. */}
                <img
                    style={{ display: 'none' }}
                    src={backgroundImage}
                    alt="increase priority"
                />
                <Box mb={5} />
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"

                >
                    <Container maxWidth="xs" disableGutters align="center" top="50%" sx={{ backgroundColor: 'white', opacity: '0.7', border: 'Solid', borderWidth: '5px', borderColor: 'primary.main', padding: '30px', borderRadius: 3, marginTop: 3 }}>

                        <form onSubmit={handleSubmit}>
                            <Box mb={5} />
                            <Box mb={3} />
                            <Typography
                                variant="h3"
                                color="textSecondary"
                                component="h2"
                                gutterBottom

                            >
                                Register
                            </Typography>
                            <Box mb={2} />

                            <Typography
                                variant="paragraph"
                                color="textSecondary"
                                component="p"
                                gutterBottom
                                fontFamily={'Arial'}

                            >
                                Parents of currently registered BIC, BIDC and BIP students can create a parent portal account.
                            </Typography>
                            <Typography
                                variant="paragraph"
                                color="textSecondary"
                                component="p"
                                gutterBottom
                                fontFamily={'Arial'}

                            >
                                To create an account, parents must simply enter their email address as supplied during student registration. Once verified, a confirmation email containing your temporary login credentials will be sent to you.
                            </Typography>

                            <Box mb={2} />
                            {!succ &&
                                <TextField
                                    id="emailbox"
                                    label="Email Address:"
                                    variant="outlined"
                                    type="email"
                                    required
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    fullWidth
                                    autoComplete='email'
                                    autoFocus
                                />}


                            {succ &&
                                <TextField
                                    id="emailbox"
                                    label="Email Address:"
                                    variant="outlined"
                                    type="email"
                                    required
                                    disabled
                                    value={email}
                                    fullWidth
                                    autoComplete='email'
                                    autoFocus

                                />}





                            <Box mb={2} />
                            {error && !errorMessage && <p className='error'>Service currently offline. Please try again later.</p>}
                            {errorMessage && <p className='error'>{errorMessage}</p>}
                            {succ && <p className='success'>{succ}</p>}

                            {!isPending && !succ && <Button variant="contained" type="submit" fullWidth>Submit</Button>}

                            {isPending && <Button variant="contained" disabled fullWidth><CircularProgress /></Button>}
                            <Box mb={3} />




                            <Link href="../login">Login?</Link>
                            <Box mb={2} />
                        </form>
                    </Container>
                </Box>
                <Typography variant="body2" color="inherit" sx={{ mt: 2, fontStyle: 'italic' }}>
                    <a class="buttonIllum"><span> Illuminate et Optimus</span></a>
                </Typography>
            </ProductHeroLayout>

        </>
    )
}
