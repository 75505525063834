import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../hooks/useAuthContext'
import { useFetch } from '../../hooks/useFetch'
import { useEffect, useState } from 'react'
import { Container, Box, Grid, TextField, Typography, Button, Divider, Card, Paper, CardActions, CardContent } from '@mui/material/'
import CircularProgress from '@mui/material/CircularProgress';
import SelectedStudent from '../SelectedStudent'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import QuoteTotalFooter from '../cambridge/QuoteTotalFooter'
import CloseIcon from '@mui/icons-material/Close';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
    GridHeader,
    GridFooter,
    GridFooterContainer,
    GridFooterPlaceholder

} from '@mui/x-data-grid';


function ViewDCQuote({ sessionId, academicYear, studentId, campusId, setViewSelect, setViewTerms, setViewQuote, setViewOrder }) {
    const navigate = useNavigate()
    const { user, parentID } = useAuthContext()
    const [tableData, setTableData] = useState([])

    const { postData, error, isPending, data: quoteData } = useFetch('https://parentapi.bi-oss.co.za/studdcexam/generatequote')

    useEffect(() => {
        if (studentId && campusId) {
            postData(
                {
                    parentId: parentID,
                    studentId,
                    campusId,
                    sessionId,
                    academicYear,
                    updatedBy: user.email
                }

            )
        }
    }, [studentId, campusId])


    useEffect(() => {
        if (quoteData) {
            setTableData(quoteData)
        }
    }, [quoteData])


    const columns = [

        { field: 'academicYear', headerName: 'Year:', width: 100, editable: true },
        { field: 'session', headerName: 'Session:', width: 100, editable: true },

        { field: 'paperCode', headerName: 'Code:', width: 120, editable: true },
        { field: 'description', headerName: 'Paper Description:', width: 300, editable: true },


        { field: 'examTotal', headerName: 'Exam Fee:', width: 150, editable: true },




    ];



    const handleOnCellClick = (e) => {
        // window.open(e.row.fileURL, '_blank')
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setViewQuote(0)
        setViewOrder(1)
    }

    const handleChangeExams = (e) => {
        e.preventDefault()
        setViewSelect(1)
        setViewTerms(0)
        setViewQuote(0)
    }




    return (
        <>
            {isPending && (

                <Container maxWidth="md" >

                    <Box mb={5} />



                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} sx={{ border: 'solid', borderWidth: '3px', borderColor: "primary.main", borderRadius: 2 }}>

                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"

                            >

                                <CircularProgress />
                                <Typography variant="h6" component="h3" color="primary.main">
                                    Generating quotation ........
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            )}

            {!isPending && (

                <Container maxWidth="md" >

                    <Box mb={5} />



                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} sx={{ border: 'solid', borderWidth: '3px', borderColor: "primary.main", borderRadius: 2 }}>


                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"

                            >
                                <Typography variant="h3" component="h3" color="primary.main" sx={{ textAlign: 'center' }}>
                                    Distance College Examination Entry Quote
                                </Typography>

                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"

                            >
                                <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"

                                    >
                                        <SelectedStudent studentId={studentId} />

                                    </Box>


                                </Grid>






                            </Box>


                        </Grid>




                        {!isPending && !error && tableData && tableData.length > 0 && (
                            <Grid item xs={12} minWidth={0}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >
                                    <DataGrid
                                        rows={tableData}
                                        columns={columns}



                                        sx={{ color: 'primary.main', borderColor: 'primary.main', borderWidth: 3, fontWeight: 'bold', m: 1, align: 'center', headerAlign: 'center', autoCapitalize: 'words' }}
                                        disableRowSelectionOnClick={true}
                                        disableColumnSelector={true}
                                        hideFooterRowCount
                                        hideFooterSelectedRowCount
                                        hideFooterPagination
                                        getRowId={(row) => row.id}
                                        autoHeight
                                        pageSize={(row) => row.length}
                                        components={{

                                            Footer: () => <QuoteTotalFooter studentId={studentId} campusId={campusId} sessionId={sessionId} academicYear={academicYear} />
                                        }}


                                    />
                                </Box>
                            </Grid>

                        )}

                        {!isPending && !error && tableData && tableData.length === 0 && (
                            <Grid item xs={12}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >


                                    <Typography variant="body2" component="p" color="primary">
                                        <b>There are currently no authorisation for the selected student.</b>


                                    </Typography>

                                </Box>
                            </Grid>

                        )}

                        <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Button fullWidth variant="contained" onClick={handleChangeExams}>Change Selection <ChangeCircleIcon /></Button>


                            </Box>

                        </Grid>

                        <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Button fullWidth variant="contained" onClick={handleSubmit}>Checkout<ShoppingCartCheckoutIcon /></Button>


                            </Box>


                            <Box mb={5} />
                        </Grid>


                        <Grid item xs={12} >

                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{

                                            maxWidth: '95%',
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}
                                    >
                                        <Button fullWidth variant="contained" onClick={(e) => navigate('/dcexam')}><CloseIcon /> Close</Button>


                                    </Box>


                                    <Box mb={5} />
                                </Grid>

                            </Box>


                            <Box mb={5} />
                        </Grid>

                    </Grid >






                </Container >

            )
            }

        </>
    )
}

export default ViewDCQuote