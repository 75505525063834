import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../../hooks/useAuthContext'
import { useFetch } from '../../../hooks/useFetch'
import { useEffect, useState } from 'react'
import { Container, Box, Grid, TextField, Typography, Button, Divider } from '@mui/material/'
import DashboardIcon from '@mui/icons-material/Dashboard';
import SelectStudent from '../../../components/SelectStudent'
import SelectCampusByStudent from '../../../components/SelectCampusByStudent'
import { NumericFormat } from 'react-number-format';
import SendIcon from '@mui/icons-material/Send';
import SelectedStudent from '../../../components/SelectedStudent'
import CircularProgress from '@mui/material/CircularProgress';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CancelIcon from '@mui/icons-material/Cancel';
function TuitionPayment() {

    const { user, parentID } = useAuthContext()

    const [studentId, setStudentId] = useState('')
    const [campusId, setCampusId] = useState('')
    const [amount, setAmount] = useState(0)
    const navigate = useNavigate()
    const [process, setProcess] = useState(0)
    const { postData, error, errorMessage, data, isPending } = useFetch('https://parentapi.bi-oss.co.za/tuitionorders/create', 'POST')


    const [payReqId, setPayReqId] = useState('')
    const [returnChecksum, setReturnChecksum] = useState('')
    const handleSubmit = (e) => {
        e.preventDefault()
        postData({
            parentId: parentID,
            studentId,
            campusId,
            amount

        })
    }


    useEffect(() => {
        if (data) {

            data.map(payorder => (



                setPayReqId(payorder.paymentReqId),
                setReturnChecksum(payorder.returnChecksum)



            ))
            setProcess(1)
        }
    }, [data])


    return (
        <>




            {isPending && (
                < Container maxWidth="md"  >

                    <Box mb={5} />
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} sx={{ border: 'solid', borderWidth: '3px', borderColor: 'primary.main', borderRadius: 2 }}>

                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="h3" component="h3" color="primary.main" sx={{ textAlign: 'center' }}>
                                    Processing Payment Order.
                                </Typography>



                            </Box>


                        </Grid>
                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="h3" component="h3" color="primary.main" sx={{ textAlign: 'center' }}>
                                    Please wait...  <CircularProgress />
                                </Typography>



                            </Box>


                        </Grid>
                    </Grid>
                </Container>
            )}
            {!process && !isPending && (
                <form onSubmit={handleSubmit}>


                    < Container maxWidth="md"  >





                        <Box mb={5} />
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} sx={{ border: 'solid', borderWidth: '3px', borderColor: 'primary.main', borderRadius: 2 }}>

                            <Grid item xs={12}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <Typography variant="h3" component="h3" color="primary.main" sx={{ textAlign: 'center' }}>
                                        Tuition Payments
                                    </Typography>



                                </Box>


                            </Grid>
                            <Grid item xs={12}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>
                                        Parents and Guardians can make tuition payments directly into each students account via the BICS Parent Portal. The portal accepts credit card, debit card or secure eft (SID) payments and allocates them directly to the selected account. Please note, each student maintains a separate account.
                                    </Typography>



                                </Box>


                            </Grid>
                            <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <SelectStudent studentId={studentId} setStudentId={setStudentId} />


                                </Box>

                            </Grid>

                            <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <SelectCampusByStudent studentId={studentId} campusId={campusId} setCampusId={setCampusId} />


                                </Box>

                            </Grid>


                            {amount < 0 && (
                                <Grid item xs={12}>

                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{

                                            maxWidth: '95%',
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}
                                    >
                                        <Typography class="error" variant="body2" component="p" color="error.main" sx={{ textAlign: 'center' }}>
                                            The transaction amount cannot be a negative value.
                                        </Typography>

                                    </Box>

                                    {/* <Box mb={5} /> */}
                                </Grid>

                            )}

                            <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >



                                    <NumericFormat
                                        customInput={TextField}
                                        onValueChange={(values) => setAmount(values.value)}
                                        value={amount}
                                        // you can define additional custom props that are all forwarded to the customInput e. g.
                                        variant="outlined"
                                        decimalScale={2}
                                        fullWidth
                                        prefix="R  "
                                        label="Transaction Amount"
                                        required
                                        thousandSeparator={true}
                                    />

                                </Box>

                                {/* <Box mb={5} /> */}
                            </Grid>


                            <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >


                                    {amount > 0 && !isPending && campusId && studentId && (
                                        <Button fullWidth type="submit" color="primary" variant="contained">Process Payment<SendIcon /></Button>


                                    )}
                                    {(amount <= 0 || !amount || amount === "0" || isPending || !campusId || !studentId) && (
                                        <Button fullWidth disabled color="primary" variant="contained">Process Payment<SendIcon /></Button>


                                    )}




                                </Box>


                            </Grid>


                            <Grid item xs={12}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >

                                    <Box mb={5} />


                                </Box>


                            </Grid>

                            <Grid item xs={12}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        >
                                            <Button fullWidth variant="contained" onClick={(e) => navigate('/accounts')}><ArrowBackIcon /> Back</Button>


                                        </Box>

                                        <Box mb={3} />
                                    </Grid>


                                </Box>


                            </Grid>



                        </Grid>

                    </Container>
                </form>
            )}

            {process === 1 && (
                <form action="https://secure.paygate.co.za/payweb3/process.trans" method="POST" >
                    <>
                        < Container maxWidth="md"  >

                            <Box mb={5} />
                            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} sx={{ border: 'solid', borderWidth: '3px', borderColor: 'primary.main', borderRadius: 2 }}>

                                <Grid item xs={12}>

                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{

                                            maxWidth: '95%',
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}
                                    >
                                        <Typography variant="h3" component="h3" color="primary.main" sx={{ textAlign: 'center' }}>
                                            Process Payment
                                        </Typography>



                                    </Box>


                                </Grid>

                                <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{

                                            maxWidth: '95%',
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}
                                    >
                                        <SelectedStudent studentId={studentId} />


                                    </Box>

                                </Grid>
                                <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{

                                            maxWidth: '95%',
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}
                                    >
                                        <TextField

                                            id="amount"
                                            label="Payment Amount"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            value={"R " + amount}
                                            fullWidth
                                            required
                                        />
                                    </Box>


                                </Grid>
                                {/* <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{

                                            maxWidth: '95%',
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}
                                    >
                                        <Button fullWidth variant="contained" onClick={(e) => navigate('/accounts')}>Cancel <DashboardIcon /></Button>


                                    </Box>

                                </Grid> */}

                                <Grid item xs={12}>

                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{

                                            maxWidth: '95%',
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}
                                    >
                                        <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>
                                            <b>Credit Card Payments:</b> Please ensure your credit / debit card is enabled for online transactions before you proceed. For further information, please contact your banking provider.

                                        </Typography>



                                    </Box>


                                </Grid>
                                <Grid item xs={12}>

                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{

                                            maxWidth: '95%',
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}
                                    >
                                        <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>
                                            <b>Secure EFT</b> payments can be completed with the following banking providers: Absa, Capitec, FNB, Investec, Nedbank and Standard Bank.
                                        </Typography>



                                    </Box>


                                </Grid>
                                <Grid item xs={12}>

                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{

                                            maxWidth: '95%',
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}
                                    >
                                        <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>
                                            EFT payments must be completed within our secure EFT portal to ensure the applicaiton is processed. Manual EFT payments will not be processed and do not secure entry.


                                        </Typography>



                                    </Box>


                                </Grid>
                                <Grid item xs={12}>

                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{

                                            maxWidth: '95%',
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}
                                    >
                                        <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>
                                            <b>Click Pay Now to make a Secure EFT or Credit Card Payment.</b>

                                        </Typography>



                                    </Box>


                                </Grid>
                                <Grid item xs={12}>

                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{

                                            maxWidth: '95%',
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}
                                    >
                                        <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{

                                                    maxWidth: '95%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            >



                                                <Button fullWidth type="submit" color="primary" variant="contained">Pay Now<SendIcon /></Button>





                                            </Box>

                                            <Box mb={3} />

                                        </Grid>


                                    </Box>


                                </Grid>



                                <Grid item xs={12}>

                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{

                                            maxWidth: '95%',
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}
                                    >
                                        <img src="../../img/paylogo/PayGate-Payment-Method-Logo-American-Express.jpg" />

                                        <img src="../../img/paylogo/PayGate-Payment-Method-Logo-SiD-Secure-EFT.jpg" />

                                        <img src="../../img/paylogo/PayGate-Payment-Method-Logo-Mastercard.jpg" />

                                        <img src="../../img/paylogo/PayGate-Payment-Method-Logo-Diners.jpg" />
                                        <img src="../../img/paylogo/PayGate-Payment-Method-Logo-Visa.jpg" />
                                    </Box>


                                </Grid>

                                <Grid item xs={12}>

                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{

                                            maxWidth: '95%',
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}
                                    >
                                        <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{

                                                    maxWidth: '95%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            >



                                                <Button fullWidth variant="contained" onClick={(e) => navigate('/accounts')}><CancelIcon /> Cancel </Button>




                                            </Box>

                                            <Box mb={3} />

                                        </Grid>


                                    </Box>


                                </Grid>
                            </Grid>
                        </Container>
                        <input type="hidden" name="PAY_REQUEST_ID" value={payReqId} />
                        <input type="hidden" name="CHECKSUM" value={returnChecksum} />

                    </>

                </form>
            )}

        </>

    )
}

export default TuitionPayment