import React from 'react'
import { useFetch } from '../hooks/useFetch'
import { OutlinedInput, InputLabel, FormControl, Select, MenuItem } from '@mui/material/'

function SelectRace({ raceId, setRaceId, disabled }) {

    const { error, isPending, data } = useFetch('https://parentapi.bi-oss.co.za/shared/race')


    return (
        <FormControl fullWidth>
            <InputLabel id="selectrace">Select Race*</InputLabel>

            <Select


                labelId="race"
                id="race"

                onChange={(e) => setRaceId(e.target.value)}
                input={<OutlinedInput label="selectrace" />}
                required
                value={raceId}
                disabled={disabled}
            >
                {!error && !isPending && data && data.map(data => (

                    <MenuItem key={data.id} value={data.id}>{data.race}</MenuItem>
                ))}

            </Select>
        </FormControl>
    )
}

export default SelectRace