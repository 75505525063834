import React from 'react'
import { useFetch } from '../hooks/useFetch'
import { OutlinedInput, InputLabel, FormControl, Select, MenuItem } from '@mui/material/'
function SelectSALang({ languageId, setLanguageId, disabled, label }) {


    const { error, isPending, data } = useFetch('https://parentapi.bi-oss.co.za/shared/salanguages')
    return (


        <FormControl fullWidth>
            <InputLabel id="homeLang">{label}</InputLabel>

            <Select


                labelId="language"
                id="language"

                onChange={(e) => setLanguageId(e.target.value)}
                input={<OutlinedInput label="homeLang" />}
                required
                value={languageId}
                disabled={disabled}
            >
                {!error && !isPending && data && data.map(data => (

                    <MenuItem key={data.id} value={data.id}>{data.language}</MenuItem>
                ))}

            </Select>
        </FormControl>
    )
}

export default SelectSALang