import { useState, useEffect } from 'react'
import { Container, Grid, TextField, Button, Typography, createTheme, Box, Link } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import AppAppBar2 from '../components/AppBar2'
import ProductHeroLayout from '../components/ProductHeroLayout';
import { palette } from '@mui/system'
import { useFetchNoUser } from '../hooks/useFetchNoUser'
import CircularProgress from '@mui/material/CircularProgress';
export default function ForgotPass() {



    const images = ['../img/BannerDistance.jpg', '../img/BannerBryanston.jpg', '../img/BannerDistance.jpg', '../img/BannerBryanston.jpg', '../img/BannerDistance.jpg', '../img/BannerBryanston.jpg'];

    const [backgroundImage, setBackgroundImage] = useState('../img/BannerBryanston.jpg')


    const { postData, error: error2, errorMessage, data, isPending: isPending2 } = useFetchNoUser('https://parentapi.bi-oss.co.za/user/emailcheck', 'POST')
    const [isPending, setIsPending] = useState(null)

    useEffect(() => {
        if (errorMessage) {
            setError(errorMessage)
            setIsPending(null)
        }
    }, [errorMessage])


    useEffect(() => {
        const intervalId = setInterval(() => {
            setBackgroundImage(images[Math.floor(Math.random() * images.length)]);
        }, 9000)

        return () => clearInterval(intervalId);
    }, [])


    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const auth = getAuth();
    const [error, setError] = useState('')
    const [succ, setSucc] = useState('')





    const handleSubmit = (e) => {
        e.preventDefault()
        setIsPending(1)
        setError('')
        setSucc('')
        postData({
            email
        })
    }


    useEffect(() => {
        if (data) {

            sendRest()
        }
    }, [data])



    const sendRest = (e) => {

        sendPasswordResetEmail(auth, email)
            .then(() => {

                setSucc('A password rest email has been sent to: ' + email)
                setIsPending(null)
                setTimeout(() => { navigate('/login') }, 8000)
            })
            .catch((err) => {
                setError(err.code)
                setIsPending(null)
                // ..
            })
    }


    return (

        <>

            <AppAppBar2 />

            <ProductHeroLayout
                fullWidth
                sxBackground={{
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundColor: '#7fc7d9', // Average color of the background image.
                    fullWidth: '90vh',
                    transition: 'background 3s linear',

                }}
            >
                {/* Increase the network loading priority of the background image. */}
                <img
                    style={{ display: 'none' }}
                    src={backgroundImage}
                    alt="increase priority"
                />
                <Box mb={5} />
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"

                >
                    <Container maxWidth="xs" disableGutters align="center" top="50%" sx={{ backgroundColor: 'white', opacity: '0.7', border: 'Solid', borderWidth: '5px', borderColor: 'primary.main', padding: '30px', borderRadius: 3, marginTop: 3 }}>

                        <form onSubmit={handleSubmit}>
                            <Box mb={5} />
                            <Box mb={3} />
                            <Typography
                                variant="h3"
                                color="textSecondary"
                                component="h2"
                                gutterBottom

                            >
                                Reset Password
                            </Typography>
                            <Box mb={2} />

                            <Typography
                                variant="paragraph"
                                color="textSecondary"
                                component="p"
                                gutterBottom
                                fontFamily={'Arial'}

                            >
                                To reset your password, enter your email address in the below field and click submit. An email will be sent to you with a link to change your password.  Please note, passwords must be at least 8 characters long and must contain capital letters and numbers.
                            </Typography>

                            <Box mb={2} />
                            {!succ &&
                                <TextField
                                    id="emailbox"
                                    label="Email Address:"
                                    variant="outlined"
                                    type="email"
                                    required
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    fullWidth
                                    autoComplete='email'
                                    autoFocus
                                />}


                            {succ &&
                                <TextField
                                    id="emailbox"
                                    label="Email Address:"
                                    variant="outlined"
                                    type="email"
                                    required
                                    disabled
                                    value={email}
                                    fullWidth
                                    autoComplete='email'
                                    autoFocus

                                />}

                            <Box mb={2} />



                            <Box mb={2} />
                            {error && <p className='error'>{error}</p>}
                            {succ && <p className='success'>{succ}</p>}

                            {!isPending && !succ && <Button variant="contained" type="submit" fullWidth>Submit</Button>}

                            {isPending && <Button variant="contained" disabled fullWidth><CircularProgress /></Button>}
                            <Box mb={3} />

                            {error && (

                                <>
                                    <Typography
                                        variant="paragraph"
                                        color="textSecondary"
                                        component="p"
                                        gutterBottom
                                        fontFamily={'Arial'}

                                    >
                                        or </Typography>
                                    <Button variant="contained" href="./register" fullWidth>Register Account</Button>
                                    <Box mb={3} />
                                </>
                            )}



                            <Link href="../login">Login?</Link>
                            <Box mb={2} />
                        </form>
                    </Container>
                </Box>
                <Typography variant="body2" color="inherit" sx={{ mt: 2, fontStyle: 'italic' }}>
                    <a class="buttonIllum"><span> Illuminate et Optimus</span></a>
                </Typography>
            </ProductHeroLayout>

        </>
    )
}
