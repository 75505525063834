
import { Container, Box, Grid, TextField, Typography, Button, Divider } from '@mui/material/'
import SendIcon from '@mui/icons-material/Send';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../hooks/useAuthContext'
import { useFetch } from '../../hooks/useFetch'
import { useEffect, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import CancelIcon from '@mui/icons-material/Cancel';
function OrderTotal({ studentId, campusId, setViewOrder, setViewQuote, venueId, sessionId, academicYear }) {

    const navigate = useNavigate()
    const { user, parentID } = useAuthContext()
    const { postData, error, isPending, data: orderData } = useFetch('https://parentapi.bi-oss.co.za/studcambridge/createorder')



    useEffect(() => {
        if (studentId && campusId && parentID) {
            postData({
                studentId,
                campusId,
                parentId: parentID,
                createdBy: user.email,
                venueId,
                sessionId,
                academicYear

            })

        }
    }, [studentId, campusId, parentID, academicYear, sessionId])


    const handleRetry = (e) => {
        e.preventDefault()
        postData({
            studentId,
            campusId,
            parentId: parentID,
            createdBy: user.email

        })
    }


    const [amount, setAmount] = useState('')
    const [returnChecksum, setChecksum] = useState('')
    const [paymentReqId, setReqId] = useState('')
    const [orderId, setOrderId] = useState('')

    useEffect(() => {
        if (orderData) {
            console.log(orderData)
            orderData.map(order => (



                setReqId(order.paymentReqId),
                setChecksum(order.returnChecksum),
                setAmount(order.amount),
                setOrderId(order.orderId)



            ))


        }
    }, [orderData])
    return (

        <>

            {isPending && (

                <Container maxWidth="lg" >

                    <Box mb={5} />



                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} sx={{ border: 'solid', borderWidth: '3px', borderColor: "primary.main", borderRadius: 2 }}>

                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"

                            >

                                <CircularProgress />
                                <Typography variant="h6" component="h3" color="primary.main">
                                    Generating payment order ........
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            )}


            {!isPending && !error && (




                <form action="https://secure.paygate.co.za/payweb3/process.trans" method="POST" >
                    <>
                        < Container maxWidth="md"  >

                            <Box mb={5} />
                            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} sx={{ border: 'solid', borderWidth: '3px', borderColor: 'primary.main', borderRadius: 2 }}>

                                <Grid item xs={12}>

                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{

                                            maxWidth: '95%',
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}
                                    >
                                        <Typography variant="h3" component="h3" color="primary.main" sx={{ textAlign: 'center' }}>
                                            Process Payment
                                        </Typography>



                                    </Box>


                                </Grid>

                                <Grid item xs={12}>

                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{

                                            maxWidth: '95%',
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}
                                    >
                                        <Typography variant="h3" component="h3" color="primary.main" sx={{ textAlign: 'center' }}>
                                            (Cambridge External Assessment Application)
                                        </Typography>



                                    </Box>


                                </Grid>
                                <Grid item xs={12}>

                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{

                                            maxWidth: '95%',
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}
                                    >
                                        <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{

                                                    maxWidth: '95%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            >
                                                {/* <TextField

                                            id="amount"
                                            label="Total Amount"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            value={"R " + amount}
                                            fullWidth

                                        /> */}

                                                <Typography variant="h6" component="h6" color="primary.main" sx={{ textAlign: 'center' }}>
                                                    Total Payment Amount: <u>R {new Intl.NumberFormat().format(amount)}</u>
                                                </Typography>

                                            </Box>


                                        </Grid>


                                    </Box>


                                </Grid>




                                <Grid item xs={12}>

                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{

                                            maxWidth: '95%',
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}
                                    >
                                        <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>
                                            <b>Credit Card Payments:</b> Please ensure your credit / debit card is enabled for online transactions before you proceed. For further information, please contact your banking provider.

                                        </Typography>



                                    </Box>


                                </Grid>
                                <Grid item xs={12}>

                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{

                                            maxWidth: '95%',
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}
                                    >
                                        <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>
                                            <b>Secure EFT</b> payments can be completed with the following banking providers: Absa, Capitec, FNB, Investec, Nedbank and Standard Bank.
                                        </Typography>



                                    </Box>


                                </Grid>
                                <Grid item xs={12}>

                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{

                                            maxWidth: '95%',
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}
                                    >
                                        <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>
                                            EFT payments must be completed within our secure EFT portal to ensure the applicaiton is processed. Manual EFT payments will not be processed and do not secure entry.


                                        </Typography>



                                    </Box>


                                </Grid>
                                <Grid item xs={12}>

                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{

                                            maxWidth: '95%',
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}
                                    >
                                        <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>
                                            <b>Click Pay Now to make a Secure EFT or Credit Card Payment.</b>

                                        </Typography>



                                    </Box>


                                </Grid>
                                <Grid item xs={12}>

                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{

                                            maxWidth: '95%',
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}
                                    >
                                        <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{

                                                    maxWidth: '95%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            >



                                                <Button fullWidth type="submit" color="primary" variant="contained">Pay Now <SendIcon /></Button>





                                            </Box>

                                            <Box mb={3} />

                                        </Grid>


                                    </Box>


                                </Grid>



                                <Grid item xs={12}>

                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{

                                            maxWidth: '95%',
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}
                                    >
                                        <img src="../../img/paylogo/PayGate-Payment-Method-Logo-American-Express.jpg" />

                                        <img src="../../img/paylogo/PayGate-Payment-Method-Logo-SiD-Secure-EFT.jpg" />

                                        <img src="../../img/paylogo/PayGate-Payment-Method-Logo-Mastercard.jpg" />

                                        <img src="../../img/paylogo/PayGate-Payment-Method-Logo-Diners.jpg" />
                                        <img src="../../img/paylogo/PayGate-Payment-Method-Logo-Visa.jpg" />
                                    </Box>


                                </Grid>

                                <Grid item xs={12}>

                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{

                                            maxWidth: '95%',
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}
                                    >
                                        <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{

                                                    maxWidth: '95%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            >



                                                <Button fullWidth variant="contained" onClick={(e) => navigate('/cambridge')}><CancelIcon /> Cancel</Button>




                                            </Box>

                                            <Box mb={3} />

                                        </Grid>


                                    </Box>


                                </Grid>
                            </Grid>
                        </Container>
                        <input type="hidden" name="PAY_REQUEST_ID" value={paymentReqId} />
                        <input type="hidden" name="CHECKSUM" value={returnChecksum} />

                    </>

                </form>
            )}
        </>
    )
}

export default OrderTotal