import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../hooks/useAuthContext'
import { useFetch } from '../../hooks/useFetch'
import { useEffect, useState } from 'react'
import { Container, Box, Grid, TextField, Typography, Button, Divider, Card, Paper, CardActions, CardContent } from '@mui/material/'
import CloseIcon from '@mui/icons-material/Close';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
    GridHeader,
    GridFooter,
    GridFooterContainer,
    GridFooterPlaceholder

} from '@mui/x-data-grid';
import format from 'date-fns/format'
import UpdateUserPassword from '../../components/profile/UpdateUserPassword'
import EditProfile from '../../components/profile/EditProfile'
import EditIcon from '@mui/icons-material/Edit';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DataGridView from '../../components/DataGridView';
import PageviewIcon from '@mui/icons-material/Pageview';
import PreviewIcon from '@mui/icons-material/Preview';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { toHaveAccessibleDescription } from '@testing-library/jest-dom/matchers'
import SummaryFooter from './SummaryFooter'
import CircularProgress from '@mui/material/CircularProgress';
function ViewSummary({ studentId, setView, fname, lname }) {

    const { user, accessLevel, parentID, token } = useAuthContext()
    const [edit, setEdit] = useState(0)
    const [change, setChange] = useState(0)
    const [tableData, setTableData] = useState()

    const { error, isPending, data: transactionData } = useFetch(`https://parentapi.bi-oss.co.za/students/accountsummary/${studentId}`)

    const navigate = useNavigate()

    const classes = {};
    const [total, setTotal] = useState('')
    const testString = toString(studentId)


    useEffect((tableData) => {
        if (transactionData) {
            setTableData(transactionData)

        }


    }, [transactionData])



    const columns = [
        { field: 'trnDate', headerName: 'Date:', fwidth: 120, IsReadOnly: true, valueFormatter: params => { return format(new Date(params.value), 'dd/MM/yy') } },

        { field: 'description', headerName: 'Description:', IsReadOnly: true, width: 300, },
        { field: 'academicYear', headerName: 'Academic Year:', width: 120, IsReadOnly: true },

        { field: 'campus', headerName: 'Campus/School:', width: 170, IsReadOnly: true, },

        { field: 'amount', headerName: 'Amount:', width: 200, IsReadOnly: true, },



    ];


    return (
        <Container maxWidth="md" >

            <Box mb={5} />
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} sx={{ border: 'solid', borderWidth: '3px', borderColor: "primary.main", borderRadius: 2 }}>



                <Grid item xs={12}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"

                    >
                        <Typography variant="h3" component="h3" color="primary.main" sx={{ textAlign: 'center' }}>
                            Account Summary
                        </Typography>

                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"

                    >
                        <Typography variant="h6" component="h6" color="primary.main" sx={{ textAlign: 'center' }}>
                            (Current Academic Year)
                        </Typography>

                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"

                    >
                        <Typography variant="body2" component="p" color="primary.main" sx={{ margin: "5px", textAlign: 'center' }}>
                            See below, account summary for {fname} {lname} for the current academic year.

                        </Typography>

                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"

                    >
                        <Typography variant="h6" component="h6" color="primary.main" sx={{ textAlign: 'center' }}>
                            Account: {studentId} ({fname} {lname})
                        </Typography>

                    </Box>
                </Grid>

                {isPending && !error && (
                    <>
                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"

                            >
                                <Typography variant="h6" component="h3" color="primary">
                                    Fetching transaction list ...
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} >
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"

                            >

                                <CircularProgress />
                                <Typography variant="h6" component="h3" color="secondary">

                                </Typography>

                            </Box>
                        </Grid>
                    </>

                )}

                {error && (
                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"

                        >
                            <Typography variant="h6" component="h3" color="error">
                                Unable to fetch account summary. You may be offline. Please try again.


                            </Typography>
                        </Box>
                    </Grid>
                )}

                {!isPending && !error && tableData && tableData.length > 0 && (
                    <Grid item xs={12} minWidth={0} >
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"

                        >
                            <DataGrid
                                rows={tableData}
                                columns={columns}



                                sx={{ color: 'primary.main', borderColor: 'primary.main', borderWidth: 3, fontWeight: 'bold', m: 1, align: 'center', headerAlign: 'center', autoCapitalize: 'words' }}
                                disableRowSelectionOnClick={true}
                                disableColumnSelector={true}
                                getRowId={(row) => row.id}
                                autoHeight
                                pageSize={(row) => row.length}

                                hideFooterRowCount
                                hideFooterSelectedRowCount
                                hideFooterPagination

                                components={{

                                    Footer: () => <SummaryFooter studentId={studentId} />
                                }}


                            />

                        </Box>
                    </Grid>

                )}

                {!isPending && !error && tableData && tableData.length === 0 && (
                    <>
                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"

                            >
                                <Typography variant="body2" component="p" color="primary">
                                    <b>There is no transaction data for the current academic year</b>.


                                </Typography>
                            </Box>
                        </Grid>


                    </>


                )}


                <Grid item xs={12}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"

                    >
                        <Typography variant="body2" component="p" color="primary.main" sx={{ margin: "5px", textAlign: 'center' }}>
                            Should you have any enquiries, please contact the Bursar on <a href="tel:27117067775"> +27 11 706 7775</a> or email <a href="mailto: bursar@bicollege.co.za"> bursar@bicollege.co.za</a>.

                        </Typography>

                    </Box>
                </Grid>
                <Grid item xs={12}>

                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{

                            maxWidth: '95%',
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}
                    >
                        <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Button fullWidth variant="contained" onClick={(e) => setView(0)}>Close <CloseIcon /></Button>


                            </Box>

                            <Box mb={3} />
                        </Grid>

                    </Box>

                    <Box mb={3} />
                </Grid>

            </Grid>
            <Box mb={5} />


        </Container >
    )
}

export default ViewSummary