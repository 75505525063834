import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../hooks/useAuthContext'
import { useFetch } from '../../hooks/useFetch'
import { useEffect, useState } from 'react'
import { Container, Box, Grid, TextField, Typography, Button, Divider, Card, Paper, CardActions, CardContent } from '@mui/material/'
import DashboardIcon from '@mui/icons-material/Dashboard';
import DataGridView from '../../components/DataGridView';
import PageviewIcon from '@mui/icons-material/Pageview';
import PreviewIcon from '@mui/icons-material/Preview';
import SelectStudent from '../../components/SelectStudent'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SelectCampusByStudent from '../../components/SelectCampusByStudent'
import CircularProgress from '@mui/material/CircularProgress';
import ViewExamTimetable from '../../components/examtimetable/ViewExamTimetable'
import { OutlinedInput, InputLabel, FormControl, Select, MenuItem } from '@mui/material/'
function ExamTimetable() {

    document.title = 'BIC Parent Portal: Exam Timetable';
    const [viewTimetable, setViewTimetable] = useState(0)
    const [studentId, setStudentId] = useState('')
    const [viewAll, setViewAll] = useState(1)
    const navigate = useNavigate()

    const handleSubmit = (e) => {
        e.preventDefault()
        setViewTimetable(1)
    }


    return (
        <>
            {!viewTimetable && (

                <form onSubmit={handleSubmit} >


                    < Container maxWidth="md"  >

                        <Box mb={5} />
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} sx={{ border: 'solid', borderWidth: '3px', borderColor: 'primary.main', borderRadius: 2 }}>

                            <Grid item xs={12}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <Typography variant="h3" component="h3" color="primary.main" sx={{ textAlign: 'center' }}>
                                        Students College/School Examination Timetable
                                    </Typography>



                                </Box>


                            </Grid>
                            <Grid item xs={12}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>
                                        Parents or Guardians can view a linked student’s upcoming Mock or College/School examinations timetable.  Please note timetables are subject to change. This timetable <u>does not include Cambridge examinations</u>.

                                    </Typography>



                                </Box>


                            </Grid>
                            <Grid item xs={12}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>
                                        The AM examination sessions starts at 10:00AM and PM sessions start at 14:00PM.
                                    </Typography>



                                </Box>


                            </Grid>
                            <Grid item xs={12}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>

                                        Students with examinations clashes will be advised on their examination starting times as well as isolation time from the Examinations Office.


                                    </Typography>



                                </Box>


                            </Grid>
                            <Grid item xs={12}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>

                                        Students are reminded that these examinations are held under Cambridge examination conditions.


                                    </Typography>



                                </Box>


                            </Grid>
                            <Grid item xs={12}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>

                                        Students are expected to adhere to the <a href="https://google.com" target='_blank'>Cambridge Examination Rules</a> and the <a href="https://google.com" target="_blank">British International Code of Conduct</a>.
                                    </Typography>



                                </Box>


                            </Grid>
                            <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <SelectStudent studentId={studentId} setStudentId={setStudentId} />


                                </Box>

                            </Grid>

                            <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <FormControl fullWidth>
                                        <InputLabel id="title-label">Select Filter*</InputLabel>
                                        <Select
                                            labelId="title-label"
                                            id="title"
                                            value={viewAll}
                                            label="Select Filter*"
                                            onChange={(e) => setViewAll(e.target.value)}
                                            required

                                        >



                                            <MenuItem key={1} value={1}>View All Examinations</MenuItem>
                                            <MenuItem key={2} value={2}>View Upcoming Examinations</MenuItem>

                                        </Select>
                                    </FormControl>

                                </Box>

                            </Grid>
                            <Grid item xs={12}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >

                                    <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        >


                                            {studentId && (

                                                <Button fullWidth type="submit" color="primary" variant="contained">View <PreviewIcon /></Button>

                                            )}

                                            {!studentId && (

                                                <Button fullWidth disabled color="primary" variant="contained">View <PreviewIcon /></Button>

                                            )}






                                        </Box>

                                    </Grid>






                                </Box>

                            </Grid>

                            <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >


                                </Box>


                            </Grid>


                            <Grid item xs={12}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        >
                                            <Button fullWidth variant="contained" onClick={(e) => navigate(-1)}>Dashboard <DashboardIcon /></Button>


                                        </Box>

                                        <Box mb={5} />
                                    </Grid>

                                </Box>

                                <Box mb={5} />
                            </Grid>





                        </Grid>
                    </Container>
                </form>


            )
            }
            {viewTimetable === 1 && (

                <ViewExamTimetable viewAll={viewAll} studentId={studentId} setViewTimetable={setViewTimetable} />)}
        </>
    )
}

export default ExamTimetable