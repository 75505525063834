import React, { useEffect } from 'react'
import { OutlinedInput, InputLabel, FormControl, Select, MenuItem } from '@mui/material/'
import { useFetch } from '../hooks/useFetch'
import { useAuthContext } from '../hooks/useAuthContext'


function SelectStudentSharedAccount({ studentId, setStudentId, disabled }) {

    const { parentID } = useAuthContext()
    const { getData, error, isPending, data } = useFetch(`https://parentapi.bi-oss.co.za/students/sharedaccount/selectedstudent/${parentID}`)



    useEffect(() => {
        if (parentID) {
            getData()
        }
    }, [parentID])

    return (
        <FormControl fullWidth>
            <InputLabel id="selectstud">Select Account*</InputLabel>

            <Select


                labelId="selectstud"
                id="studentId"
                disabled={disabled}
                onChange={(e) => setStudentId(e.target.value)}
                input={<OutlinedInput label="selectstud" />}
                required
                value={studentId}
            >
                {data && data.map(data => (

                    <MenuItem key={data.id} value={data.id}>{data.lname}, {data.fname} (Account: {data.id})</MenuItem>
                ))}

            </Select>
        </FormControl>
    )
}

export default SelectStudentSharedAccount