import React, { useState, useEffect } from 'react'
import { OutlinedInput, InputLabel, FormControl, Select, MenuItem } from '@mui/material/'
import { useFetch } from '../hooks/useFetch'
function SelectCampusByStudent({ campusId, setCampusId, disabled, studentId }) {

    const { getData, error, isPending, data } = useFetch(`https://parentapi.bi-oss.co.za/students/studentcampus/${studentId}`)

    const [reverse, setReverse] = useState([])
    useEffect(() => {
        if (studentId) {
            getData()
        }
    }, [studentId])




    // useEffect(() => {
    //     if (data && data.length > 0) {
    //         data.toReversed().map((data) => {
    //             setCampusId(data.id)
    //         })

    //     }
    // }, [data])
    return (
        <FormControl fullWidth>
            <InputLabel id="campusId">Select Students Campus*</InputLabel>

            <Select


                labelId="campusId"
                id="campusId"
                disabled={disabled}
                onChange={(e) => setCampusId(e.target.value)}
                input={<OutlinedInput label="campusId" />}
                required
                value={campusId}
            >


                {data && (

                    data.map(function (data) {



                        if (data.id !== 6) {
                            return <MenuItem key={data.id} value={data.id}>{data.campus}</MenuItem>
                        }
                        else {
                            return
                        }


                    })


                )

                }









            </Select>
        </FormControl >
    )
}

export default SelectCampusByStudent