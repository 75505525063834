import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../context/AuthContext'
import { useFetch } from '../../hooks/useFetch'
import { useEffect, useState } from 'react'
import { Container, Box, Grid, TextField, Typography, Button, Divider, Card, Paper, CardActions, CardContent } from '@mui/material/'
import DashboardIcon from '@mui/icons-material/Dashboard';
import DataGridView from '../../components/DataGridView';
import SelectedStudent from '../../components/SelectedStudent'
import SelectedCampus from '../../components/SelectedCampus'
import format from 'date-fns/format'
import CloseIcon from '@mui/icons-material/Close';
import { addDays } from 'date-fns'
import { OutlinedInput, InputLabel, FormControl, Select, MenuItem } from '@mui/material/'
import CircularProgress from '@mui/material/CircularProgress';
function ViewReg({ studentId, setViewReg }) {

    const [tableData, setTableData] = useState([])

    const { getData, error, errorMessage, isPending, data: resultsData } = useFetch(`https://parentapi.bi-oss.co.za/students/registrations/${studentId}`, 'GET')

    useEffect(() => {
        if (studentId) {
            getData()
        }
    }, [studentId])


    const navigate = useNavigate()

    const classes = {};

    document.title = 'BIC Parent Portal: Confirmation of Registration';


    useEffect((tableData) => {
        if (resultsData) {
            setTableData(resultsData)

        }


    }, [resultsData])

    const handleOnCellClick = (e) => {
        // window.open(e.row.fileURL, '_blank')
    }

    const openLink = (url) => {
        window.open(url, '_blank')
    }
    const columns = [

        { field: 'academicYear', headerName: 'Year:', width: 150 },
        { field: 'campus', headerName: 'Campus/School:', width: 250 },
        { field: 'courseName', headerName: 'Course:', width: 300 },
        { field: 'designation', headerName: 'Class:', width: 150 },

    ];

    return (
        <Container maxWidth="md" >
            <>
                <Box mb={5} />
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} sx={{ border: 'solid', borderWidth: '3px', borderColor: "primary.main", borderRadius: 2 }}>

                    < Grid item xs={12}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"

                        >




                            <Typography variant="h6" component="h6" color="primary">
                                Student Registration Details
                            </Typography>

                        </Box>
                    </Grid>

                    {!errorMessage && !isPending && studentId && (

                        <>




                            <Grid item xs={12}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        >
                                            <SelectedStudent studentId={studentId} />

                                        </Box>


                                    </Grid>
                                </Box>


                            </Grid>
                        </>
                    )}
                    {isPending && !error && (
                        <>

                            <Grid item xs={12}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >
                                    <Typography variant="h6" component="h3" color="primary">
                                        Fetching registration data ...
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >
                                    <CircularProgress />
                                </Box>
                            </Grid>
                        </>
                    )}

                    {error && !errorMessage && (
                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"

                            >
                                <Typography variant="h6" component="h3" color="error">
                                    Unable to fetch registration data. You may be offline. Please try again.


                                </Typography>
                            </Box>
                        </Grid>
                    )}



                    {resultsData && resultsData.length === 0 && (
                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"

                            >
                                <Typography variant="body2" component="p" color="primary">
                                    <b>There are no registrations for the selected student in the current academic year.</b>
                                </Typography>
                            </Box>
                        </Grid>

                    )}
                    {resultsData && resultsData.length > 0 && (
                        <Grid item xs={12} minWidth={0} fullWidth>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"

                            >
                                <DataGridView
                                    fullWidth
                                    tableData={tableData}
                                    error={error} isPending={isPending} getFormattedDate={null} columns={columns} handleOnCellClick={handleOnCellClick}
                                    getRowId={(row) => row.id}
                                    pageSize={10}

                                />
                            </Box>
                        </Grid>

                    )}

                    <Grid item xs={12}>

                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{

                                maxWidth: '95%',
                                marginLeft: 'auto',
                                marginRight: 'auto'
                            }}
                        >
                            <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <Button fullWidth variant="contained" onClick={(e) => setViewReg(0)}>Close <CloseIcon /></Button>


                                </Box>

                                <Box mb={3} />
                            </Grid>

                        </Box>

                        <Box mb={3} />
                    </Grid>
                </Grid>
                <Box mb={5} />
            </>
        </Container >
    )
}

export default ViewReg