import React, { useState, useEffect } from 'react'

import ProductHeroLayout from './ProductHeroLayout';
import { Container, Grid, TextField, Button, Typography, createTheme, Box } from '@mui/material'
import LoginIcon from '@mui/icons-material/Login';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
const images = ['../img/BannerDistance.jpg', '../img/BannerBryanston.jpg', '../img/BannerDistance.jpg', '../img/BannerBryanston.jpg', '../img/BannerDistance.jpg', '../img/BannerBryanston.jpg'];


export default function ProductHero() {

    const [backgroundImage, setBackgroundImage] = useState(images[0])


    useEffect(() => {
        const intervalId = setInterval(() => {
            setBackgroundImage(images[Math.floor(Math.random() * images.length)]);
        }, 9000)

        return () => clearInterval(intervalId);
    }, [])


    return (
        <ProductHeroLayout
            fullWidth
            sxBackground={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundColor: '#7fc7d9', // Average color of the background image.
                fullWidth: 'calc(85vh)',
                transition: 'background 3s linear',
                marginTop: 0,


            }}
        >
            {/* Increase the network loading priority of the background image. */}
            <img
                style={{ display: 'none' }}
                src={backgroundImage}
                alt="increase priority"
            />

            <Box mb={10} />
            <Typography color="inherit" align="center" variant="h3" marked="center" fontFamily="Times New Roman" >
                Online Parent Portal
            </Typography>
            <Typography
                color="inherit"
                align="center"
                variant="body2"
                fontSize={"16px"}
                sx={{ mb: 4, mt: { xs: 4, sm: 10 } }}


            >
                Welcome to the British International Colleges and Schools online parent protal. Within this portal, parents of registered students will have the ability to track assignment history, view course registrations, edit contact information, enter for Cambridge Examinations, review South African matric requirements for further study, keep up to date with your child’s campus or school specific communications and more.
            </Typography>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"

            >
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>

                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"

                        >
                            <Button
                                color="secondary"
                                variant="contained"
                                size="large"
                                component="a"
                                href="/register"
                                sx={{ minWidth: 200 }}
                            >
                                Register <AppRegistrationIcon />
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>

                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"

                        >
                            <Button
                                color="primary"
                                variant="contained"
                                size="large"
                                component="a"
                                href="/login"
                                sx={{ minWidth: 200 }}
                            >
                                Login <LoginIcon />
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Typography variant="body2" color="inherit" sx={{ mt: 2, fontStyle: 'italic' }}>
                <a class="buttonIllum"><span> Illuminate et Optimus</span></a>
            </Typography>
        </ProductHeroLayout>
    );
}