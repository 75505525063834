import React, { useState, useEffect } from 'react'
import { Container, OutlinedInput, Box, Grid, TextField, Typography, Button, InputLabel, FormControl, Select, MenuItem } from '@mui/material/'
import { useFetch } from '../../hooks/useFetch'
import SelectTitle from '../SelectTitle'
import SelectNationality from '../SelectNationality'
import { TitleOutlined } from '@mui/icons-material'
import DoneOutlineIcon from '@mui/icons-material/DoneOutline'
import { useAuthContext } from '../../hooks/useAuthContext'
import CancelIcon from '@mui/icons-material/Cancel'
import SendIcon from '@mui/icons-material/Send'
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SelectedStudent from '../SelectedStudent'
import SelectedParent from '../SelectedParent'
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import SelectedCampus from '../SelectedCampus'
function DeclineAllEntries({ setRemoveAll, handleComplete, studentId, campusId, academicYear, sessionId, hideNotice }) {

    const { user, parentID } = useAuthContext()

    const [success, setSuccess] = useState(0)



    const { updateData, errorMessage, error, isPending, data: updatedData } = useFetch('https://parentapi.bi-oss.co.za/studcambridge/updateallconf', 'PUT')



    const handleSubmit = (e) => {

        e.preventDefault()

        updateData({
            studentId,
            campusId,
            status: 0,
            confirmedBy: user.email,
            parentId: parentID,
            academicYear,
            sessionId
        })
    }


    useEffect(() => {


        if (updatedData) {

            setSuccess(1)

        }


    }, [updatedData])
    return (
        <>
            <Container maxWidth="md">
                <Box mb={5} />
                {!success && (



                    <form autoComplete="off" onSubmit={handleSubmit}>
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} sx={{ border: 'solid', borderWidth: '3px', borderColor: "error.main", borderRadius: 2 }}>



                            <Grid item xs={12} >
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >

                                    <Typography variant="h6" component="h3" color="error">
                                        Decline All Examination Entries
                                    </Typography>


                                </Box>

                            </Grid>
                            <Grid item xs={12} >
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >
                                    {campusId !== 3 && !hideNotice && (

                                        <Typography variant="body2" component="p" color="error">
                                            It is recommended that both parents and students discuss the student’s examination entries during their progress interview. As certain examination paper combinations are only available during specific sessions. Declining an entry may affect the students desired qualification outcome.
                                        </Typography>
                                    )}
                                    {campusId === 3 && !hideNotice && (

                                        <Typography variant="body2" component="p" color="error">
                                            It is recommended that both parents and students discuss the student’s examination entries with thier assigned Student Advisor. As certain examination paper combinations are only available during specific sessions. Declining an entry may affect the students desired qualification outcome.
                                        </Typography>
                                    )}


                                </Box>

                            </Grid>
                            {error && !errorMessage && (

                                <Grid item xs={12} >
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"

                                    >

                                        <Typography variant="h6" component="h3" className='error' fullWidth sx={{ textAlign: 'center' }}>
                                            Unable to get entry infromation. You may be offline.
                                        </Typography>

                                    </Box>
                                </Grid>
                            )}



                            {errorMessage && (

                                <Grid item xs={12} >
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"

                                    >

                                        <Typography variant="h6" component="h3" className='error' fullWidth sx={{ textAlign: 'center' }}>
                                            {errorMessage}
                                        </Typography>

                                    </Box>
                                </Grid>
                            )}


                            {isPending && (

                                <Grid item xs={12} >
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"

                                    >

                                        <CircularProgress />
                                        <Typography variant="h6" component="h3" color="secondary">
                                            Updating data....
                                        </Typography>

                                    </Box>
                                </Grid>
                            )}
                            {!isPending &&
                                (

                                    <>

                                        <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>
                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"

                                            >



                                                <SelectedCampus campusId={campusId} />
                                            </Box>


                                        </Grid>
                                        <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>
                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"

                                            >
                                                <SelectedStudent studentId={studentId} />

                                            </Box>


                                        </Grid>





                                        <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{

                                                    maxWidth: '95%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            >

                                                {!isPending && (
                                                    <Button fullWidth color={"primary"} variant="contained" onClick={(e) => setRemoveAll(0)}>Cancel <CancelIcon /></Button>


                                                )}



                                            </Box>


                                        </Grid>


                                        <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{

                                                    maxWidth: '95%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            >


                                                {!isPending && (

                                                    <Button fullWidth color="error" type="submit" variant="contained">Decline<ThumbDownOffAltIcon /></Button>

                                                )}




                                            </Box>
                                            <Box mb={5} />

                                        </Grid>
                                    </>
                                )}
                            <Box mb={5} />
                        </Grid>
                    </form>
                )}
                {!isPending && !error && !errorMessage && success === 1 && (
                    <>
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} sx={{ border: 'solid', borderWidth: '3px', borderColor: "success.main", borderRadius: 2 }}>


                            <Grid item xs={12}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >

                                    <Typography variant="h6" component="h3" padding={1} borderRadius={1} color="success.main">
                                        Examination Entries Updated
                                    </Typography>


                                </Box>

                            </Grid>





                            <Grid item xs={12} >
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >

                                    <Button color="success" fullWidth type="button" variant="contained" onClick={handleComplete}>Close<DoneOutlineIcon /></Button>
                                </Box>
                                <Box mb={5} />
                            </Grid>


                        </Grid>
                        <Box mb={4} />
                    </>
                )
                }
                <Box mb={5} />
            </Container >

        </>
    )
}

export default DeclineAllEntries