import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../hooks/useAuthContext'
import { useFetch } from '../../hooks/useFetch'
import { useEffect, useState } from 'react'
import { Container, Box, Grid, TextField, Typography, Button, Divider, Card, Paper, CardActions, CardContent } from '@mui/material/'
import format from 'date-fns/format'
import UpdateUserPassword from '../../components/profile/UpdateUserPassword'
import EditProfile from '../../components/profile/EditProfile'
import EditIcon from '@mui/icons-material/Edit';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DataGridView from '../../components/DataGridView';
import PageviewIcon from '@mui/icons-material/Pageview';
import PreviewIcon from '@mui/icons-material/Preview';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
function Resources() {

    const { user, accessLevel, parentID, token } = useAuthContext()

    const [tableData, setTableData] = useState([])

    const { error, isPending, data: commsData } = useFetch('https://parentapi.bi-oss.co.za/students/resources/' + parentID)
    const navigate = useNavigate()

    const classes = {};

    document.title = 'BIC Parent Portal: Parent Resources';


    useEffect((tableData) => {
        if (commsData) {
            setTableData(commsData)

        }


    }, [commsData])

    const handleOnCellClick = (e) => {
        // window.open(e.row.fileURL, '_blank')
    }

    const openLink = (url) => {
        window.open(url, '_blank')
    }
    const columns = [

        { field: 'createdOn', headerName: 'Date:', width: 120, valueFormatter: params => { return format(new Date(params.value), 'dd/MM/yy') } },
        { field: 'courseName', headerName: 'Course:', width: 200, editable: true },


        { field: 'description', headerName: 'Description:', width: 300, editable: true },

        {
            field: "fileURL",
            headerName: "",
            sortable: false,
            width: 120,
            renderCell: (params) => {
                const onClick2 = (e) => {
                    e.stopPropagation() // don't select this row after clicking

                    window.open(params.row.fileURL, '_blank')

                }



                return <Button color="primary" onClick={onClick2} fullWidth variant="contained">View <PreviewIcon /></Button>;



            }
        },

    ];


    return (
        <>
            <Container maxWidth="md" >

                <Box mb={5} />
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} sx={{ border: 'solid', borderWidth: '3px', borderColor: "primary.main", borderRadius: 2 }}>


                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"

                        >
                            <Typography variant="h3" component="h3" color="primary.main" sx={{ textAlign: 'center' }}>
                                Parent Resources
                            </Typography>

                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"

                        >


                        </Box>
                    </Grid>





                    {isPending && !error && (
                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"

                            >
                                <Typography variant="h6" component="h3" color="primary">
                                    Fetching resource list ...
                                </Typography>
                            </Box>
                        </Grid>
                    )}

                    {error && (
                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"

                            >
                                <Typography variant="h6" component="h3" color="error">
                                    Unable to fetch parent resources. You may be offline. Please try again.


                                </Typography>
                            </Box>
                        </Grid>
                    )}

                    {!isPending && !error && tableData && tableData.length > 0 && (
                        <Grid item xs={12} sx={{ display: { xs: "none", sm: "block" }, }}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"

                            >
                                <DataGridView

                                    tableData={tableData}
                                    error={error} isPending={isPending} getFormattedDate={null} columns={columns} pageSize={15} handleOnCellClick={handleOnCellClick}
                                    getRowId={(row) => row.id}
                                />
                            </Box>
                        </Grid>

                    )}

                    {!isPending && !error && tableData && tableData.length === 0 && (
                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"

                            >
                                <Typography variant="body2" component="p" color="primary">
                                    <b>There are currently no resources to view.</b>


                                </Typography>
                            </Box>
                        </Grid>

                    )}




                    {commsData && commsData.length > 0 && commsData.map(data => (


                        <>

                            <Grid container bgcolor="primary.light" spacing={3} direction="row" justify="center" alignItems="stretch" sx={{ display: { xs: "block", sm: "none" }, }}>


                                <Grid item xs={12} spacing={3} key={data.id}>
                                    <Grid>
                                        <Card className={classes.root} variant="outlined">
                                            <CardContent>

                                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                    {format(new Date(data.createdOn), 'dd/MM/yy')}
                                                </Typography>
                                                <Typography variant="h5" component="h3">
                                                    {data.courseName}
                                                </Typography>
                                                <Typography variant="h5" component="h3">
                                                    {data.description}
                                                </Typography>
                                                <Typography className={classes.pos} color="textSecondary">
                                                    {data.campus}
                                                </Typography>
                                                <Grid item xs={6}>

                                                    <Button gutterBottom fullWidth color="primary" onClick={(e) => openLink(data.fileURL)} variant="contained">View <PreviewIcon /></Button>

                                                </Grid>
                                                <Box mb={1} />
                                            </CardContent>


                                        </Card>
                                    </Grid>

                                </Grid>

                            </Grid>
                            <Box mb={1} />





                        </>


                    ))}



                    <Grid item xs={12}>

                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{

                                maxWidth: '95%',
                                marginLeft: 'auto',
                                marginRight: 'auto'
                            }}
                        >
                            <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <Button fullWidth variant="contained" onClick={(e) => navigate(-1)}>Dashboard <DashboardIcon /></Button>


                                </Box>

                                <Box mb={3} />
                            </Grid>

                        </Box>

                        <Box mb={3} />
                    </Grid>
                    <Box mb={5} />


                </Grid >
                <Box mb={5} />


            </Container >

        </>
    )
}

export default Resources