import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../context/AuthContext'
import { useFetch } from '../../hooks/useFetch'
import { useEffect, useState } from 'react'
import { Container, Box, Grid, TextField, Typography, Button, Divider, Card, Paper, CardActions, CardContent } from '@mui/material/'
import DashboardIcon from '@mui/icons-material/Dashboard';
import DataGridView from '../../components/DataGridView';
import SelectedStudent from '../../components/SelectedStudent'
import SelectedCampus from '../../components/SelectedCampus'
import format from 'date-fns/format'
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
export default function ViewResults({ campusId, studentId, setViewResult }) {

    const [tableData, setTableData] = useState([])

    const { postData, error, errorMessage, isPending, data: resultsData } = useFetch('https://parentapi.bi-oss.co.za/students/testsandassignments', 'POST')


    useEffect(() => {
        if (studentId && campusId) {
            postData({
                studentId, campusId
            })
        }
    }, [studentId, campusId])


    const navigate = useNavigate()

    const classes = {};
    const [viewAll, setViewAll] = useState(0)
    document.title = 'BIC Parent Portal: Student Results';


    useEffect((tableData) => {
        if (resultsData) {
            setTableData(resultsData)

        }


    }, [resultsData])

    const handleOnCellClick = (e) => {
        // window.open(e.row.fileURL, '_blank')
    }

    const openLink = (url) => {
        window.open(url, '_blank')
    }
    const columns = [

        { field: 'dateComplete', headerName: 'Date:', width: 120, valueFormatter: params => { return format(new Date(params.value), 'dd/MM/yy') } },

        { field: 'courseName', headerName: 'Course:', width: 200 },
        { field: 'description', headerName: 'Test/Assignment:', width: 300 },

        {
            field: "action1",
            headerName: "Result",
            sortable: false,
            width: 120,
            align: "right",
            renderCell: (params) => {




                return <TextField



                    InputProps={{
                        readOnly: true,
                    }}
                    value={params.row.result + '/' + params.row.maxMarks}
                    fullWidth
                    required
                />



            }
        },
        {
            field: "action2",
            headerName: "%",
            sortable: false,
            width: 120,
            align: "right",
            renderCell: (params) => {




                return <TextField



                    InputProps={{
                        readOnly: true,
                    }}
                    value={(params.row.result / params.row.maxMarks) * 100 + "%"}
                    fullWidth
                    required
                />



            }
        },
    ];
    const columns2 = [

        { field: 'receivedOn', headerName: 'Date:', width: 120, valueFormatter: params => { return format(new Date(params.value), 'dd/MM/yy') } },

        { field: 'courseName', headerName: 'Course:', width: 270 },
        { field: 'assignmentId', headerName: 'Assignment:', width: 150 },


        {
            field: "action1",
            headerName: "Result",
            sortable: false,
            width: 120,
            renderCell: (params) => {




                return <TextField



                    InputProps={{
                        readOnly: true,
                    }}
                    value={params.row.result + '/' + params.row.maxMark}
                    fullWidth
                    required
                />



            }
        },
        {
            field: "action2",
            headerName: "%",
            sortable: false,
            width: 120,
            renderCell: (params) => {




                return <TextField



                    InputProps={{
                        readOnly: true,
                    }}
                    value={(params.row.result / params.row.maxMark) * 100 + "%"}
                    fullWidth
                    required
                />



            }
        },
    ];
    return (
        <Container maxWidth="md" >
            <>
                <Box mb={5} />
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} sx={{ border: 'solid', borderWidth: '3px', borderColor: "primary.main", borderRadius: 2 }}>
                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"

                        >
                            <Typography variant="h6" component="h3" color="primary">
                                Student Results
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"

                        >
                            <Typography variant="h6" component="h4" color="primary">
                                (Tests and Assignments)
                            </Typography>
                        </Box>
                    </Grid>

                    {!errorMessage && (

                        <>



                            <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >

                                    <SelectedCampus campusId={campusId} />
                                </Box>


                            </Grid>
                            <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <SelectedStudent studentId={studentId} />

                                </Box>


                            </Grid>
                        </>
                    )}
                    {isPending && !error && (
                        <>

                            <Grid item xs={12}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >
                                    <Typography variant="h6" component="h3" color="primary">
                                        Fetching student results ...
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >
                                    <CircularProgress />
                                </Box>
                            </Grid>
                        </>
                    )}

                    {error && !errorMessage && (
                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"

                            >
                                <Typography variant="h6" component="h3" color="error">
                                    Unable to fetch results. You may be offline. Please try again.


                                </Typography>
                            </Box>
                        </Grid>
                    )}

                    {errorMessage && (
                        <>
                            <Grid item xs={12} sx={{ display: { xs: "none", sm: "block" }, }}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >
                                    <Typography variant="h6" component="h3" color="error">
                                        Results Locked

                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sx={{ display: { xs: "none", sm: "block" }, }}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >
                                    <Typography variant="body2" component="p">
                                        <b>Reason:</b>  {errorMessage}

                                    </Typography>
                                </Box>
                            </Grid>
                        </>
                    )}

                    {resultsData && resultsData.length === 0 && (
                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"

                            >
                                <Typography variant="h6" component="h6" color="primary">
                                    There are currently no results to view for the selected student.
                                </Typography>
                            </Box>
                        </Grid>

                    )}
                    {resultsData && resultsData.length > 0 && campusId !== 3 && (
                        <>


                            <Grid item xs={12} minWidth={0}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >
                                    <DataGridView

                                        tableData={tableData}
                                        error={error} isPending={isPending} getFormattedDate={null} columns={columns} pageSize={15} handleOnCellClick={handleOnCellClick}
                                        getRowId={(row) => row.id}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >
                                    <Typography variant="body2" component="p" color="primary">
                                        Should you require a full current Record of Achievement report, please contact your college or school.
                                    </Typography>
                                </Box>
                            </Grid>

                        </>

                    )}
                    {resultsData && resultsData.length > 0 && campusId === 3 && (
                        <>
                            <Grid item xs={12} minWidth={0}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >
                                    <DataGridView

                                        tableData={tableData}
                                        error={error} isPending={isPending} getFormattedDate={null} columns={columns2} pageSize={15} handleOnCellClick={handleOnCellClick}
                                        getRowId={(row) => row.id}
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >
                                    <Typography variant="body2" component="p" color="primary">
                                        Should you require a full assignment history report, please contact your associated Student Advisor.
                                    </Typography>
                                </Box>
                            </Grid>
                        </>

                    )}
                    <Grid item xs={12}>

                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{

                                maxWidth: '95%',
                                marginLeft: 'auto',
                                marginRight: 'auto'
                            }}
                        >
                            <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <Button fullWidth variant="contained" onClick={(e) => setViewResult(0)}>Close <CloseIcon /></Button>


                                </Box>

                                <Box mb={3} />
                            </Grid>

                        </Box>

                        <Box mb={3} />
                    </Grid>
                </Grid>

            </>
        </Container >
    )
}
