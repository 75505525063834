import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../hooks/useAuthContext'
import { useFetch } from '../../hooks/useFetch'
import { useEffect, useState } from 'react'
import { Container, Box, Grid, TextField, Typography, Button, Divider } from '@mui/material/'
import format from 'date-fns/format'
import UpdateUserPassword from '../../components/profile/UpdateUserPassword'
import EditProfile from '../../components/profile/EditProfile'
import EditIcon from '@mui/icons-material/Edit';
import DashboardIcon from '@mui/icons-material/Dashboard';
function Profile() {

    const { user, accessLevel, parentID, token } = useAuthContext()
    const [edit, setEdit] = useState(0)
    const [change, setChange] = useState(0)
    const { postData, error, isPending, data: parentData } = useFetch('https://parentapi.bi-oss.co.za/profile/userdata', 'POST')
    const navigate = useNavigate()
    document.title = 'BIC Parent Portal: My Profile';


    useEffect(() => {


        if (parentID) {
            postData({
                pid: parentID,
                email: user.email
            })
        }
    }, [parentID])


    const handleComplete = (e) => {
        e.preventDefault()
        postData({
            pid: parentID,
            email: user.email
        })
        setEdit(0)
        setChange(0)
    }


    return (
        < Container maxWidth="md"  >






            {isPending && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"

                >
                    <Typography variant="h6" component="h3" color="secondary">
                        Fetching profile data ...
                    </Typography>
                </Box>
            )}


            {!edit && !change && !isPending && parentData && parentID && parentData.map(parent => ((
                <>
                    <Box mb={5} />
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} sx={{ border: 'solid', borderWidth: '3px', borderColor: 'primary.main', borderRadius: 2 }}>

                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="h3" component="h3" color="primary.main" sx={{ textAlign: 'center' }}>
                                    Profile Data
                                </Typography>



                            </Box>


                        </Grid>
                        <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <TextField

                                    id="username"
                                    label="Username"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={parent.username}
                                    fullWidth
                                    required
                                />


                            </Box>


                        </Grid>
                        <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Button fullWidth onClick={(e) => setChange(1)} variant="contained">Change Password</Button>


                            </Box>


                        </Grid>
                        <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <TextField

                                    id="nation"
                                    label="Nationality"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={parent.nationality}
                                    fullWidth
                                    required
                                />


                            </Box>


                        </Grid>
                        <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <TextField

                                    id="idpass"
                                    label="RSA ID or Passport"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={parent.idpass}
                                    fullWidth
                                    required
                                />


                            </Box>

                        </Grid>
                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>
                                    To update your profile date (username, nationality, RSA ID or International Passport number), please send an email to the Registrar (<a href="mailto:registrar@bicollege.co.za">registrar@bicollege.co.za</a>) requesting the change accompanied with a digital copy of your identification.
                                </Typography>



                            </Box>


                        </Grid>
                    </Grid>
                    <Box mb={5} />
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} sx={{ border: 'solid', borderWidth: '3px', borderColor: 'primary.main', borderRadius: 2 }}>

                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="h3" component="h3" color="primary.main" sx={{ textAlign: 'center' }}>
                                    Personal Details
                                </Typography>



                            </Box>


                        </Grid>

                        <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <TextField

                                    id="dob"
                                    label="DOB (YYYY/MM/DD)"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={format(new Date(parent.dob), 'yyyy/MM/dd')}
                                    fullWidth
                                    required
                                />
                            </Box>


                        </Grid>



                        <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <TextField

                                    id="title"
                                    label="Title"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={parent.title}
                                    fullWidth
                                    required
                                />
                            </Box>


                        </Grid>


                        <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <TextField

                                    id="fname"
                                    label="First Names"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={parent.fname}
                                    fullWidth
                                    required
                                />
                            </Box>


                        </Grid>



                        <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <TextField

                                    id="lname"
                                    label="Last Name"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={parent.lname}
                                    fullWidth
                                    required
                                />


                            </Box>


                        </Grid>
                        <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <TextField

                                    id="workTel"
                                    label="Work Tel:"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={parent.workTel}
                                    fullWidth
                                    required
                                />


                            </Box>


                        </Grid>
                        <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <TextField

                                    id="homeTel"
                                    label="Home Tel"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={parent.homeTel}
                                    fullWidth
                                    required
                                />


                            </Box>


                        </Grid>
                        <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <TextField

                                    id="saMobile"
                                    label="RSA Mobile:"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={parent.saMobile}
                                    fullWidth
                                    required
                                />


                            </Box>


                        </Grid>
                        <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <TextField

                                    id="intMobile"
                                    label="International Mobile (Whatsapp)"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={parent.intMobile}
                                    fullWidth
                                    required
                                />


                            </Box>


                        </Grid>

                        <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <TextField

                                    id="email"
                                    label="Email Address:"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={parent.email}
                                    fullWidth
                                    required
                                />


                            </Box>


                        </Grid>
                        <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <TextField

                                    id="language"
                                    label="Home Language:"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={parent.language}
                                    fullWidth
                                    required
                                />


                            </Box>


                        </Grid>

                        <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <TextField

                                    id="race"
                                    label="Ethnicity:"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={parent.race}
                                    fullWidth
                                    required
                                />


                            </Box>


                        </Grid>


                        <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                {(parent.gender === 'M' || parent.gender === 'm') && (

                                    <TextField

                                        id="gender"
                                        label="Gender:"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        value="Male"
                                        fullWidth
                                        required
                                    />
                                )}
                                {(parent.gender === 'F' || parent.gender === 'f') && (

                                    <TextField

                                        id="gender"
                                        label="Gender:"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        value="Female"
                                        fullWidth
                                        required
                                    />
                                )}


                            </Box>


                        </Grid>

                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <TextField

                                    id="address"
                                    label="Physical Address:"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={parent.address}
                                    fullWidth
                                    required
                                />


                            </Box>


                        </Grid>
                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>
                                    To update your personal details, click the edit button bellow. Should you experience any issues, please send an email to the Registrar (<a href="mailto:registrar@bicollege.co.za">registrar@bicollege.co.za</a>) requesting the change accompanied with a digital copy of your identification.
                                </Typography>



                            </Box>


                        </Grid>
                        <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Button fullWidth onClick={(e) => setEdit(1)} variant="contained">Edit <EditIcon /></Button>


                            </Box>


                        </Grid>
                        <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Button fullWidth variant="contained" onClick={(e) => navigate(-1)}>Dashboard <DashboardIcon /></Button>


                            </Box>
                            <Box mb={5} />

                        </Grid>


                    </Grid>
                    <Box mb={5} />
                </>
            )))}

            {change === 1 && (

                <UpdateUserPassword setChange={setChange} />
            )}
            {edit === 1 && (

                <EditProfile data={parentData} handleComplete={handleComplete} setEdit={setEdit} />
            )}
        </Container>
    )
}

export default Profile