import React, { useEffect } from 'react'
import { OutlinedInput, InputLabel, FormControl, Select, MenuItem } from '@mui/material/'
import { useFetch } from '../hooks/useFetch'
import { useAuthContext } from '../hooks/useAuthContext'

function SelectVenue({ setVenueId, venueId, disabled }) {

    const { parentID } = useAuthContext()
    const { getData, error, isPending, data } = useFetch(`https://parentapi.bi-oss.co.za/shared/venuelist`)




    return (
        <FormControl fullWidth>
            <InputLabel id="selectstud">Select Writing Location*</InputLabel>

            <Select


                labelId="venueId"
                id="studentId"
                disabled={disabled}
                onChange={(e) => setVenueId(e.target.value)}
                input={<OutlinedInput label="studentId" />}
                required
                value={venueId}
            >
                {data && data.map(data => (

                    <MenuItem key={data.id} value={data.id}>{data.venue}</MenuItem>
                ))}

            </Select>
        </FormControl>
    )
}

export default SelectVenue