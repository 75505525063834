import React from 'react'
import { OutlinedInput, InputLabel, FormControl, Select, MenuItem } from '@mui/material/'
export default function SelectTitle({ title, setTitle, disabled }) {
    return (
        <FormControl fullWidth>
            <InputLabel id="title-label">Select Title*</InputLabel>
            <Select
                labelId="title-label"
                id="title"
                value={title}
                label="Select Title*"
                onChange={(e) => setTitle(e.target.value)}
                required
                disabled={disabled}
            >



                <MenuItem key="Mr." value="Mr.">Mr.</MenuItem>
                <MenuItem key="Mrs." value="Mrs.">Mrs.</MenuItem>
                <MenuItem key="Ms." value="Ms.">Ms.</MenuItem>
                <MenuItem key="Dr." value="Dr.">Dr.</MenuItem>
                <MenuItem key="Prof." value="Prof.">Prof.</MenuItem>
                <MenuItem key="Rev." value="Rev.">Rev.</MenuItem>
                <MenuItem key="St." value="St.">St.</MenuItem>

            </Select>
        </FormControl>
    )
}
