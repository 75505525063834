import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../../hooks/useAuthContext'
import { useFetch } from '../../../hooks/useFetch'
import { useEffect, useState } from 'react'
import { Container, Box, Grid, TextField, Typography, Button, Divider } from '@mui/material/'
import DashboardIcon from '@mui/icons-material/Dashboard';
import SelectStudent from '../../../components/SelectStudent'
import SelectCampusByStudent from '../../../components/SelectCampusByStudent'
import { NumericFormat } from 'react-number-format';
import SendIcon from '@mui/icons-material/Send';
import CircularProgress from '@mui/material/CircularProgress';
import CancelIcon from '@mui/icons-material/Cancel';
import DCOrderTotal from '../../../components/dcexam/DCOrderTotal'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SelectCambridgeExamSession from '../../../components/SelectCambridgeExamSession'
import ViewDCAuthList from '../../../components/dcexam/ViewDCAuthList'
import ViewDCTerms from '../../../components/dcexam/ViewDCTerms'
import ViewDCQuote from '../../../components/dcexam/ViewDCQuote'


function DCApply() {
    const navigate = useNavigate()
    const [studentId, setStudentId] = useState('')
    const [campusId, setCampusId] = useState(3)
    const [sessionId, setSessionId] = useState('')
    const [venueId, setVenueId] = useState(0)
    const [academicYear, setAcademicYear] = useState(new Date().getUTCFullYear())


    const [viewStudent, setViewStudent] = useState(1)
    const [viewSelect, setViewSelect] = useState(0)
    const [viewTerms, setViewTerms] = useState(0)
    const [viewQuote, setViewQuote] = useState(0)
    const [viewOrder, setViewOrder] = useState(0)


    const handleSubmit1 = (e) => {
        e.preventDefault()
        setViewStudent(0)
        setViewSelect(1)
    }
    return (

        <>




            {viewStudent === 1 && (

                <>


                    <form onSubmit={handleSubmit1} >


                        < Container maxWidth="md"  >

                            <Box mb={5} />
                            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} sx={{ border: 'solid', borderWidth: '3px', borderColor: 'primary.main', borderRadius: 2 }}>

                                <Grid item xs={12}>

                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{

                                            maxWidth: '95%',
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}
                                    >
                                        <Typography variant="h3" component="h3" color="primary.main" sx={{ textAlign: 'center' }}>
                                            Distance College Examination Application
                                        </Typography>



                                    </Box>


                                </Grid>
                                <Typography variant="body" component="p" color="primary.main" sx={{ textAlign: 'center' }}>
                                    Distance College students qualify to write Colleges Exams should they have not entered for Cambridge or are in a course where no Cambridge exam is available.
                                </Typography>
                                <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{

                                            maxWidth: '95%',
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}
                                    >
                                        <SelectStudent studentId={studentId} setStudentId={setStudentId} />


                                    </Box>

                                </Grid>




                                <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{

                                            maxWidth: '95%',
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}
                                    >


                                        <SelectCambridgeExamSession sessionId={sessionId} setSessionId={setSessionId} nonCam={1} />


                                    </Box>

                                </Grid>

                                <Grid item xs={12}>

                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{

                                            maxWidth: '95%',
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}
                                    >

                                        <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{

                                                    maxWidth: '95%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            >


                                                {studentId && sessionId && (

                                                    <Button fullWidth type="submit" color="primary" variant="contained">Continue<SendIcon /></Button>

                                                )}

                                                {(!studentId || !sessionId) && (

                                                    <Button fullWidth disabled color="primary" variant="contained">Continue<SendIcon /></Button>

                                                )}






                                            </Box>
                                            \
                                        </Grid>






                                    </Box>

                                </Grid>
                                <Grid item xs={12} >

                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{

                                            maxWidth: '95%',
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}
                                    >
                                        <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{

                                                    maxWidth: '95%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            >
                                                <Button fullWidth variant="contained" onClick={(e) => navigate('/dcexam')}><ArrowBackIcon /> Back </Button>


                                            </Box>

                                            <Box mb={5} />
                                        </Grid>

                                    </Box>

                                    <Box mb={5} />
                                </Grid>



                            </Grid>
                        </Container>
                    </form>

                </>
            )}

            {viewSelect === 1 && (

                <>
                    <ViewDCAuthList sessionId={sessionId} campusId={campusId} studentId={studentId} academicYear={academicYear} setViewSelect={setViewSelect} setViewTerms={setViewTerms} setViewStudent={setViewStudent} />
                </>
            )}

            {viewTerms === 1 && (

                <ViewDCTerms sessionId={sessionId} academicYear={academicYear} studentId={studentId} campusId={campusId} setViewQuote={setViewQuote} setViewTerms={setViewTerms} setViewSelect={setViewSelect} />
            )}


            {viewQuote === 1 && (

                <ViewDCQuote academicYear={academicYear} sessionId={sessionId} venueId={venueId} studentId={studentId} campusId={campusId} setViewQuote={setViewQuote} setViewTerms={setViewTerms} setViewSelect={setViewSelect} setViewOrder={setViewOrder} />
            )}

            {viewOrder === 1 && (

                <DCOrderTotal academicYear={academicYear} sessionId={sessionId} studentId={studentId} campusId={campusId} setViewQuote={setViewQuote} setViewOrder={setViewOrder} venueId={venueId} />
            )}
        </>
    )
}

export default DCApply