import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../../hooks/useAuthContext'
import { useFetch } from '../../../hooks/useFetch'
import { useEffect, useState } from 'react'
import { Container, Box, Grid, TextField, Typography, Button, Divider } from '@mui/material/'
import DashboardIcon from '@mui/icons-material/Dashboard';
import SelectStudent from '../../../components/SelectStudent'
import SelectCampusByStudent from '../../../components/SelectCampusByStudent'
import { NumericFormat } from 'react-number-format';
import SendIcon from '@mui/icons-material/Send';
import SelectedStudent from '../../../components/SelectedStudent'
import CircularProgress from '@mui/material/CircularProgress';
import { orderBy } from 'firebase/firestore'
import ViewAuthList from '../../../components/cambridge/ViewAuthList'
import ViewTerms from '../../../components/cambridge/ViewTerms'
import ViewQuote from '../../../components/cambridge/ViewQuote'
import SelectVenue from '../.././../components/SelectVenue'
import CancelIcon from '@mui/icons-material/Cancel';
import OrderTotal from '../../../components/cambridge/OrderTotal'
import { ElectricScooterSharp } from '@mui/icons-material'
import SelectCambridgeExamSession from '../../../components/SelectCambridgeExamSession'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
function CamApply() {

    const { user, parentID } = useAuthContext()

    const [studentId, setStudentId] = useState('')
    const [campusId, setCampusId] = useState('')
    const [sessionId, setSessionId] = useState('')
    const [venueId, setVenueId] = useState('')
    const [academicYear, setAcademicYear] = useState(new Date().getUTCFullYear())

    useEffect(() => {
        if (new Date().getMonth() >= 4) {
            setSessionId(2)
        }
        if (new Date().getMonth() < 4) {
            setSessionId(1)
        }
    }, [new Date().getMonth()])


    const [viewVenueSelect, setViewVenueSelect] = useState(0)
    const [viewExams, setViewExams] = useState(0)
    const [viewTerms, setViewTerms] = useState(0)
    const [viewQuote, setViewQuote] = useState(0)
    const [viewOrder, setViewOrder] = useState(0)

    const navigate = useNavigate()
    const [process, setProcess] = useState(0)
    const { postData, error, data: venueData, isPending: isPendingVenue } = useFetch('https://parentapi.bi-oss.co.za/studcambridge/checkvenue', 'POST')


    useEffect(() => {
        if (campusId === 3 && studentId) {
            postData({
                studentId,
                sessionId,
                campusId,
                academicYear
            })
        }
    }, [campusId, studentId])


    useEffect(() => {
        if (campusId) {
            setViewVenueSelect(0)
            if (campusId !== 3) {
                setVenueId(campusId)
            } else {
                setVenueId('')
            }
        }
    }, [campusId])


    const [payReqId, setPayReqId] = useState('')
    const [returnChecksum, setReturnChecksum] = useState('')


    const handleSubmit1 = (e) => {
        e.preventDefault()
        if (campusId !== 3) {

            setVenueId(campusId)
            setViewExams(1)
        }

        if (campusId === 3) {


            setViewExams(1)
        }

    }

    useEffect(() => {



        if (venueData) {
            venueData.map(data => {
                setViewVenueSelect(2)
                setVenueId(data.venueId)

            })
            if (venueId > 1) {
                setViewVenueSelect(2)
            } else {
                setViewVenueSelect(1)
            }
        }
    }, [venueData])



    return (
        <>





            {!viewExams && !viewTerms && !viewQuote && !viewOrder && (


                <form onSubmit={handleSubmit1} >


                    < Container maxWidth="md"  >

                        <Box mb={5} />
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} sx={{ border: 'solid', borderWidth: '3px', borderColor: 'primary.main', borderRadius: 2 }}>

                            <Grid item xs={12}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <Typography variant="h3" component="h3" color="primary.main" sx={{ textAlign: 'center' }}>
                                        External Assessment Application
                                    </Typography>



                                </Box>


                            </Grid>
                            <Grid item xs={12}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>
                                        Parents or Guardians are able to process a student’s Cambridge External Assessment entry via this portal. To process an entry, select the student you wish to enter, confirm their examination entries and complete the online payment. Once payment is complete, British International Colleges and Schools will process the student’s application with Cambridge on your behalf.

                                    </Typography>



                                </Box>


                            </Grid>

                            <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <SelectStudent studentId={studentId} setStudentId={setStudentId} />


                                </Box>

                            </Grid>


                            <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <SelectCampusByStudent studentId={studentId} campusId={campusId} setCampusId={setCampusId} />


                                </Box>

                            </Grid>

                            <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <SelectCambridgeExamSession sessionId={sessionId} setSessionId={setSessionId} camOnly={1} />


                                </Box>

                            </Grid>

                            {campusId === 3 && (

                                <>
                                    <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        >

                                            {campusId === 3 && viewVenueSelect === 1 && (

                                                <SelectVenue venueId={venueId} setVenueId={setVenueId} />

                                            )}
                                            {campusId === 3 && viewVenueSelect === 2 && (

                                                <SelectVenue venueId={venueId} setVenueId={setVenueId} disabled={1} />

                                            )}
                                            {isPendingVenue && (

                                                <Typography variant="h6" component="h3" color="secondary">
                                                    <CircularProgress />Checking Venue....
                                                </Typography>
                                            )}

                                        </Box>

                                    </Grid>


                                    <Grid item xs={12}>

                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        >
                                            <Typography variant="body2" component="p" color="primary">
                                                Only one venue can be selected as the writing location per examination session.
                                            </Typography>

                                        </Box>


                                    </Grid>
                                    <Grid item xs={12}>

                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        >


                                            <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                                <Box
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    sx={{

                                                        maxWidth: '95%',
                                                        marginLeft: 'auto',
                                                        marginRight: 'auto'
                                                    }}
                                                >


                                                    {campusId && studentId && !isPendingVenue && (
                                                        <Button fullWidth type="submit" color="primary" variant="contained">Continue<SendIcon /></Button>


                                                    )}
                                                    {(!campusId || !studentId || isPendingVenue) && (
                                                        <Button fullWidth disabled color="primary" variant="contained">Continue<SendIcon /></Button>


                                                    )}




                                                </Box>

                                            </Grid>


                                        </Box>
                                        <Box mb={5} />
                                    </Grid>
                                </>
                            )}


                            {campusId !== 3 && (

                                <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{

                                            maxWidth: '95%',
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}
                                    >


                                        {campusId && studentId && !isPendingVenue && (
                                            <Button fullWidth type="submit" color="primary" variant="contained">Continue<SendIcon /></Button>


                                        )}
                                        {(!campusId || !studentId || isPendingVenue) && (
                                            <Button fullWidth disabled color="primary" variant="contained">Continue<SendIcon /></Button>


                                        )}




                                    </Box>
                                    <Box mb={5} />
                                </Grid>
                            )}
                            <Grid item xs={12}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        >
                                            <Button fullWidth variant="contained" onClick={(e) => navigate('/cambridge')}><ArrowBackIcon /> Back</Button>


                                        </Box>


                                    </Grid>

                                </Box>

                                <Box mb={5} />
                            </Grid>







                        </Grid>

                    </Container>
                </form>

            )
            }



            {viewExams === 1 && (

                <ViewAuthList academicYear={academicYear} sessionId={sessionId} studentId={studentId} campusId={campusId} setViewExams={setViewExams} setViewTerms={setViewTerms} />
            )}

            {viewTerms === 1 && (

                <ViewTerms academicYear={academicYear} sessionId={sessionId} studentId={studentId} campusId={campusId} setViewQuote={setViewQuote} setViewTerms={setViewTerms} setViewExams={setViewExams} />
            )}

            {viewQuote === 1 && (

                <ViewQuote academicYear={academicYear} sessionId={sessionId} venueId={venueId} studentId={studentId} campusId={campusId} setViewQuote={setViewQuote} setViewTerms={setViewTerms} setViewExams={setViewExams} setViewOrder={setViewOrder} />
            )}


            {viewOrder === 1 && (

                <OrderTotal academicYear={academicYear} sessionId={sessionId} studentId={studentId} campusId={campusId} setViewQuote={setViewQuote} setViewOrder={setViewOrder} venueId={venueId} />
            )}
        </>
    )
}

export default CamApply