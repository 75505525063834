import { useAuthContext } from '../hooks/useAuthContext'
import DashCard from '../components/DashCard'
import { Container, Box, Grid } from '@mui/material'
import ConstructionIcon from '@mui/icons-material/Construction'
import SchoolIcon from '@mui/icons-material/School'
import AssignmentIcon from '@mui/icons-material/Assignment'
import PersonIcon from '@mui/icons-material/Person'
import SummarizeIcon from '@mui/icons-material/Summarize'
import GradeIcon from '@mui/icons-material/Grade'
import HeadphonesIcon from '@mui/icons-material/Headphones'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import LogoutCard from '../components/LogoutCard'
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { Typography } from '@mui/material'
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PublicIcon from '@mui/icons-material/Public';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import AssessmentIcon from '@mui/icons-material/Assessment';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import { useState, useEffect } from 'react'
import { useCampusCheck } from '../hooks/useCampusCheck'
import QuizIcon from '@mui/icons-material/Quiz';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { useAccountHolderCheck } from '../hooks/useAccountHolderCheck'
import SickIcon from '@mui/icons-material/Sick';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import GradingIcon from '@mui/icons-material/Grading';
export default function Dashboard() {

  const { accessLevel, parentID, distance, fulltime } = useAuthContext()
  const { CheckCampus, error, isPending } = useCampusCheck()
  const { checkAccountHolder, error: error2, isPending: isPending2 } = useAccountHolderCheck()
  document.title = 'BIC Parent Portal';

  useEffect(() => {
    if (parentID) {
      CheckCampus()
      checkAccountHolder()
    }
  }, [parentID])




  return (
    <Container maxWidth="xl">

      <Box mb={5} />




      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }}>




        {parentID && (

          <>

            {fulltime && (

              <Grid item xs={4} sm={4} md={4} lg={4} xl={3}>

                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"

                >
                  <DashCard link={"/absence"} cardName={"Absence Log"} cardIcon={<SickIcon />} />
                </Box>

              </Grid>
            )}

            <Grid item xs={4} sm={4} md={4} lg={4} xl={3}>

              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"

              >
                <DashCard link={"/accounts"} cardName={"Accounts"} cardIcon={<AccountBalanceIcon />} />
              </Box>

            </Grid>

            <Grid item xs={4} sm={4} md={4} lg={4} xl={3}>



              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"

              >
                <DashCard link={"/cambridge"} cardName={"Cambridge Assessments "} cardIcon={<QuizIcon />} />
              </Box>

            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={3}>



              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"

              >
                <DashCard link={"/comms"} cardName={"Communications"} cardIcon={<AnnouncementIcon />} />
              </Box>

            </Grid>




            {distance && (
              <Grid item xs={4} sm={4} md={4} lg={4} xl={3}>



                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"

                >
                  <DashCard link={"/dcexam"} cardName={"Distance College Exam"} cardIcon={<QuizIcon />} />
                </Box>

              </Grid>


            )}




            <Grid item xs={4} sm={4} md={4} lg={4} xl={3}>

              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"

              >
                <DashCard link={"/profile"} cardName={"My Profile"} cardIcon={<PersonIcon />} />
              </Box>

            </Grid>

            {fulltime && (
              <>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={3}>

                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"

                  >
                    <DashCard link={"/timetable"} cardName={"Timetable (Course/Class)"} cardIcon={<CalendarMonthIcon />} />
                  </Box>

                </Grid>

                <Grid item xs={4} sm={4} md={4} lg={4} xl={3}>

                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"

                  >
                    <DashCard link={"/examtimetable"} cardName={"Timetable (Examinations)"} cardIcon={<CalendarMonthIcon />} />
                  </Box>

                </Grid>
              </>
            )}
            {distance && (
              <Grid item xs={4} sm={4} md={4} lg={4} xl={3}>



                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"

                >
                  <DashCard link={"/mts"} cardName={"Timetable (Midterm Seminars)"} cardIcon={<CalendarMonthIcon />} />
                </Box>

              </Grid>


            )}

            <Grid item xs={4} sm={4} md={4} lg={4} xl={3}>

              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"

              >
                <DashCard link={"/resources"} cardName={"Parent Resources"} cardIcon={<CloudDownloadIcon />} />
              </Box>

            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={3}>

              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"

              >
                <DashCard link={"/registrations"} cardName={"Registration Details"} cardIcon={<AppRegistrationIcon />} />
              </Box>

            </Grid>


            <Grid item xs={4} sm={4} md={4} lg={4} xl={3}>

              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"

              >
                <DashCard link={"/results"} cardName={"Results"} cardIcon={<GradingIcon />} />
              </Box>

            </Grid>
          </>
        )}



        <Grid item xs={4} sm={4} md={4} lg={4} xl={3}>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"

          >

            <LogoutCard />


          </Box>

        </Grid>

      </Grid>

      <Box mb={5} />



    </Container >

  )
}
