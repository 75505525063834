import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../hooks/useAuthContext'
import { useFetch } from '../../hooks/useFetch'
import { useEffect, useState } from 'react'
import { Container, Box, Grid, TextField, Typography, Button, Divider, Card, Paper, CardActions, CardContent } from '@mui/material/'
import format from 'date-fns/format'
import UpdateUserPassword from '../../components/profile/UpdateUserPassword'
import EditProfile from '../../components/profile/EditProfile'
import EditIcon from '@mui/icons-material/Edit';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DataGridView from '../../components/DataGridView';
import PageviewIcon from '@mui/icons-material/Pageview';
import PreviewIcon from '@mui/icons-material/Preview';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import CancelIcon from '@mui/icons-material/Cancel';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import DescriptionIcon from '@mui/icons-material/Description';
import ConfirmSingleEntry from '../cambridge/ConfirmSingleEntry'
import DeclineSingleEntry from '../cambridge/DeclineSingleEntry'
import DeclineAllEntries from '../cambridge/DeclineAllEntries'
import ConfirmAllEntries from '../cambridge/ConfirmAllEntries'
import CircularProgress from '@mui/material/CircularProgress';
function ViewDCAuthList({ sessionId, academicYear, studentId, campusId, setViewSelect, setViewTerms, setViewStudent }) {

    const { user, accessLevel, parentID, token } = useAuthContext()



    const [confirmId, setConfirmId] = useState('')

    const [confirm, setConfirm] = useState(0)

    const [remove, setRemove] = useState(0)
    const [removeId, setRemoveId] = useState('')

    const [confirmAll, setConfirmAll] = useState(0)
    const [removeAll, setRemoveAll] = useState(0)
    const [tableData, setTableData] = useState([])


    const [examCount, setExamCount] = useState(0)
    const { postData, error, isPending, data: authList } = useFetch('https://parentapi.bi-oss.co.za/studdcexam/authorisationlist')
    useEffect(() => {
        if (studentId && campusId && academicYear && sessionId) {

            postData({
                studentId,
                campusId,
                sessionId,
                academicYear
            })
        }
    }, [studentId, campusId, academicYear, sessionId])

    useEffect((tableData) => {
        if (authList) {
            setExamCount(0)
            setTableData(authList)
            authList.map(data => {
                if (data.studentConf === 1) {
                    setExamCount(1)
                }
            })
        }


    }, [authList])

    const columns = [


        { field: 'session', headerName: 'Session:', width: 120, editable: true },
        { field: 'qualification', headerName: 'Qualification:', width: 150, editable: true },
        { field: 'paperCode', headerName: 'Code:', width: 120, editable: true },
        { field: 'description', headerName: 'Paper Description:', width: 300, editable: true },
        {
            field: "action2",
            headerName: "Status:",
            width: 120,
            renderCell: (params) => {

                if (params.row.studentConf === 0) {

                    return <TextField

                        id="stundentConf"

                        InputProps={{
                            readOnly: true,
                        }}
                        value="Declined"
                        fullWidth

                        sx={{ borderRadius: 1, input: { color: 'error.main' } }}
                    />;

                }

                if (params.row.studentConf === 1) {
                    return <TextField

                        id="stundentConf"

                        InputProps={{
                            readOnly: true,
                        }}
                        value="Approved"
                        fullWidth

                        sx={{ borderRadius: 1, input: { color: 'success.main' } }}
                    />;
                }


            }
        },

        {
            field: "action1",
            headerName: "Action",
            width: 150,
            renderCell: (params) => {
                const onClick1 = (e) => {
                    e.stopPropagation() // don't select this row after clicking

                    setConfirmId(params.row.id)
                    setConfirm(1)

                }
                const onClick2 = (e) => {
                    e.stopPropagation() // don't select this row after clicking

                    setRemoveId(params.row.id)
                    setRemove(1)

                }
                if (params.row.studentConf === 0) {


                    return <Button color="primary" onClick={onClick1} fullWidth variant="contained">Approve <ThumbUpIcon /></Button>;


                }

                if (params.row.studentConf === 1) {
                    return <Button color="primary" onClick={onClick2} fullWidth variant="contained">Decline <ThumbDownOffAltIcon /></Button>;

                }


            }
        },

    ];

    const handleComplete = (e) => {

        e.preventDefault()
        setRemoveId('')
        setConfirmId('')
        setRemove(0)
        setConfirm(0)
        setConfirmAll(0)
        setRemoveAll(0)
        postData({
            studentId,
            campusId,
            sessionId,
            academicYear
        })
    }


    const handleOnCellClick = (e) => {
        // window.open(e.row.fileURL, '_blank')
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setViewSelect(0)
        setViewTerms(1)
    }


    const handleCancel = (e) => {
        e.preventDefault()
        setViewSelect(0)
        setViewStudent(1)
    }
    return (
        <>
            {isPending && (

                <Container maxWidth="lg" >

                    <Box mb={5} />



                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} sx={{ border: 'solid', borderWidth: '3px', borderColor: "primary.main", borderRadius: 2 }}>

                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"

                            >
                                <Typography variant="h6" component="h3" color="primary.main">
                                    Fetching authorisation list ...
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"

                            >
                                <CircularProgress />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            )}

            {!remove && !confirm && !confirmAll && !removeAll && !isPending && (

                <Container maxWidth="lg" >

                    <Box mb={5} />
                    <form onSubmit={handleSubmit}>


                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} sx={{ border: 'solid', borderWidth: '3px', borderColor: "primary.main", borderRadius: 2 }}>


                            <Grid item xs={12}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >
                                    <Typography variant="h3" component="h3" color="primary.main" sx={{ textAlign: 'center' }}>
                                        Distance College Examination
                                    </Typography>

                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >
                                    <Typography variant="h6" component="h6" color="primary.main" sx={{ textAlign: 'center' }}>
                                        Authorisation List
                                    </Typography>

                                </Box>
                            </Grid>
                            <Grid item xs={12}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >


                                    <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>
                                        British International Distance College students should contact their student advisor to discuss examination entry and gain entry authorisation.

                                    </Typography>





                                </Box>


                            </Grid>

                            <Grid item xs={12}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >





                                    <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>
                                        <b>Entry Process:</b> Approve the examination papers that the student wishes to apply for and then process the entry request payment online. Distance College examinations are written at home and should be completed under examination conditions.


                                    </Typography>



                                </Box>


                            </Grid>

                            {!isPending && !error && tableData && tableData.length > 0 && (
                                <Grid item xs={12} minWidth={0}>
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"

                                    >
                                        <DataGridView

                                            tableData={tableData}
                                            error={error} isPending={isPending} getFormattedDate={null} columns={columns} pageSize={15} handleOnCellClick={handleOnCellClick}
                                            getRowId={(row) => row.id}
                                        />
                                    </Box>
                                </Grid>

                            )}

                            {!isPending && !error && tableData && tableData.length === 0 && (
                                <Grid item xs={12}>
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"

                                    >


                                        <Typography variant="body2" component="p" color="primary">
                                            <b>There are currently no authorisation for the selected student. </b>


                                        </Typography>

                                    </Box>
                                </Grid>

                            )}
                            <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >

                                    {examCount > 0 && (

                                        <Button fullWidth variant="contained" onClick={(e) => setRemoveAll(1)}>Decline All <ThumbDownOffAltIcon /></Button>

                                    )}


                                </Box>

                            </Grid>

                            <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >

                                    {examCount > 0 && (

                                        <Button fullWidth variant="contained" onClick={(e) => setConfirmAll(1)}>Approve All <ThumbUpIcon /></Button>

                                    )}


                                </Box>


                            </Grid>

                            <Grid item xs={12}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >


                                    <Typography variant="body2" component="p" color="primary">
                                        Once you have approved the desired examination paper combinations, click the generate quote button to view the current entry fee.

                                    </Typography>


                                </Box>
                            </Grid>


                            <Grid item xs={12}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >
                                    <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                        >

                                            {examCount > 0 && (

                                                <Button fullWidth color={"primary"} variant="contained" type="submit">Generate Quote <DescriptionIcon /></Button>

                                            )}
                                            {(!examCount || examCount === 0) && (

                                                <Button fullWidth color={"primary"} variant="contained" disabled>Generate Quote <DescriptionIcon /></Button>

                                            )}


                                        </Box>
                                    </Grid>

                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >






                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >



                                    <Typography variant="body2" component="p" color="primary">
                                        To cancel processing an entry or change the selected student, click below.
                                    </Typography>




                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >

                                    <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        >


                                            <Button fullWidth color={"primary"} variant="contained" onClick={handleCancel}>Cancel <CancelIcon /></Button>


                                        </Box>
                                        <Box mb={5} />
                                    </Grid>


                                </Box>
                            </Grid>
                        </Grid >


                        <Box mb={5} />

                    </form>

                </Container >

            )
            }



            {
                confirm === 1 && (

                    <ConfirmSingleEntry confirmId={confirmId} setConfirm={setConfirm} handleComplete={handleComplete} />
                )
            }

            {
                remove === 1 && (

                    <DeclineSingleEntry removeId={removeId} setRemove={setRemove} handleComplete={handleComplete} campusId={campusId} hideNotice={1} />
                )
            }
            {
                confirmAll === 1 && (

                    <ConfirmAllEntries sessionId={sessionId} academicYear={academicYear} studentId={studentId} campusId={campusId} handleComplete={handleComplete} setConfirmAll={setConfirmAll} />
                )
            }

            {
                removeAll === 1 && (

                    <DeclineAllEntries sessionId={sessionId} academicYear={academicYear} studentId={studentId} campusId={campusId} handleComplete={handleComplete} setRemoveAll={setRemoveAll} hideNotice={1} />
                )
            }
        </>
    )
}

export default ViewDCAuthList