import React, { useState, useEffect } from 'react'
import { Container, OutlinedInput, Box, Grid, TextField, Typography, Button, InputLabel, FormControl, Select, MenuItem } from '@mui/material/'
import { useFetch } from '../../hooks/useFetch'
import SelectTitle from '../SelectTitle'
import SelectNationality from '../SelectNationality'
import { TitleOutlined } from '@mui/icons-material'
import DoneOutlineIcon from '@mui/icons-material/DoneOutline'
import { useAuthContext } from '../../hooks/useAuthContext'
import CancelIcon from '@mui/icons-material/Cancel'
import SendIcon from '@mui/icons-material/Send'
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SelectedStudent from '../SelectedStudent'
import SelectedParent from '../SelectedParent'
function ChangeAccountAccess({ spouseId, studentId, status, setStatus, handleComplete, setChange }) {


    const { user, parentID } = useAuthContext()

    const [success, setSuccess] = useState(0)


    const { updateData, errorMessage, error, isPending, data: updated } = useFetch(`https://parentapi.bi-oss.co.za/profile/updateaccountaccess`, 'PUT')

    const handleSubmit = (e) => {

        e.preventDefault()


        updateData({
            spouseId,
            status,
            studentId,
            updatedBy: parentID


        })
    }


    useEffect(() => {


        if (updated) {
            setSuccess(1)

        }


    }, [updated])
    return (
        <>
            <Container maxWidth="md">

                {!success && (
                    <form autoComplete="off" onSubmit={handleSubmit}>
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} sx={{ border: 'solid', borderWidth: '3px', borderColor: (success ? "success.main" : "secondary.main"), borderRadius: 2 }}>



                            <Grid item xs={12} >
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >

                                    <Typography variant="h6" component="h3" color="secondary">
                                        Update Account Access
                                    </Typography>


                                </Box>

                            </Grid>

                            {error && !errorMessage && (

                                <Grid item xs={12} >
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"

                                    >

                                        <Typography variant="h6" component="h3" className='error' fullWidth sx={{ textAlign: 'center' }}>
                                            Unable to update account access. You may be offline.
                                        </Typography>

                                    </Box>
                                </Grid>
                            )}

                            {errorMessage && (

                                <Grid item xs={12} >
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"

                                    >

                                        <Typography variant="h6" component="h3" className='error' fullWidth sx={{ textAlign: 'center' }}>
                                            {errorMessage}
                                        </Typography>

                                    </Box>
                                </Grid>
                            )}

                            {isPending && (

                                <Grid item xs={12} >
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"

                                    >

                                        <CircularProgress />
                                        <Typography variant="h6" component="h3" color="secondary">
                                            Updating....
                                        </Typography>

                                    </Box>
                                </Grid>
                            )}
                            {!isPending && (

                                <>


                                    <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"

                                        >
                                            <SelectedStudent studentId={studentId} />

                                        </Box>


                                    </Grid>
                                    <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"

                                        >


                                        </Box>


                                    </Grid>
                                    <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"

                                        >
                                            <SelectedParent parentId={spouseId} />

                                        </Box>


                                    </Grid>

                                    <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"

                                        >


                                            <FormControl fullWidth>
                                                <InputLabel id="title-label">Select Access Level*</InputLabel>
                                                <Select
                                                    labelId="title-label"
                                                    id="status"
                                                    value={status}
                                                    label="Select Access Level*"
                                                    onChange={(e) => setStatus(e.target.value)}
                                                    required

                                                >



                                                    <MenuItem key={0} value={0}>Deny Access</MenuItem>
                                                    <MenuItem key={1} value={1}>Grant Access</MenuItem>


                                                </Select>
                                            </FormControl>
                                        </Box>


                                    </Grid>
                                    <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        >

                                            {!isPending && (
                                                <Button fullWidth color={"primary"} variant="contained" onClick={(e) => setChange(0)}>Cancel <CancelIcon /></Button>


                                            )}



                                        </Box>


                                    </Grid>


                                    <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        >


                                            {!isPending && (

                                                <Button fullWidth color="secondary" type="submit" variant="contained">Update <SendIcon /></Button>

                                            )}




                                        </Box>
                                        <Box mb={5} />

                                    </Grid>
                                </>
                            )}
                            <Box mb={5} />
                        </Grid>
                    </form>
                )}
                {success === 1 && (

                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} sx={{ border: 'solid', borderWidth: '3px', borderColor: "success.main", borderRadius: 2 }}>


                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"

                            >

                                <Typography variant="h6" component="h3" padding={1} borderRadius={1} color="success.main">
                                    Accounst Access Level updated
                                </Typography>


                            </Box>

                        </Grid>





                        <Grid item xs={12} >
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >

                                <Button color="success" fullWidth type="button" variant="contained" onClick={handleComplete}>Close<DoneOutlineIcon /></Button>
                            </Box>
                            <Box mb={5} />
                        </Grid>


                    </Grid>
                )}
                <Box mb={5} />
            </Container >

        </>

    )
}

export default ChangeAccountAccess