import { createContext, useReducer, useEffect } from 'react'
import { onAuthStateChanged } from 'firebase/auth'
import { auth, db } from '../firebase/config'
import { doc, getDoc } from "firebase/firestore"

export const AuthContext = createContext()

export const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, user: action.payload }
    case 'LOGOUT':
      return {
        ...state,
        user: null,
        authIsReady: null,
        parentID: null,
        distance: null,
        fulltime: null,
        accountHolder: null,
        shareAccount: null,
        token: null

      }
    case 'AUTH_IS_READY':
      return { user: action.payload, authIsReady: true }
    case 'ACCESSPARENT':
      return { ...state, parentID: action.payload }
    case 'DCACCESS':
      return { ...state, distance: action.payload }
    case 'FULLTIME':
      return { ...state, fulltime: action.payload }
    case 'ACCOUNTHOLDER':
      return { ...state, accountHolder: action.payload }
    case 'SHAREACCOUNT':
      return { ...state, shareAccount: action.payload }
    case 'CREATETOKEN':
      return { ...state, token: action.payload }
    default:
      return state
  }
}

export const AuthContextProvider = ({ children }) => {


  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    authIsReady: null,
    token: null,
    parentID: null,
    distance: null,
    fulltime: null,
    accountHolder: null,
    shareAccount: null,
  })

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, user => {
      dispatch({ type: 'AUTH_IS_READY', payload: user })

      unsub()
    })
  }, [])

  useEffect(() => {

    const unsub = onAuthStateChanged(auth, user => {

      if (user) {
        const docRef = doc(db, "users", user.uid)

        user.getIdTokenResult().then(accessToken => {
          if (accessToken) {

            if (accessToken.claims.pid) {

              dispatch({ type: 'ACCESSPARENT', payload: accessToken.claims.pid })

            }
          }


        })
        dispatch({ type: 'CREATETOKEN', payload: user.accessToken })
        unsub()
      }





    })
  }, [])




  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  )

}