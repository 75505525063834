import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, Box, Paper } from '@mui/material';

import { useNavigate } from 'react-router-dom'

export default function DashCard({ link, cardName, cardIcon, disabled, report }) {

    const navigate = useNavigate()

    return (

        <Card sx={{
            width: 300,
            color: 'white',
            maxWidth: 250,
            alignItems: 'center',
            height: 80,
            bgcolor: report ? 'success.dark' : disabled ? '#9e9e9e' : 'primary.main',

        }}>


            {!disabled && (
                <CardActionArea onClick={() => navigate(link)} >


                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div" textAlign="center">
                            {cardIcon}  {cardName}
                        </Typography>
                    </CardContent>

                </CardActionArea>

            )}

            {disabled && (
                <CardActionArea disabled onClick={() => navigate(link)} >


                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div" textAlign="center">
                            {cardIcon}  {cardName}
                        </Typography>
                    </CardContent>

                </CardActionArea>

            )}

        </Card>


    )
}