
import { Container, Typography } from '@mui/material';
import { DataGrid, GridToolbar, GridToolbarExport, GridToolbarContainer } from '@mui/x-data-grid';

export default function DataGridView({ tableData, error, isPending, columns, pageSize, handleOnCellClick, getRowId, secondaryColor }) {




    return (


        // <Container bgcolor='primary.main' maxWidth="xl" sx={{ bgcolor: "secondary.main" }}>
        <Container bgcolor='primary.main' maxWidth="xxl" textAlign='center' >


            {error && <Typography className="error" sx={{ textAlign: 'center' }}>{error}</Typography>}
            {isPending && <Typography color='primary.main' sx={{ textAlign: 'center' }}>Fetching data...</Typography>}




            {secondaryColor === 1 && (

                <DataGrid
                    rows={tableData}
                    columns={columns}
                    pageSize={pageSize}


                    sx={{ color: 'secondary.main', borderColor: 'secondary.main', borderWidth: 3, fontWeight: 'bold', m: 1, align: 'center', headerAlign: 'center', autoCapitalize: 'words' }}

                    getRowId={getRowId}
                    autoHeight
                    disableRowSelectionOnClick={true}
                    disableColumnSelector={true}


                />
            )}



            {!secondaryColor && (
                <>

                    <DataGrid
                        rows={tableData}
                        columns={columns}

                        initialState={{
                            ...tableData.initialState,
                            pagination: { paginationModel: { pageSize: pageSize } },
                        }}

                        pageSizeOptions={[5, 10, 15, 20, 25, 30, 50, 100]}


                        sx={{ color: 'primary.main', borderColor: 'primary.main', borderWidth: 3, fontWeight: 'bold', m: 1, align: 'center', headerAlign: 'center', autoCapitalize: 'words' }}
                        disableRowSelectionOnClick={true}
                        disableColumnSelector={true}
                        getRowId={getRowId}
                        autoHeight


                    />
                </>
            )}






        </Container>
    )
}
