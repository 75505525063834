import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../context/AuthContext'
import { useFetch } from '../../hooks/useFetch'
import { useEffect, useState } from 'react'
import { Container, Box, Grid, TextField, Typography, Button, Divider, Card, Paper, CardActions, CardContent } from '@mui/material/'
import DashboardIcon from '@mui/icons-material/Dashboard';
import DataGridView from '../../components/DataGridView';
import SelectedStudent from '../../components/SelectedStudent'
import SelectedCampus from '../../components/SelectedCampus'
import format from 'date-fns/format'
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
function ViewTimetable({ studentId, setViewTimetable }) {

    const [tableData, setTableData] = useState([])

    const { postData, error, errorMessage, isPending, data: resultsData } = useFetch(`https://parentapi.bi-oss.co.za/students/timetable/${studentId}`, 'POST')
    const currentMonth = new Date().getMonth()

    useEffect(() => {
        if (studentId) {
            postData()
        }
    }, [studentId])


    const navigate = useNavigate()

    const classes = {};
    const [viewAll, setViewAll] = useState(0)
    document.title = 'BIC Parent Portal: Timetable';
    const [campusId, setCampusId] = useState('')

    useEffect((tableData) => {
        if (resultsData) {
            setTableData(resultsData)
            resultsData.map(data => {
                setCampusId(data.campusId)
            })
        }


    }, [resultsData])

    const handleOnCellClick = (e) => {
        // window.open(e.row.fileURL, '_blank')
    }

    const openLink = (url) => {
        window.open(url, '_blank')
    }
    const columns = [

        { field: 'WeekdayName', headerName: 'Day:', width: 120 },
        {
            field: "action1",
            headerName: "Time:",
            sortable: false,
            width: 150,

            renderCell: (params) => {




                return <TextField



                    InputProps={{
                        readOnly: true,
                    }}
                    value={params.row.startTime + ' - ' + params.row.endTime}
                    fullWidth
                    required
                />;



            }
        },
        {
            field: "action3",
            headerName: "Course:",
            sortable: false,
            width: 300,

            renderCell: (params) => {




                return <TextField



                    InputProps={{
                        readOnly: true,
                    }}
                    value={params.row.courseName + ' (' + params.row.designation + ')'}
                    fullWidth
                    required
                />;



            }
        },

        {
            field: "action2",
            headerName: "Venue:",
            sortable: false,
            width: 300,

            renderCell: (params) => {




                return <TextField



                    InputProps={{
                        readOnly: true,
                    }}
                    value={params.row.name + ' (' + params.row.roomCode + ')'}
                    fullWidth
                    required
                />;



            }
        },
        {
            field: "action4",
            headerName: "Lecturer:",
            sortable: false,
            width: 300,

            renderCell: (params) => {




                return <TextField



                    InputProps={{
                        readOnly: true,
                    }}
                    value={params.row.title + ' ' + params.row.lname}
                    fullWidth
                    required
                />;



            }
        },
    ];
    const columns2 = [

        { field: 'WeekdayName', headerName: 'Day:', width: 120 },
        {
            field: "action1",
            headerName: "Time:",
            sortable: false,
            width: 150,

            renderCell: (params) => {




                return <TextField



                    InputProps={{
                        readOnly: true,
                    }}
                    value={params.row.startTime + ' - ' + params.row.endTime}
                    fullWidth
                    required
                />;



            }
        },
        {
            field: "action3",
            headerName: "Subject:",
            sortable: false,
            width: 300,

            renderCell: (params) => {




                return <TextField



                    InputProps={{
                        readOnly: true,
                    }}
                    value={params.row.courseName + ' (' + params.row.designation + ')'}
                    fullWidth
                    required
                />;



            }
        },

        {
            field: "action2",
            headerName: "Classroom:",
            sortable: false,
            width: 300,

            renderCell: (params) => {




                return <TextField



                    InputProps={{
                        readOnly: true,
                    }}
                    value={params.row.name + ' (' + params.row.roomCode + ')'}
                    fullWidth
                    required
                />;



            }
        },
        {
            field: "action4",
            headerName: "Teacher:",
            sortable: false,
            width: 300,

            renderCell: (params) => {




                return <TextField



                    InputProps={{
                        readOnly: true,
                    }}
                    value={params.row.title + ' ' + params.row.lname}
                    fullWidth
                    required
                />;



            }
        },
    ];
    return (
        <Container maxWidth="lg" >
            <>
                <Box mb={5} />
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} sx={{ border: 'solid', borderWidth: '3px', borderColor: "primary.main", borderRadius: 2 }}>
                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"

                        >
                            {currentMonth === 0 && (

                                <Typography variant="h6" component="h6" color="primary">
                                    Students Timetable (Temporary)
                                </Typography>
                            )}

                            {currentMonth !== 0 && (

                                <Typography variant="h6" component="h6" color="primary">
                                    Students Timetable
                                </Typography>
                            )}
                        </Box>
                    </Grid>
                    {currentMonth === 0 && (
                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"

                            >


                                <Typography variant="body2" component="p" color="primary">
                                    Please note, British International Colleges and Schools publishes a temporary timetable during the month on January. Timetables are finalised in February each year to accommodate for all students subject choices. Additional amendments to the timetable may also occur throughout the academic year, most notably during the final term.
                                </Typography>

                            </Box>
                        </Grid>

                    )}
                    {!errorMessage && !isPending && studentId && (

                        <>


                            <Grid item xs={12}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        >
                                            <SelectedStudent studentId={studentId} />

                                        </Box>


                                    </Grid>
                                </Box>


                            </Grid>


                        </>
                    )}
                    {isPending && !error && (

                        <>
                            <Grid item xs={12}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >
                                    <Typography variant="h6" component="h3" color="primary">
                                        Fetching timetable data ...
                                    </Typography>
                                </Box>
                            </Grid>  <Grid item xs={12}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >
                                    <CircularProgress />
                                </Box>
                            </Grid>
                        </>

                    )}

                    {error && !errorMessage && (
                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"

                            >
                                <Typography variant="h6" component="h3" color="error">
                                    Unable to fetch timetable. You may be offline. Please try again.


                                </Typography>
                            </Box>
                        </Grid>
                    )}

                    {errorMessage && (
                        <>
                            <Grid item xs={12} sx={{ display: { xs: "none", sm: "block" }, }}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >
                                    <Typography variant="h6" component="h3" color="error">
                                        Timetable Locked

                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sx={{ display: { xs: "none", sm: "block" }, }}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >
                                    <Typography variant="body2" component="p">
                                        <b>Reason:</b>  {errorMessage}

                                    </Typography>
                                </Box>
                            </Grid>
                        </>
                    )}

                    {resultsData && resultsData.length === 0 && (
                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"

                            >
                                <Typography variant="body2" component="p" color="primary">
                                    <b>There are currently no timetabled classes to view for the selected student.</b>
                                </Typography>
                            </Box>
                        </Grid>

                    )}
                    {resultsData && resultsData.length > 0 && campusId !== 4 && (
                        <Grid item xs={12} minWidth={0} fullWidth>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"

                            >
                                <DataGridView
                                    fullWidth
                                    tableData={tableData}
                                    error={error} isPending={isPending} getFormattedDate={null} columns={columns} handleOnCellClick={handleOnCellClick}
                                    getRowId={(row) => row.id}
                                    pageSize={10}

                                />
                            </Box>
                        </Grid>

                    )}
                    {resultsData && resultsData.length > 0 && campusId === 4 && (
                        <Grid item xs={12} minWidth={0} fullWidth>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"

                            >
                                <DataGridView
                                    fullWidth
                                    tableData={tableData}
                                    error={error} isPending={isPending} getFormattedDate={null} columns={columns2} handleOnCellClick={handleOnCellClick}
                                    getRowId={(row) => row.id}
                                    pageSize={15}

                                    pagination
                                />
                            </Box>
                        </Grid>

                    )}
                    <Grid item xs={12}>

                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{

                                maxWidth: '95%',
                                marginLeft: 'auto',
                                marginRight: 'auto'
                            }}
                        >
                            <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <Button fullWidth variant="contained" onClick={(e) => setViewTimetable(0)}>Close <CloseIcon /></Button>


                                </Box>

                                <Box mb={3} />
                            </Grid>

                        </Box>

                        <Box mb={3} />
                    </Grid>
                </Grid>
                <Box mb={5} />
            </>
        </Container >
    )
}

export default ViewTimetable