import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../hooks/useAuthContext'
import { useFetch } from '../../hooks/useFetch'
import { useEffect, useState } from 'react'
import { Container, Box, Grid, TextField, Typography, Button, Divider, Card, Paper, CardActions, CardContent } from '@mui/material/'
import format from 'date-fns/format'
import UpdateUserPassword from '../../components/profile/UpdateUserPassword'
import EditProfile from '../../components/profile/EditProfile'
import EditIcon from '@mui/icons-material/Edit';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DataGridView from '../../components/DataGridView';
import PageviewIcon from '@mui/icons-material/Pageview';
import PreviewIcon from '@mui/icons-material/Preview';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ConfirmSingleEntry from './ConfirmSingleEntry'
import DeclineSingleEntry from './DeclineSingleEntry'
import ConfirmAllEntries from './ConfirmAllEntries'
import CancelIcon from '@mui/icons-material/Cancel';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import DeclineAllEntries from './DeclineAllEntries'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import DescriptionIcon from '@mui/icons-material/Description';
import clsx from 'clsx';
function ViewEntryStatus({ studentId, sessionId, setProcessEntry }) {
    const [tableData, setTableData] = useState([])


    const { postData, error, isPending, data: authList } = useFetch('https://parentapi.bi-oss.co.za/studcambridge/entrystatus')

    const handleOnCellClick = (e) => {
        // window.open(e.row.fileURL, '_blank')
    }
    useEffect(() => {
        if (studentId && sessionId) {
            setProcessEntry(0)
            postData({
                studentId,
                sessionId,


            })
        }
    }, [studentId, sessionId])


    useEffect((tableData) => {
        if (authList) {

            setTableData(authList)
            authList.map(data => {
                if (data.paid === 0) {
                    setProcessEntry(1)
                }
            })




        }


    }, [authList])


    const columns = [

        { field: 'academicYear', headerName: 'Year:', width: 100, editable: true, cellClassName: 'super-app-theme--cell' },
        { field: 'campus', headerName: 'Campus:', width: 150, editable: true },
        { field: 'session', headerName: 'Session:', width: 120, editable: true },
        { field: 'qualification', headerName: 'Qualification:', width: 120, editable: true },
        { field: 'paperCode', headerName: 'Code:', width: 120, editable: true },
        { field: 'description', headerName: 'Paper Description:', width: 200, editable: true },


        {
            field: "action2",
            headerName: "Entry Status:",
            width: 150,
            renderCell: (params) => {

                if (params.row.paid === 0) {

                    return <TextField

                        id="stundentConf"

                        InputProps={{
                            readOnly: true,
                        }}
                        value="Incomplete"
                        fullWidth

                        sx={{ borderRadius: 1, input: { color: 'error.main' } }}
                    />;

                }

                if (params.row.paid === 1) {
                    return <TextField

                        id="stundentConf"

                        InputProps={{
                            readOnly: true,
                        }}
                        value="Complete"
                        fullWidth

                        sx={{ borderRadius: 1, input: { color: 'success.main' } }}
                    />;
                }


            }
        },

        { field: 'venue', headerName: 'Writing Location:', width: 200, editable: true },

    ];
    return (
        <>

            {isPending && (




                <Grid item xs={12}>
                    <Box
                        sx={{
                            height: 300,
                            width: '100%',

                            '.negative': {
                                backgroundColor: 'rgba(157, 255, 118, 0.49)',
                                color: '#1a3e72',
                                fontWeight: '600',
                            },
                            '.positive': {
                                backgroundColor: '#d47483',
                                color: '#1a3e72',
                                fontWeight: '600',
                            },
                        }}
                    >
                        <Typography variant="h6" component="h3" color="primary.main">
                            Fetching authorisation list ...
                        </Typography>
                    </Box>
                </Grid>

            )}


            {!isPending && !error && tableData && tableData.length > 0 && (




                <Grid item xs={12} minWidth={0}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"

                    >
                        <DataGridView

                            tableData={tableData}
                            error={error} isPending={isPending} getFormattedDate={null} columns={columns} pageSize={15} handleOnCellClick={handleOnCellClick}
                            getRowId={(row) => row.id}
                            getRowClassName={(params) =>
                                params.row.paid === 1 ? 'positive' : 'negative'
                            }

                        />
                    </Box>
                </Grid>


            )}

            {!isPending && !error && tableData && tableData.length === 0 && (




                <Grid item xs={12} minWidth={0}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"

                    >
                        <Typography variant="body2" component="p" color="primary">
                            <b>There are currently no authorisations for the selected student.</b>


                        </Typography>
                    </Box>
                </Grid>


            )}

        </>
    )
}

export default ViewEntryStatus