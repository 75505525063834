import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../hooks/useAuthContext'
import { useFetch } from '../../hooks/useFetch'
import { useEffect, useState } from 'react'
import { Container, Box, Grid, TextField, Typography, Button, Divider, Card, Paper, CardActions, CardContent } from '@mui/material/'
import DashboardIcon from '@mui/icons-material/Dashboard';
import DataGridView from '../../components/DataGridView';
import PageviewIcon from '@mui/icons-material/Pageview';
import PreviewIcon from '@mui/icons-material/Preview';
import SelectStudent from '../../components/SelectStudent'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SelectCampusByStudent from '../../components/SelectCampusByStudent'
import ViewReg from '../../components/reg/ViewReg'

function Registrations() {

    document.title = 'BIC Parent Portal: Student Registrations';
    const [viewReg, setViewReg] = useState(0)
    const [studentId, setStudentId] = useState('')
    const [campusId, setCampusId] = useState('')

    const navigate = useNavigate()

    const handleSubmit = (e) => {
        e.preventDefault()
        setViewReg(1)
    }

    return (
        <>
            {!viewReg && (

                <form onSubmit={handleSubmit} >


                    < Container maxWidth="md"  >

                        <Box mb={5} />
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} sx={{ border: 'solid', borderWidth: '3px', borderColor: 'primary.main', borderRadius: 2 }}>

                            <Grid item xs={12}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <Typography variant="h3" component="h3" color="primary.main" sx={{ textAlign: 'center' }}>
                                        Registration Details
                                    </Typography>



                                </Box>


                            </Grid>
                            <Grid item xs={12}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>
                                        Should you require a Confirmation of Registration document, kindly email the Group Registrar via <a href="mailto:registrar@bicollege.co.za">registrar@bicollege.co.za</a>.
                                    </Typography>



                                </Box>


                            </Grid>
                            <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <SelectStudent studentId={studentId} setStudentId={setStudentId} />


                                </Box>

                            </Grid>



                            <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >


                                    {studentId && (

                                        <Button fullWidth type="submit" color="primary" variant="contained">View <PreviewIcon /></Button>

                                    )}

                                    {!studentId && (

                                        <Button fullWidth disabled color="primary" variant="contained">View <PreviewIcon /></Button>

                                    )}






                                </Box>
                                <Box mb={5} />
                            </Grid>


                            <Grid item xs={12}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        >
                                            <Button fullWidth variant="contained" onClick={(e) => navigate(-1)}>Dashboard <DashboardIcon /></Button>


                                        </Box>

                                        <Box mb={3} />
                                    </Grid>


                                </Box>

                                <Box mb={3} />
                            </Grid>



                        </Grid>
                    </Container>
                </form>


            )
            }
            {viewReg === 1 && (
                <>

                    <ViewReg studentId={studentId} setViewReg={setViewReg} />
                </>

            )}
        </>
    )
}

export default Registrations