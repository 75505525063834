import React from 'react'
import { OutlinedInput, InputLabel, FormControl, Select, MenuItem, TextField } from '@mui/material/'

function ViewAccStatus({ statusId }) {
    return (
        <FormControl fullWidth>

            {statusId === 0 && (
                <TextField

                    id="accStatus"
                    label="Account Status - Current Academic Year"
                    InputProps={{
                        readOnly: true,
                    }}
                    value="Inactive"
                    fullWidth

                    sx={{ borderRadius: 1, input: { color: 'primary.main' } }}
                />

            )}


            {statusId === 1 && (
                <TextField

                    id="accStatus"
                    label="Account Status - Current Academic Year"
                    InputProps={{
                        readOnly: true,
                    }}
                    value="Active"
                    fullWidth

                    sx={{ fontWeight: 'bold', borderRadius: 1, input: { fontWeight: 'bold', color: 'success.main' } }}
                />

            )}
            {statusId === 2 && (
                <TextField

                    id="accStatus"
                    label="Account Status - Current Academic Year"
                    InputProps={{
                        readOnly: true,
                    }}
                    value="Active - with payment plan"
                    fullWidth

                    sx={{ borderRadius: 1, input: { fontWeight: 'bold', color: 'success.main' } }}
                />

            )}

            {statusId === 3 && (
                <TextField

                    id="accStatus"
                    label="Account Status - Current Academic Year"
                    InputProps={{
                        readOnly: true,
                    }}
                    value="Profile Locked (Outstanding Account)"
                    fullWidth
                    sx={{ borderRadius: 1, input: { fontWeight: 'bold', color: 'primary.main' } }}
                />

            )}
            {statusId === 4 && (
                <TextField

                    id="accStatus"
                    label="Account Status - Current Academic Year"
                    InputProps={{
                        readOnly: true,
                    }}
                    value="Profile Locked (Financial Suspension)"
                    fullWidth
                    sx={{ borderRadius: 1, input: { fontWeight: 'bold', color: 'primary.main' } }}
                />

            )}
            {statusId === 5 && (
                <TextField

                    id="accStatus"
                    label="Account Status - Current Academic Year"
                    InputProps={{
                        readOnly: true,
                    }}
                    value="Profile Locked (Academic Suspension)"
                    fullWidth
                    sx={{ borderRadius: 1, input: { fontWeight: 'bold', color: 'primary.main' } }}
                />

            )}



        </FormControl>
    )
}

export default ViewAccStatus