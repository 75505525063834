import { useState, useEffect } from "react"
import { auth } from '../firebase/config'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { useAuthContext } from '../hooks/useAuthContext'
import { useFetch } from './useFetch'
import { set } from "date-fns"


export const useAccountHolderCheck = () => {


    const { dispatch, parentID } = useAuthContext()

    const { getData, error, errorMessage, isPending, data } = useFetch(`https://parentapi.bi-oss.co.za/profile/accountholdercheck/${parentID}`)

    const checkAccountHolder = async () => {

        getData()

    }


    useEffect(() => {
        if (data) {
            data.map(data => {
                if (data.accountHolder === 1) {
                    dispatch({ type: 'ACCOUNTHOLDER', payload: data.accountHolder })

                }
                if (data.shareAccount === 1) {
                    dispatch({ type: 'SHAREACCOUNT', payload: data.shareAccount })

                }


            })


        }

    }, [data])




    return { error, checkAccountHolder, isPending }
}