import React from 'react'
import { Container, Box, Grid } from '@mui/material'
import { Typography } from '@mui/material'
import AppAppBar2 from '../components/AppBar2'
import ProductHero from '../components/ProductHero'
export default function HomePage() {
    return (


        <Container bgcolor='black' maxWidth={false} disableGutters sx={{ height: '100vh' }}  >
            <AppAppBar2 />
            <ProductHero />

        </Container>

    )
}
