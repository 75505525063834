import { useState, useEffect } from "react"
import { auth } from '../firebase/config'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { useAuthContext } from '../hooks/useAuthContext'
import { useFetch } from './useFetch'
import { set } from "date-fns"


export const useCampusCheck = () => {


    const { dispatch, parentID } = useAuthContext()

    const { getData, error, isPending, data } = useFetch(`https://parentapi.bi-oss.co.za/profile/checklinkedcampus/${parentID}`, 'GET')

    const CheckCampus = async () => {


        getData()



    }

    useEffect(() => {
        if (data) {
            data.map(data => {
                if (data.campusId === 1 && data.campusId !== 6) {
                    dispatch({ type: 'FULLTIME', payload: 1 })

                }
                if (data.campusId === 2 && data.campusId !== 6) {
                    dispatch({ type: 'FULLTIME', payload: 1 })

                }

                if (data.campusId === 4 && data.campusId !== 6) {
                    dispatch({ type: 'FULLTIME', payload: 1 })

                }

                if (data.campusId === 3) {
                    dispatch({ type: 'DCACCESS', payload: data.campusId })

                }


            })


        }

    }, [data])




    return { error, CheckCampus, isPending }
}