import React, { useState, useEffect } from 'react'
import { Container, OutlinedInput, Box, Grid, TextField, Typography, Button, InputLabel, FormControl, Select, MenuItem } from '@mui/material/'
import { useFetch } from '../../hooks/useFetch'
import SelectTitle from '../SelectTitle'
import SelectNationality from '../SelectNationality'
import { TitleOutlined } from '@mui/icons-material'
import DoneOutlineIcon from '@mui/icons-material/DoneOutline'
import { useAuthContext } from '../../hooks/useAuthContext'
import CancelIcon from '@mui/icons-material/Cancel'
import SendIcon from '@mui/icons-material/Send'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import format from 'date-fns/format'
import SelectRace from '../SelectRace'
import SelectSALang from '../SelectSALang'
import CircularProgress from '@mui/material/CircularProgress';
import AddIcon from '@mui/icons-material/Add';
import EnterIDPass from '../EnterIDPass'
import EnterEmail from '../EnterEmail'

function EditProfile({ data, setEdit, handleComplete }) {


    const { user, parentID } = useAuthContext()



    const { updateData, errorMessage, error, isPending, data: updated } = useFetch(`https://parentapi.bi-oss.co.za/profile/${parentID}`, 'PUT')



    const [success, setSuccess] = useState(0)
    const [title, setTitle] = useState('')
    const [fname, setFname] = useState('')
    const [lname, setLname] = useState('')
    const [nation, setNation] = useState('')
    const [idpass, setIdPass] = useState('')
    const [email, setEmail] = useState('')
    const [saMobile, setSaMobile] = useState('')
    const [intMobile, setIntMobile] = useState('')
    const [languageId, setLanguageId] = useState('')

    const [gender, setGender] = useState('')
    const [dob, setDob] = useState('')
    const [raceId, setRaceId] = useState('')
    const [homeTel, setHomeTel] = useState('')
    const [workTel, setWorkTel] = useState('')
    const [address, setAddress] = useState('')



    useEffect(() => {

        if (data) {
            data.map(parent => (



                setTitle(parent.title),
                setFname(parent.fname),
                setLname(parent.lname),
                setNation(parent.nationality),
                setIdPass(parent.idpass),
                setEmail(parent.email),
                setSaMobile(parent.saMobile),
                setIntMobile(parent.intMobile),
                setGender(parent.gender),
                setDob(parent.dob),
                setRaceId(parent.raceid),
                setWorkTel(parent.workTel),
                setHomeTel(parent.homeTel),
                setAddress(parent.address),
                setLanguageId(parent.languageid)


            ))


        }
    }, [data])

    const handleSubmit = (e) => {

        e.preventDefault()


        updateData({
            title,
            fname,
            lname,
            nationality: nation,
            idpass,
            email,
            saMobile,
            intMobile,
            updatedBy: user.email,
            workTel,
            homeTel,
            dob,
            gender,
            race: raceId,
            address,
            language: languageId
        })

    }

    useEffect(() => {
        if (saMobile && nation === "South African") {

            setIntMobile(27 + saMobile.substring(1))


        }

    }, [saMobile])

    useEffect(() => {


        if (updated) {
            setSuccess(1)

        }


    }, [updated])

    return (
        <>
            <Container maxWidth="md">






                <Box mb={5} />

                {!success && (
                    <form autoComplete="off" onSubmit={handleSubmit}>
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} sx={{ border: 'solid', borderWidth: '3px', borderColor: (success ? "success.main" : "secondary.main"), borderRadius: 2 }}>



                            <Grid item xs={12} >
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >

                                    <Typography variant="h6" component="h3" color="secondary">
                                        Update Personal Information
                                    </Typography>


                                </Box>

                            </Grid>




                            {isPending && (

                                <Grid item xs={12} >
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"

                                    >
                                        <CircularProgress />
                                        <Typography variant="h6" component="h3" color="secondary">
                                            Updating information....
                                        </Typography>

                                    </Box>
                                </Grid>
                            )}
                            {error && !errorMessage && (

                                <Grid item xs={12} >
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"

                                    >

                                        <Typography variant="h6" component="h3" className='error' fullWidth sx={{ textAlign: 'center' }}>
                                            Unable to update information. You may be offline.
                                        </Typography>

                                    </Box>
                                </Grid>
                            )}

                            {errorMessage && (

                                <Grid item xs={12} >
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"

                                    >

                                        <Typography variant="h6" component="h3" className='error' fullWidth sx={{ textAlign: 'center' }}>
                                            {errorMessage}
                                        </Typography>

                                    </Box>
                                </Grid>
                            )}


                            {!isPending && (

                                <>






                                    <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        >


                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    label="DOB (yyyy/MM/dd)"
                                                    inputFormat="yyyy/MM/dd"
                                                    value={dob}
                                                    onChange={(value) => setDob(value)}
                                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                                />

                                            </LocalizationProvider>


                                        </Box>


                                    </Grid>
                                    <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        >
                                            <SelectTitle title={title} setTitle={setTitle} />
                                        </Box>


                                    </Grid>


                                    <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        >
                                            <TextField

                                                id="fname"
                                                label="First Names"
                                                value={fname}
                                                fullWidth
                                                required
                                                onChange={(e) => setFname(e.target.value)}
                                                autoComplete='nope'
                                                inputProps={{ maxLength: 100, minLength: 3, style: { textTransform: "capitalize" } }}
                                            />
                                        </Box>


                                    </Grid>



                                    <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        >
                                            <TextField

                                                id="lname"
                                                label="Last Name"
                                                value={lname}
                                                fullWidth
                                                required
                                                onChange={(e) => setLname(e.target.value)}
                                                autoComplete='nope'
                                                inputProps={{ maxLength: 100, minLength: 3, style: { textTransform: "capitalize" } }}
                                            />


                                        </Box>


                                    </Grid>


                                    <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        >
                                            <TextField

                                                id="workTel"
                                                label="Work Tel"
                                                value={workTel}
                                                fullWidth
                                                type="tel"
                                                inputProps={{ maxLength: 15, minLength: 10 }}
                                                onChange={(e) => setWorkTel(e.target.value)}
                                                required
                                                autoComplete='nope'
                                            />
                                        </Box>


                                    </Grid>


                                    <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        >
                                            <TextField

                                                id="homeTel"
                                                label="Home Tel"
                                                value={homeTel}
                                                fullWidth
                                                type="tel"
                                                inputProps={{ maxLength: 15, minLength: 10 }}
                                                onChange={(e) => setHomeTel(e.target.value)}

                                                autoComplete='nope'
                                            />
                                        </Box>


                                    </Grid>

                                    <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        >

                                            <TextField

                                                id="saMobile"
                                                label="Parent SA Mobile/Comms"
                                                onChange={(e) => setSaMobile(e.target.value)}
                                                value={saMobile}
                                                fullWidth
                                                type="tel"
                                                inputProps={{ maxLength: 10, minLength: 10, }}
                                                autoComplete='nope'
                                            />



                                        </Box>


                                    </Grid>

                                    <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        >


                                            <TextField

                                                id="intMobile"
                                                label="Parent Mobile Int(No Comms)"
                                                onChange={(e) => setIntMobile(e.target.value)}
                                                value={intMobile}
                                                fullWidth
                                                type="tel"
                                                inputProps={{ maxLength: 15, minLength: 10, }}
                                                autoComplete='nope'
                                            />




                                        </Box>


                                    </Grid>





                                    <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        >
                                            <EnterEmail email={email} setEmail={setEmail} />
                                        </Box>



                                    </Grid>
                                    <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        >




                                            <SelectSALang languageId={languageId} setLanguageId={setLanguageId} label="Home Language" />
                                        </Box>
                                    </Grid>


                                    <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        >
                                            <SelectRace setRaceId={setRaceId} raceId={raceId} />

                                        </Box>


                                    </Grid>
                                    <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        >
                                            <FormControl fullWidth>
                                                <InputLabel id="genderL">Gender*</InputLabel>

                                                <Select

                                                    labelId="genderL"
                                                    id="Gender"
                                                    value={gender}
                                                    onChange={(e) => setGender(e.target.value)}
                                                    input={<OutlinedInput label="genderL" />}
                                                    required

                                                >
                                                    <MenuItem value="M">Male</MenuItem>
                                                    <MenuItem value="F">Female</MenuItem>

                                                </Select>
                                            </FormControl>
                                        </Box>


                                    </Grid>
                                    <Grid item xs={12}>

                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        >
                                            <TextField

                                                id="postal"
                                                label="Postal Address"
                                                value={address}
                                                fullWidth
                                                required
                                                onChange={(e) => setAddress(e.target.value)}
                                                autoComplete='nope'
                                                inputProps={{ maxLength: 200, minLength: 3, style: { textTransform: "capitalize" } }}
                                            />
                                        </Box>


                                    </Grid>
                                </>
                            )}
                            <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >

                                    {!isPending && (
                                        <Button fullWidth color={"primary"} variant="contained" onClick={(e) => setEdit(0)}>Cancel <CancelIcon /></Button>


                                    )}



                                </Box>


                            </Grid>


                            <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >


                                    {!isPending && (

                                        <Button fullWidth color="secondary" type="submit" variant="contained">Update <SendIcon /></Button>

                                    )}




                                </Box>


                            </Grid>

                            <Box mb={12} />
                        </Grid>
                        <Box mb={4} />
                    </form>
                )
                }


                {!isPending && !error && !errorMessage && success === 1 && (
                    <>
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} sx={{ border: 'solid', borderWidth: '3px', borderColor: "success.main", borderRadius: 2 }}>


                            <Grid item xs={12}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >

                                    <Typography variant="h6" component="h3" padding={1} borderRadius={1} color="success.main">
                                        Personal information updated.
                                    </Typography>


                                </Box>

                            </Grid>





                            <Grid item xs={12} >
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >

                                    <Button color="success" fullWidth type="button" variant="contained" onClick={handleComplete}>Close<DoneOutlineIcon /></Button>
                                </Box>
                                <Box mb={5} />
                            </Grid>


                        </Grid>
                        <Box mb={4} />
                    </>
                )
                }

                <Box mb={2} />

            </Container >
        </>
    )
}

export default EditProfile