import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../context/AuthContext'
import { useFetch } from '../../hooks/useFetch'
import { useEffect, useState } from 'react'
import { Container, Box, Grid, TextField, Typography, Button, Divider, Card, Paper, CardActions, CardContent } from '@mui/material/'
import DashboardIcon from '@mui/icons-material/Dashboard';
import DataGridView from '../../components/DataGridView';
import SelectedStudent from '../../components/SelectedStudent'
import SelectedCampus from '../../components/SelectedCampus'
import format from 'date-fns/format'
import CloseIcon from '@mui/icons-material/Close';
import { set } from 'date-fns'
import CircularProgress from '@mui/material/CircularProgress';
function ViewAbsence({ studentId, setViewLog }) {

    const [tableData, setTableData] = useState([])
    const [campusId, setCampusId] = useState('')
    const { postData, error, errorMessage, isPending, data: resultsData } = useFetch('https://parentapi.bi-oss.co.za/students/absencelog', 'POST')

    useEffect(() => {
        if (studentId) {
            postData({
                studentId
            })
        }
    }, [studentId])


    const navigate = useNavigate()

    const classes = {};
    const [viewAll, setViewAll] = useState(0)
    document.title = 'BIC Parent Portal: Absence';


    useEffect((tableData) => {
        if (resultsData) {
            setTableData(resultsData)
            resultsData.map(data => {
                setCampusId(data.campusId)
            })
        }


    }, [resultsData])

    const handleOnCellClick = (e) => {
        // window.open(e.row.fileURL, '_blank')
    }

    const openLink = (url) => {
        window.open(url, '_blank')
    }
    const columns = [

        { field: 'missedDate', headerName: 'Date:', width: 120, valueFormatter: params => { return format(new Date(params.value), 'dd/MM/yy') } },


        {
            field: "action1",
            headerName: "Period:",
            sortable: false,
            width: 150,

            renderCell: (params) => {




                return <TextField



                    InputProps={{
                        readOnly: true,
                    }}
                    value={params.row.startTime + ' - ' + params.row.endTime}
                    fullWidth
                    required
                />;



            }
        },

        { field: 'courseName', headerName: 'Course:', width: 250, editable: true },



        { field: 'reason', headerName: 'Reason:', width: 250, editable: true },

    ];
    const columns2 = [

        { field: 'missedDate', headerName: 'Date:', width: 120, valueFormatter: params => { return format(new Date(params.value), 'dd/MM/yy') } },

        { field: 'duration', headerName: 'Duration:', width: 150, editable: true },




        { field: 'courseName', headerName: 'Stage/Level:', width: 250, editable: true },



        { field: 'reason', headerName: 'Reason:', width: 250, editable: true },

    ];
    return (
        <Container maxWidth="md" >
            <>
                <Box mb={5} />
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} sx={{ border: 'solid', borderWidth: '3px', borderColor: "primary.main", borderRadius: 2 }}>
                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"

                        >

                            <Typography variant="h6" component="h6" color="primary">
                                Students Absence Log
                            </Typography>


                        </Box>
                    </Grid>

                    {!errorMessage && !isPending && campusId && studentId && (

                        <>



                            <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >

                                    <SelectedCampus campusId={campusId} />
                                </Box>


                            </Grid>
                            <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <SelectedStudent studentId={studentId} />

                                </Box>


                            </Grid>
                        </>
                    )}
                    {isPending && !error && (
                        <>
                            <Grid item xs={12}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >
                                    <Typography variant="h6" component="h3" color="primary">
                                        Fetching student data ...
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} >
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >

                                    <CircularProgress />
                                    <Typography variant="h6" component="h3" color="secondary">

                                    </Typography>

                                </Box>
                            </Grid>
                        </>
                    )}

                    {error && !errorMessage && (
                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"

                            >
                                <Typography variant="h6" component="h3" color="error">
                                    Unable to fetch absence log. You may be offline. Please try again.


                                </Typography>
                            </Box>
                        </Grid>
                    )}
                    {errorMessage && (
                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"

                            >
                                <Typography variant="body2" component="p" color="primary">
                                    {errorMessage}


                                </Typography>
                            </Box>
                        </Grid>
                    )}

                    {resultsData && resultsData.length === 0 && (
                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"

                            >
                                <Typography variant="body2" component="p" color="primary">
                                    <b>The student has not been marked as absent for the current academic year.</b> </Typography>
                            </Box>
                        </Grid>

                    )}
                    {resultsData && resultsData.length > 0 && campusId !== 4 && (
                        <Grid item xs={12} minWidth={0} fullWidth>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"

                            >
                                <DataGridView
                                    fullWidth
                                    tableData={tableData}
                                    error={error} isPending={isPending} getFormattedDate={null} columns={columns} handleOnCellClick={handleOnCellClick}
                                    getRowId={(row) => row.id}
                                    pageSize={10}

                                />
                            </Box>
                        </Grid>

                    )}
                    {resultsData && resultsData.length > 0 && campusId === 4 && (
                        <Grid item xs={12} minWidth={0} fullWidth>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"

                            >
                                <DataGridView
                                    fullWidth
                                    tableData={tableData}
                                    error={error} isPending={isPending} getFormattedDate={null} columns={columns2} handleOnCellClick={handleOnCellClick}
                                    getRowId={(row) => row.id}
                                    pageSize={10}

                                />
                            </Box>
                        </Grid>

                    )}
                    <Grid item xs={12}>

                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{

                                maxWidth: '95%',
                                marginLeft: 'auto',
                                marginRight: 'auto'
                            }}
                        >
                            <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <Button fullWidth variant="contained" onClick={(e) => setViewLog(0)}>Close <CloseIcon /></Button>


                                </Box>

                                <Box mb={3} />
                            </Grid>

                        </Box>

                        <Box mb={3} />
                    </Grid>
                </Grid>
                <Box mb={5} />
            </>
        </Container >
    )
}

export default ViewAbsence