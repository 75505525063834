import React from 'react'
import { Container, Grid, TextField, Button, Typography, createTheme, Box, Link } from '@mui/material'
import { useState, useEffect } from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import validator from 'validator'
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import SendIcon from '@mui/icons-material/Send'
import { auth } from '../../firebase/config'
import { updatePassword } from 'firebase/auth'
import { useAuthContext } from '../../hooks/useAuthContext'
import CancelIcon from '@mui/icons-material/Cancel';
import { useFetch } from '../../hooks/useFetch'
import { Buffer } from 'buffer';
import { useLogout } from '../../hooks/useLogout'
import LogoutIcon from '@mui/icons-material/Logout';

function UpdateUserPassword({ setChange }) {

    const { user } = useAuthContext()
    const [password, setPassword] = useState('')
    const [errorPass, setErrorPass] = useState('')
    const [successPass, setSuccessPass] = useState('')
    const [success, setSuccess] = useState(0)
    const { postData, errorMessage, error, isPending, data } = useFetch('https://parentapi.bi-oss.co.za/profile/updatepassword', 'POST')
    const { logout } = useLogout()

    const [logoutMessage, setLogoutMessage] = useState('')

    const validate = (value) => {

        if (validator.isStrongPassword(value, {
            minLength: 8, minLowercase: 1,
            minUppercase: 1, minNumbers: 1, minSymbols: 1
        })) {
            setErrorPass('')
            setSuccessPass('Password strength: Secure.')
            setPassword(value)

        } else {
            setSuccessPass('')
            setErrorPass('Password strength: Weak. Please enter an alphanumeric password minimum of 8 characters. ')

        }
    }


    const handleSubmit = (e) => {

        e.preventDefault()

        postData({
            uid: user.uid,
            password: Buffer.from(password).toString('base64')
        })
    }


    useEffect(() => {
        if (data) {
            setSuccess(1)
            setTimeout(() => { setLogoutMessage('Auto logout in 5s.') }, 5000)
            setTimeout(() => { setLogoutMessage('Auto logout in 4s.') }, 6000)
            setTimeout(() => { setLogoutMessage('Auto logout in 3s.') }, 7000)
            setTimeout(() => { setLogoutMessage('Auto logout in 2s.') }, 8000)
            setTimeout(() => { setLogoutMessage('Auto logout in 1s.') }, 9000)
            setTimeout(() => { logout() }, 10000)
        }
    }, [data])

    return (
        < Container maxWidth="md"  >

            <>
                <Box mb={5} />

                {success === 1 && (
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} sx={{ border: 'solid', borderWidth: '3px', borderColor: 'success.main', borderRadius: 2 }}>






                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"

                            >
                                <Typography variant="h3" component="h3" color="success.main">
                                    Password Update Successful
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"

                            >
                                <Typography variant="h6" component="h3" color="success.main">
                                    You will need to login again in order to gain access to your profile.
                                </Typography>
                            </Box>
                        </Grid>
                        {logoutMessage && (
                            <Grid item xs={12}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >
                                    <Typography variant="h6" component="h3" color="success.main">
                                        {logoutMessage}
                                    </Typography>
                                </Box>
                            </Grid>

                        )}
                        <Grid item xs={12} >
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >


                                <Button color="success" fullWidth type="button" variant="contained" onClick={() => logout()}>Logout <LogoutIcon /></Button>
                            </Box>
                            <Box mb={5} />
                        </Grid>

                    </Grid>
                )}

                {!success && (
                    <form onSubmit={handleSubmit} >
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} sx={{ border: 'solid', borderWidth: '3px', borderColor: 'secondary.main', borderRadius: 2 }}>






                            <Grid item xs={12}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >

                                    <Typography variant="h3" component="h3" color="secondary.main" sx={{ textAlign: 'center' }}>
                                        Update Password
                                    </Typography>

                                </Box>


                            </Grid>

                            {isPending && (

                                <Grid item xs={12} >
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"

                                    >

                                        <CircularProgress />
                                        <Typography variant="h6" component="h3" color="secondary">
                                            Updating password....
                                        </Typography>

                                    </Box>
                                </Grid>
                            )}


                            {!isPending && (

                                <>



                                    <Grid item xs={12}>

                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        >
                                            <TextField

                                                id="password"
                                                label="Enter New Password (Alphanumeric & Minimum 8 Charaters)"
                                                onChange={(e) => validate(e.target.value)}

                                                fullWidth
                                                required

                                                type="password"
                                                autoComplete='nope'
                                                inputProps={{ maxLength: 100, minLength: 8, }}
                                            />

                                        </Box>


                                    </Grid>

                                    {errorPass && (

                                        <Grid item xs={12}>

                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{

                                                    maxWidth: '95%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            >

                                                <TextField

                                                    id="Notification"
                                                    InputProps={{
                                                        readOnly: true,

                                                    }}
                                                    value={errorPass}
                                                    fullWidth
                                                    required
                                                    sx={{ borderRadius: 1, backgroundColor: "error.main", input: { color: 'white', textAlign: 'center' } }}
                                                />


                                            </Box>


                                        </Grid>
                                    )}



                                    {successPass && (

                                        <Grid item xs={12}>
                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{

                                                    maxWidth: '95%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto'
                                                }}
                                            >

                                                <TextField

                                                    id="Notification"
                                                    InputProps={{
                                                        readOnly: true,

                                                    }}
                                                    value={successPass}
                                                    fullWidth
                                                    required
                                                    sx={{ borderRadius: 1, backgroundColor: "success.main", input: { color: 'white', textAlign: 'center' } }}
                                                />

                                            </Box>

                                        </Grid>
                                    )}
                                    <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        >

                                            {!isPending && (

                                                <Button fullWidth variant="contained" onClick={(e) => setChange(0)}>Cancel <CancelIcon /></Button>
                                            )}

                                        </Box>

                                    </Grid>

                                    <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        >
                                            {!isPending && !errorPass && successPass && (

                                                <Button fullWidth color="secondary" type="submit" variant="contained">Update <SendIcon /></Button>

                                            )}




                                            {(errorPass || !successPass) && !isPending && (

                                                <Button fullWidth color="secondary" disabled variant="contained">Update <SendIcon /></Button>

                                            )}



                                        </Box>

                                        <Box mb={5} />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </form>
                )}
            </>


        </Container>
    )
}

export default UpdateUserPassword