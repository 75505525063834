import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, Box, Paper } from '@mui/material';
import { useLogout } from '../hooks/useLogout'
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom'

export default function LogoutCard() {

    const navigate = useNavigate()
    const { logout } = useLogout()
    return (

        <Card sx={{
            width: 300,
            color: 'white',
            bgcolor: 'secondary.main',
            maxWidth: 250,
            alignItems: 'center',
            height: 80,



        }}>

            <CardActionArea onClick={() => logout()} >


                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        <LogoutIcon />  Logout
                    </Typography>
                </CardContent>

            </CardActionArea>
        </Card>

    );
}