import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth, setPersistence, browserSessionPersistence, browserLocalPersistence } from 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyDqvKqzPRgLCj-IZ9d2Wp1n8MJp4QeuJsU",
    authDomain: "bicparentportal.firebaseapp.com",
    projectId: "bicparentportal",
    storageBucket: "bicparentportal.appspot.com",
    messagingSenderId: "710781394199",
    appId: "1:710781394199:web:fc8b8421f9081ca7b02b52"
};

const app = initializeApp(firebaseConfig)
//init firestore
//const projectFirestore = getFirestore()
const db = getFirestore()

//init fb auth

const auth = getAuth()
//setPersistence(auth, browserSessionPersistence)
setPersistence(auth, browserLocalPersistence)
const timestamp = db.Timestamp
export { db, auth, timestamp, app }
