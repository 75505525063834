import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../hooks/useAuthContext'
import { useFetch } from '../../hooks/useFetch'
import { useEffect, useState } from 'react'
import { Container, Box, Grid, TextField, Typography, Button, Divider, Card, Paper, CardActions, CardContent } from '@mui/material/'
import format from 'date-fns/format';
import EditIcon from '@mui/icons-material/Edit';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DataGridView from '../../components/DataGridView';
import PageviewIcon from '@mui/icons-material/Pageview';
import PreviewIcon from '@mui/icons-material/Preview';
import CircularProgress from '@mui/material/CircularProgress';

function MTS() {

  const { user, accessLevel, parentID, token } = useAuthContext()
  const [edit, setEdit] = useState(0)
  const [change, setChange] = useState(0)
  const [tableData, setTableData] = useState([])

  const { error, isPending, data: mtsData } = useFetch('https://parentapi.bi-oss.co.za/students/mtstimetable/' + parentID)
  const navigate = useNavigate()

  const classes = {};

  document.title = 'BIC Parent Portal: Midterm Seminars';


  useEffect((tableData) => {
    if (mtsData) {
      setTableData(mtsData)

    }


  }, [mtsData])

  const handleOnCellClick = (e) => {
    // window.open(e.row.fileURL, '_blank')
  }

  const openLink = (url) => {
    window.open(url, '_blank')
  }
  const columns = [

    { field: 'seminarDate', headerName: 'Date:', width: 120, valueFormatter: params => { return format(new Date(params.value), 'dd/MM/yy') } },
    {
      field: "action1",
      headerName: "Student:",
      sortable: false,
      width: 250,

      renderCell: (params) => {




        return <TextField



          InputProps={{
            readOnly: true,
          }}
          value={params.row.fname + ' ' + params.row.lname}
          fullWidth
          required
        />;



      }
    },



    { field: 'courseName', headerName: 'Course:', width: 250, editable: true },
    {
      field: "action2",
      headerName: "Time:",
      sortable: false,
      width: 150,

      renderCell: (params) => {




        return <TextField



          InputProps={{
            readOnly: true,
          }}
          value={params.row.startTime + ' - ' + params.row.endTime}
          fullWidth
          required
        />;



      }
    },
  ];

  return (
    <Container maxWidth="md" >

      <Box mb={5} />
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} sx={{ border: 'solid', borderWidth: '3px', borderColor: "primary.main", borderRadius: 2 }}>


        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"

          >
            <Typography variant="h3" component="h3" color="primary.main" sx={{ textAlign: 'center' }}>
              Midterm Seminar Timetable
            </Typography>

          </Box>
        </Grid>


        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"

          >
            <Typography variant="body2" component="p" color="primary.main" sx={{ margin: "5px", textAlign: 'center' }}>
              British International Distance College students are invited to attend our course seminars held throughout the academic year. These seminars discuss course material and Cambridge External Assessment requirements. Seminars are not mandatory but are recommended to attend. Only students can access the seminar via the student portal. Parents and Guardians can view upcoming seminar dates in the below timetable.

            </Typography>
          </Box>
        </Grid>


        {isPending && !error && (
          <>
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"

              >
                <Typography variant="h6" component="h3" color="primary">
                  Fetching seminar data ...
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"

              >
                <CircularProgress />
              </Box>
            </Grid></>
        )}

        {error && (
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"

            >
              <Typography variant="h6" component="h3" color="error">
                Unable to fetch mts info. You may be offline. Please try again.


              </Typography>
            </Box>
          </Grid>
        )}

        {!isPending && !error && tableData && tableData.length > 0 && (
          <Grid item xs={12} minWidth={0} fullWidth >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"

            >
              <DataGridView

                tableData={tableData}
                error={error} isPending={isPending} getFormattedDate={null} columns={columns} pageSize={15} handleOnCellClick={handleOnCellClick}
                getRowId={(row) => row.id}
              />
            </Box>
          </Grid>

        )}

        {!isPending && !error && tableData && tableData.length === 0 && (
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"

            >
              <Typography variant="body2" component="p" color="primary">
                <b>There are currently no scheduled Midterm Seminars.   </b>


              </Typography>
            </Box>
          </Grid>

        )}

        <Grid item xs={12}>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{

              maxWidth: '95%',
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
          >
            <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{

                  maxWidth: '95%',
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}
              >
                <Button fullWidth variant="contained" onClick={(e) => navigate(-1)}>Dashboard <DashboardIcon /></Button>


              </Box>

              <Box mb={3} />
            </Grid>

          </Box>

          <Box mb={3} />
        </Grid>
      </Grid>


      <Box mb={5} />
    </Container>
  )
}

export default MTS