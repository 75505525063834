import { useState } from "react"
import { auth } from '../firebase/config'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { useAuthContext } from '../hooks/useAuthContext'


export const useLogin = () => {

    const [error, setError] = useState(null)
    const { dispatch } = useAuthContext()
    const [isPending, setIsPending] = useState(false)

    const login = (email, password) => {

        setError(null)
        setIsPending(true)

        signInWithEmailAndPassword(auth, email, password)

            .then((res) => {

                dispatch({ type: 'LOGIN', payload: res.user })
                setIsPending(false)

            })

            .catch((err) => {

                setError(err.code)
                setIsPending(false)
            })


    }
    return { error, login, isPending }
}