import { useState, useEffect } from "react"
import { onAuthStateChanged } from 'firebase/auth'
import { auth, db } from '../firebase/config'

export const useFetchNoUser = (url, method = "GET") => {
    const [data, setData] = useState(null)

    const [isPending, setIsPending] = useState(false)
    const [error, setError] = useState(null)
    const [errorMessage, setErrorMessage] = useState('')
    const [options, setOptions] = useState(null)





    const getData = async (postData) => {

        setOptions({
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000"
            },
            body: JSON.stringify(postData)
        })
    }




    const postData = async (postData) => {

        setOptions({
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000"
            },
            body: JSON.stringify(postData)
        })
    }

    const deleteData = async (deleteData) => {

        setOptions({
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000"
            },
            body: JSON.stringify(deleteData)
        })
    }

    const updateData = async (updateData) => {

        setOptions({
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000"
            },
            body: JSON.stringify(updateData)
        })
    }

    useEffect(() => {
        const controller = new AbortController()

        const fetchData = async (fetchOptions) => {
            setIsPending(true)
            setError(null)
            setErrorMessage('')





            try {



                const res = await fetch(url, {
                    headers: { "Access-Control-Allow-Origin": "http://localhost:3000" }, ...fetchOptions, signal: controller.signal


                })


                if (!res.ok) {
                    const data = await res.json()
                    setErrorMessage(data.error.message)
                    throw new Error(data)

                }

                const data = await res.json()


                setIsPending(false)
                setData(data)

                setError(null)


            } catch (err) {

                if (err.name === "AbortError") {
                    console.log("the fetch was aborted")
                } else {
                    setIsPending(false)
                    setError('Could not fetch the data')

                }
            }
        }

        // invoke the function

        if (method === "GET") {

            fetchData()
        }
        if (method === "GET" && options) {

            fetchData(options)
        }
        if (method === "POST" && options) {

            fetchData(options)
        }
        if (method === "DELETE" && options) {

            fetchData(options)
        }

        if (method === "PUT" && options) {

            fetchData(options)
        }



        return () => {
            controller.abort()
        }

    }, [url, method, options])

    return { data, isPending, error, postData, updateData, deleteData, getData, errorMessage }
}