import React, { useState, useEffect } from 'react'
import { Container, Box, Grid, TextField, Typography, Button, Divider, Card, Paper, CardActions, CardContent } from '@mui/material/'

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';


import CancelIcon from '@mui/icons-material/Cancel';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';

import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import DescriptionIcon from '@mui/icons-material/Description';
import { useAuthContext } from '../../hooks/useAuthContext'
import { useFetch } from '../../hooks/useFetch'
function ViewDCTerms({ sessionId, academicYear, studentId, campusId, setViewQuote, setViewTerms, setViewSelect }) {

    const { user, parentID } = useAuthContext()
    const [conf1, setConf1] = useState(false)
    const [conf2, setConf2] = useState(false)

    const { postData, error, isPending, data: termsResponse } = useFetch('https://parentapi.bi-oss.co.za/studdcexam/termsaccept', 'POST')


    const handleChange = (e) => {
        e.preventDefault()

        if (conf1 === 1) {
            setConf1(true)
        }
        if (conf1 === 0 || !conf1) {
            setConf1(true)
        }


        if (conf2 === 1) {
            setConf2(true)
        }
        if (conf2 === 0 || !conf2) {
            setConf2(true)
        }
    }




    const handleSubmit = (e) => {
        e.preventDefault()
        postData({

            campusId,
            studentId,
            parentId: parentID,
            createdBy: user.email,
            sessionId,
            academicYear
        })
        //add accept log here
        // setViewTerms(0)
        // setViewQuote(1)

    }
    const handleCancel = (e) => {
        e.preventDefault()
        setViewSelect(1)
        setViewTerms(0)
    }

    useEffect(() => {
        if (termsResponse && termsResponse.insertId > 0) {
            setViewTerms(0)
            setViewQuote(1)
        }
    }, [termsResponse])

    return (
        <Container maxWidth="lg" >

            <Box mb={5} />


            <form onSubmit={handleSubmit}>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} sx={{ border: 'solid', borderWidth: '3px', borderColor: "primary.main", borderRadius: 2 }}>


                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"

                        >
                            <Typography variant="h3" component="h3" color="primary.main" sx={{ textAlign: 'center' }}>
                                Terms and Conditions
                            </Typography>

                        </Box>
                    </Grid>
                    <Grid item xs={12}>

                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{

                                maxWidth: '95%',
                                marginLeft: 'auto',
                                marginRight: 'auto'
                            }}
                        >


                            <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>
                                Before you can generate an entry quote, all terms,conditions and the code of conduct must be accepted.
                            </Typography>





                        </Box>


                    </Grid>

                    <Grid item xs={12}>

                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{

                                maxWidth: '95%',
                                marginLeft: 'auto',
                                marginRight: 'auto'
                            }}
                        >


                            <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>
                                To view our Terms and Conditions, visit <a href="https://parent.bi-oss.co.za/tnc" target='blank'>https://parent.bi-oss.co.za/tnc</a>.

                            </Typography>





                        </Box>


                    </Grid>

                    <Grid item xs={12}>

                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{

                                maxWidth: '95%',
                                marginLeft: 'auto',
                                marginRight: 'auto'
                            }}
                        >


                            <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>

                                By clicking agree, I state that I have read, understood and agree to all the terms and conditions in the above link.

                            </Typography>





                        </Box>


                    </Grid>
                    <Grid item xs={12}>

                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{

                                maxWidth: '95%',
                                marginLeft: 'auto',
                                marginRight: 'auto'
                            }}
                        >

                            <FormGroup>

                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography>Decline</Typography>

                                    {conf1 === false && (
                                        <Switch checked={false} required inputProps={{ 'aria-label': 'ant design' }} onChange={(e) => setConf1(true)} />

                                    )}
                                    {conf1 === true && (
                                        <Switch color="success" checked={true} required inputProps={{ 'aria-label': 'ant design' }} onChange={(e) => setConf1(false)} />

                                    )}
                                    <Typography>I Agree. </Typography>
                                </Stack>
                            </FormGroup>


                        </Box>


                    </Grid>

                    <Grid item xs={12}>

                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{

                                maxWidth: '95%',
                                marginLeft: 'auto',
                                marginRight: 'auto'
                            }}
                        >


                            <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>
                                To view the Code of Conduct visit <a href="https://parents.bi-oss.co.za/code" target='blank'>https://parents.bi-oss.co.za/examrules</a>.

                            </Typography>





                        </Box>


                    </Grid>

                    <Grid item xs={12}>

                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{

                                maxWidth: '95%',
                                marginLeft: 'auto',
                                marginRight: 'auto'
                            }}
                        >


                            <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>


                                By clicking agree, I state that I have read, understood and agree to the code of conduct in the above link.
                            </Typography>





                        </Box>


                    </Grid>

                    <Grid item xs={12}>

                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{

                                maxWidth: '95%',
                                marginLeft: 'auto',
                                marginRight: 'auto'
                            }}
                        >

                            <FormGroup>

                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography>Decline</Typography>

                                    {conf2 === false && (
                                        <Switch checked={false} required inputProps={{ 'aria-label': 'ant design' }} onChange={(e) => setConf2(true)} />

                                    )}
                                    {conf2 === true && (
                                        <Switch color="success" checked={true} required inputProps={{ 'aria-label': 'ant design' }} onChange={(e) => setConf2(false)} />

                                    )}
                                    <Typography>I Agree. </Typography>
                                </Stack>
                            </FormGroup>


                        </Box>


                    </Grid>
                    <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{

                                maxWidth: '95%',
                                marginLeft: 'auto',
                                marginRight: 'auto'
                            }}
                        >


                            <Button fullWidth color={"primary"} variant="contained" onClick={handleCancel}>Cancel <CancelIcon /></Button>


                        </Box>

                    </Grid>
                    <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{

                                maxWidth: '95%',
                                marginLeft: 'auto',
                                marginRight: 'auto'
                            }}
                        >


                            <Button fullWidth color={"primary"} variant="contained" type="submit" >Agree and View Quote <DescriptionIcon /></Button>


                        </Box>
                        <Box mb={5} />
                    </Grid>
                </Grid>
            </form>
        </Container>
    )
}

export default ViewDCTerms