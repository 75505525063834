import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../hooks/useAuthContext'
import { useFetch } from '../../hooks/useFetch'
import { useEffect, useState } from 'react'
import { Container, Box, Grid, TextField, Typography, Button, Divider, Card, Paper, CardActions, CardContent } from '@mui/material/'

import DataGridView from '../DataGridView';
import CircularProgress from '@mui/material/CircularProgress';
function ViewDCEntryStatus({ studentId, sessionId, setProcessEntry }) {
    const [tableData, setTableData] = useState([])


    const { postData, error, isPending, data: authList } = useFetch('https://parentapi.bi-oss.co.za/studdcexam/entrystatus')

    const handleOnCellClick = (e) => {
        // window.open(e.row.fileURL, '_blank')
    }
    useEffect(() => {
        if (studentId && sessionId) {
            setProcessEntry(0)
            postData({
                studentId,
                sessionId,


            })
        }
    }, [studentId, sessionId])


    useEffect((tableData) => {
        if (authList) {

            setTableData(authList)
            authList.map(data => {
                if (data.paid === 0) {
                    setProcessEntry(1)
                }
            })




        }


    }, [authList])


    const columns = [

        { field: 'academicYear', headerName: 'Year:', width: 100, editable: true, cellClassName: 'super-app-theme--cell' },

        { field: 'session', headerName: 'Session:', width: 120, editable: true },
        { field: 'qualification', headerName: 'Qualification:', width: 120, editable: true },
        { field: 'paperCode', headerName: 'Code:', width: 120, editable: true },
        { field: 'description', headerName: 'Paper Description:', width: 200, editable: true },


        {
            field: "action2",
            headerName: "Entry Status:",
            width: 150,
            renderCell: (params) => {

                if (params.row.paid === 0) {

                    return <TextField

                        id="stundentConf"

                        InputProps={{
                            readOnly: true,
                        }}
                        value="Incomplete"
                        fullWidth

                        sx={{ borderRadius: 1, input: { color: 'error.main' } }}
                    />;

                }

                if (params.row.paid === 1) {
                    return <TextField

                        id="stundentConf"

                        InputProps={{
                            readOnly: true,
                        }}
                        value="Complete"
                        fullWidth

                        sx={{ borderRadius: 1, input: { color: 'success.main' } }}
                    />;
                }


            }
        },


    ];
    return (
        <>

            {isPending && (


                <>

                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography variant="h6" component="h3" color="primary.main">
                                Fetching authorisation list ...
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <CircularProgress />
                        </Box>
                    </Grid>
                </>
            )}


            {!isPending && !error && tableData && tableData.length > 0 && (




                <Grid item xs={12} minWidth={0}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"

                    >
                        <DataGridView

                            tableData={tableData}
                            error={error} isPending={isPending} getFormattedDate={null} columns={columns} pageSize={15} handleOnCellClick={handleOnCellClick}
                            getRowId={(row) => row.id}
                            getRowClassName={(params) =>
                                params.row.paid === 1 ? 'positive' : 'negative'
                            }

                        />
                    </Box>
                </Grid>


            )}

            {!isPending && !error && tableData && tableData.length === 0 && (




                <Grid item xs={12} minWidth={0}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"

                    >
                        <Typography variant="body2" component="p" color="primary">
                            <b>There are currently no authorisations for the selected student.</b>


                        </Typography>
                    </Box>
                </Grid>


            )}

        </>
    )
}

export default ViewDCEntryStatus