import React from 'react'
import { OutlinedInput, InputLabel, FormControl, Select, MenuItem, TextField } from '@mui/material/'
import { useFetch } from '../hooks/useFetch'


function SelectedCampus({ campusId, disabled }) {


    const { error, isPending, data } = useFetch(`https://parentapi.bi-oss.co.za/profile/selectedcampus/${campusId}`)
    return (
        <FormControl fullWidth>

            {data && data.map(data => (


                <TextField

                    id="campusId"
                    label="Campus or School"
                    InputProps={{
                        readOnly: true,
                    }}
                    value={data.reportName}
                    fullWidth
                    required
                />

            )



            )}

        </FormControl>
    )
}

export default SelectedCampus