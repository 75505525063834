import { useAuthContext } from '../../../hooks/useAuthContext'
import DashCard from '../../../components/DashCard'
import { Container, Box, Grid, Typography } from '@mui/material'
import DashboardIcon from '@mui/icons-material/Dashboard';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import { useEffect, useState } from 'react'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PaymentIcon from '@mui/icons-material/Payment';
import InfoIcon from '@mui/icons-material/Info';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';

export default function AccountsDash() {
    const { accountHolder, shareAccount } = useAuthContext()

    document.title = 'BIC Parent Portal: Accounts';


    return (
        <Container maxWidth="xl">

            <Box mb={3} />



            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }}>

                <Grid item xs={12} >
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"

                    >
                        <Typography variant="h3" component="h3" color="primary.main" sx={{ textAlign: 'center' }}>
                            Accounts Dashboard
                        </Typography>
                    </Box>
                </Grid>


                {accountHolder === 1 && (

                    <Grid item xs={4} sm={4} md={4} lg={4} xl={3}>

                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"

                        >
                            <DashCard link={"/accounts/access"} cardName={"Account Access"} cardIcon={<SettingsAccessibilityIcon />} />
                        </Box>

                    </Grid>
                )}

                {(accountHolder === 1 || shareAccount === 1) && (

                    <Grid item xs={4} sm={4} md={4} lg={4} xl={3}>

                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"

                        >
                            <DashCard link={"/accounts/status"} cardName={"Account Status"} cardIcon={<AccountBalanceIcon />} />
                        </Box>

                    </Grid>
                )}


                <Grid item xs={4} sm={4} md={4} lg={4} xl={3}>

                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"

                    >
                        <DashCard link={"/accounts/tuitionpayments"} cardName={"Tuition Payments"} cardIcon={<PaymentIcon />} />
                    </Box>

                </Grid>

                <Grid item xs={4} sm={4} md={4} lg={4} xl={3}>

                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"

                    >
                        <DashCard link={"/accounts/transactionlogs"} cardName={"Transaction Logs"} cardIcon={<InfoIcon />} />
                    </Box>

                </Grid>




                <Grid item xs={4} sm={4} md={4} lg={4} xl={3}>

                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"

                    >
                        <DashCard link={"/"} cardName={"Dashboard"} cardIcon={<DashboardIcon />} />
                    </Box>

                </Grid>
            </Grid>

            <Box mb={5} />



        </Container >

    )
}
