import React from 'react'
import { OutlinedInput, InputLabel, FormControl, Select, MenuItem, TextField } from '@mui/material/'
import { useFetch } from '../hooks/useFetch'

export default function SelectedStudent({ studentId, disabled }) {

    const { error, isPending, data } = useFetch(`https://parentapi.bi-oss.co.za/students/selectedstudent/${studentId}`)
    return (
        <FormControl fullWidth>

            {data && data.map(data => (


                <TextField

                    id="amount"
                    label="Student"
                    InputProps={{
                        readOnly: true,
                    }}
                    value={data.fname + " " + data.lname}
                    fullWidth
                    required
                />

            )



            )}

        </FormControl>
    )
}
