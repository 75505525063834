import { useState, useEffect } from "react"
import { useAuthContext } from './useAuthContext'
import { onAuthStateChanged } from 'firebase/auth'
import { auth, db } from '../firebase/config'

export const useFetch = (url, method = "GET") => {
  const [data, setData] = useState(null)

  const [isPending, setIsPending] = useState(false)
  const [error, setError] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [options, setOptions] = useState(null)

  const { user } = useAuthContext()

  const UpdateToken = async (e) => {
    var minutesAdded = 60000 * 10
    var expire = user.stsTokenManager.expirationTime - minutesAdded
    var seconds = new Date().getTime()


    if (expire < seconds) {

      await user.getIdToken(true)


    }

    if (user.stsTokenManager.isExpired) {

      await user.getIdToken(true)


    }

  }

  const getData = async (postData) => {
    await UpdateToken()
    setOptions({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${user.accessToken}`
      },
      body: JSON.stringify(postData)
    })
  }




  const postData = async (postData) => {
    await UpdateToken()
    setOptions({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${user.accessToken}`
      },
      body: JSON.stringify(postData)
    })
  }

  const deleteData = async (deleteData) => {
    await UpdateToken()
    setOptions({
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${user.accessToken}`
      },
      body: JSON.stringify(deleteData)
    })
  }

  const updateData = async (updateData) => {
    await UpdateToken()
    setOptions({
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${user.accessToken}`
      },
      body: JSON.stringify(updateData)
    })
  }

  useEffect(() => {
    const controller = new AbortController()

    const fetchData = async (fetchOptions) => {
      setIsPending(true)
      setError(null)
      setErrorMessage('')
      await UpdateToken()


      if (!options) {
        await UpdateToken()

      }



      try {



        const res = await fetch(url, {
          headers: { "Authorization": `Bearer ${user.accessToken}` }, ...fetchOptions, signal: controller.signal


        })


        if (!res.ok) {
          const data = await res.json()
          setErrorMessage(data.error.message)
          throw new Error(data)

        }

        const data = await res.json()


        setIsPending(false)
        setData(data)

        setError(null)


      } catch (err) {

        if (err.name === "AbortError") {
          console.log("the fetch was aborted")
        } else {
          setIsPending(false)
          setError('Could not fetch the data')

        }
      }
    }

    // invoke the function

    if (method === "GET") {

      fetchData()
    }
    if (method === "GET" && options) {

      fetchData(options)
    }
    if (method === "POST" && options) {

      fetchData(options)
    }
    if (method === "DELETE" && options) {

      fetchData(options)
    }

    if (method === "PUT" && options) {

      fetchData(options)
    }



    return () => {
      controller.abort()
    }

  }, [url, method, options])

  return { data, isPending, error, postData, updateData, deleteData, getData, errorMessage }
}