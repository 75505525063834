import React from 'react'
import { OutlinedInput, InputLabel, FormControl, Select, MenuItem } from '@mui/material/'
import { useFetch } from '../hooks/useFetch'

function SelectCambridgeExamSession({ sessionId, setSessionId, disabled, addAny, camOnly, nonCam }) {
    const { error, isPending, data } = useFetch('https://parentapi.bi-oss.co.za/shared/examsessions')
    return (
        <FormControl fullWidth>
            <InputLabel id="selectcampus">Select Session*</InputLabel>

            <Select


                labelId="year"
                id="year"
                disabled={disabled}
                onChange={(e) => setSessionId(e.target.value)}
                input={<OutlinedInput label="selectcampus" />}
                required
                value={sessionId}
            >


                {addAny === 1 && (
                    <MenuItem key={1} value={0}>All Examination Session</MenuItem>

                )}
                {!camOnly && !nonCam && data && data.map(data => (

                    <MenuItem key={data.id} value={data.id}>{data.session} - {data.type}</MenuItem>
                ))}



                {camOnly === 1 && data && (

                    data.map(function (data) {



                        if (data.id === 1 || data.id === 2) {
                            return <MenuItem key={data.id} value={data.id}>{data.session} - {data.type}</MenuItem>
                        }
                        else {
                            return
                        }


                    })


                )

                }

                {nonCam === 1 && data && (

                    data.map(function (data) {



                        if (data.id == 3 || data.id === 4) {
                            return <MenuItem key={data.id} value={data.id}>{data.session} - {data.type}</MenuItem>
                        }
                        else {
                            return
                        }


                    })


                )

                }

            </Select>
        </FormControl>
    )
}

export default SelectCambridgeExamSession