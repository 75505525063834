import React, { useEffect, useState } from 'react'
import { TextField } from '@mui/material/'


function EnterEmail({ email, setEmail, required, disabled }) {
    return (
        <TextField
            type="email"
            id="email"
            label="Email Address (Unique)"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            fullWidth
            inputProps={{ maxLength: 100, minLength: 8, }}
            autoComplete='nope'
            required={required}
            disabled={disabled}

        />
    )
}

export default EnterEmail