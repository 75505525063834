import React, { useState, useEffect } from 'react'
import { useFetch } from '../../hooks/useFetch'
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
    GridHeader,
    GridFooter,
    GridFooterContainer
} from '@mui/x-data-grid';
import format from 'date-fns/format'
import { Container, Box, Grid, TextField, Typography, Button, Divider, Card, Paper, CardActions, CardContent } from '@mui/material/'

function SummaryFooter({ studentId }) {

    const [totalAmount, setTotalAmount] = useState('')
    const [totalDesc, setTotalDesc] = useState('')
    const { getData, error: error2, isPending: isPending2, data: totalData } = useFetch(`https://parentapi.bi-oss.co.za/students/currenttotal/${studentId}`, 'GET')


    useEffect(() => {
        if (totalData) {
            totalData.map(data => (

                setTotalDesc(data.SumOfamount),
                setTotalAmount(data.SumOfamount2)
            ))
        }
    }, [totalData])
    return (
        <GridFooterContainer>
            <GridFooter sx={{
                border: 'none', // To delete double border.
            }} />

            {totalAmount > 0 && (
                <>
                    <Typography fullWidth variant="h6" component="h6" color="primary.main" sx={{ textAlign: 'center', marginRight: "50px" }}>Total Due: {totalDesc}</Typography>

                </>

            )}

            {totalAmount <= 0 && (
                <>
                    <Typography fullWidth variant="h6" component="h6" color="primary.main" sx={{ textAlign: 'center', marginRight: "50px" }}>Account Balance: {totalDesc}</Typography>

                </>

            )}

        </GridFooterContainer>
    )
}

export default SummaryFooter