import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../../hooks/useAuthContext'
import { useFetch } from '../../../hooks/useFetch'
import { useEffect, useState } from 'react'
import { Container, Box, Grid, TextField, Typography, Button, Divider, Card, Paper, CardActions, CardContent } from '@mui/material/'
import format from 'date-fns/format'
import DashboardIcon from '@mui/icons-material/Dashboard';
import DataGridView from '../../../components/DataGridView';
import PageviewIcon from '@mui/icons-material/Pageview';
import PreviewIcon from '@mui/icons-material/Preview';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SelectStudentAccountHolder from '../../../components/SelectStudentAccountHolder'
import useEnhancedEffect from '@mui/material/utils/useEnhancedEffect'
import ChangeAccountAccess from '../../../components/accounts/ChangeAccountAccess'
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
export default function AccountsAccess() {

    const classes = {};
    const { user, parentID, token } = useAuthContext()
    const [studentId, setStudentId] = useState('')
    const [change, setChange] = useState(0)
    const [status, setStatus] = useState(0)
    const [tableData, setTableData] = useState([])
    const [spouseId, setSpouseId] = useState('')
    const { getData, error, isPending, data: commsData } = useFetch('https://parentapi.bi-oss.co.za/profile/nonaccount/parentlist/' + studentId, 'GET')
    const navigate = useNavigate()

    useEffect(() => {
        if (studentId) {
            setTableData('')
            getData()
        }
    }, [studentId])



    useEffect((tableData) => {
        if (commsData) {
            setTableData(commsData)

        }


    }, [commsData])

    const handleOnCellClick = (e) => {
        // window.open(e.row.fileURL, '_blank')
    }

    const openLink = (url) => {
        window.open(url, '_blank')
    }
    const columns = [


        { field: 'lname', headerName: 'Last Name:', width: 200, editable: true },
        { field: 'fname', headerName: 'First Names:', width: 200, editable: true },


        {
            field: "",
            headerName: "Account Access",
            sortable: false,
            width: 200,
            renderCell: (params) => {

                if (params.row.shareAccount === 1) {
                    return <TextField

                        id="accStatus"
                        InputProps={{
                            readOnly: true,

                        }}
                        value="Access Granted"
                        fullWidth
                        required
                        sx={{ borderRadius: 1, input: { color: 'success.main' } }}
                    />;
                }
                if (params.row.shareAccount === 0) {
                    return <TextField

                        id="accStatus"
                        InputProps={{
                            readOnly: true,

                        }}
                        value="Access Denied"
                        fullWidth
                        required
                        sx={{ borderRadius: 1, input: { color: 'error.main' } }}
                    />;
                }

            }
        },

        {
            field: "action",
            headerName: "",
            sortable: false,
            width: 120,
            renderCell: (params) => {
                const onClick2 = (e) => {
                    e.stopPropagation() // don't select this row after clicking
                    setSpouseId(params.row.parentId)
                    setStatus(params.row.shareAccount)
                    setChange(1)
                }



                return <Button color="primary" onClick={onClick2} fullWidth variant="contained">Edit <EditIcon /></Button>;



            }
        },

    ];


    const handleComplete = (e) => {
        e.preventDefault()
        setSpouseId('')
        setChange(0)
        getData()
    }

    const handleChange = (spouseId, status) => {
        setSpouseId(spouseId)
        setStatus(status)
        setChange(1)
    }
    return (
        <>

            {change === 1 && (
                <>
                    <Box mb={5} />
                    <ChangeAccountAccess studentId={studentId} handleComplete={handleComplete} spouseId={spouseId} status={status} setStatus={setStatus} setChange={setChange} />
                </>
            )}

            {!change && (



                <Container maxWidth="md" >

                    <Box mb={5} />
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} sx={{ border: 'solid', borderWidth: '3px', borderColor: "primary.main", borderRadius: 2 }}>


                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"

                            >
                                <Typography variant="h3" component="h3" color="primary.main" sx={{ textAlign: 'center' }}>
                                    Accounts Access
                                </Typography>

                            </Box>
                        </Grid>


                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"

                            >
                                <Typography variant="body2" component="p" color="primary.main" sx={{ margin: "5px", textAlign: 'center' }}>
                                    <p>
                                        By default, only the primary account holder can view an accounts status or pull an account summary report. Non-accounts holders are unable to view an account summary; however, they are able to make additional online tuition payments.
                                    </p>

                                    <p>Should you wish to share a specified account’s information with another linked parent or guardian, you can do so by updating thier access level. Kindly note, this is done on a per account basis. </p>
                                </Typography>
                            </Box>
                        </Grid>


                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"

                            >
                                <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{

                                            maxWidth: '95%',
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}
                                    >
                                        <SelectStudentAccountHolder studentId={studentId} setStudentId={setStudentId} />

                                    </Box>


                                </Grid>
                            </Box>
                        </Grid>

                        {isPending && !error && (
                            <Grid item xs={12}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >
                                    <Typography variant="h6" component="h3" color="primary">
                                        Fetching parent list ...
                                    </Typography>
                                </Box>
                            </Grid>
                        )}

                        {error && studentId && (
                            <Grid item xs={12}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >
                                    <Typography variant="h6" component="h3" color="error">
                                        Unable to fetch parent and guardian list. You may be offline. Please try again.


                                    </Typography>
                                </Box>
                            </Grid>
                        )}

                        {!studentId && (
                            <Grid item xs={12}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >
                                    <Typography variant="h6" component="h3" color="primary">
                                        Please select an account.


                                    </Typography>
                                </Box>
                            </Grid>
                        )}
                        {!isPending && !error && tableData && tableData.length === 0 && (
                            <Grid item xs={12}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >
                                    <Typography class="error" variant="body2" component="p" color="primary">
                                        There are currently no additional parents or guardians linked to the selected account.


                                    </Typography>
                                </Box>
                            </Grid>

                        )}
                        {!isPending && !error && tableData && tableData.length > 0 && (
                            <Grid item xs={12} sx={{ display: { xs: "none", sm: "block" }, }}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >
                                    <DataGridView

                                        tableData={tableData}
                                        error={error} isPending={isPending} getFormattedDate={null} columns={columns} pageSize={15} handleOnCellClick={handleOnCellClick}
                                        getRowId={(row) => row.id}
                                    />
                                </Box>
                            </Grid>

                        )}


                        {!isPending && commsData && commsData.length > 0 && (
                            <Grid item xs={12} sx={{ display: { xs: "block", sm: "none" }, }}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >
                                    <Typography variant="h6" component="h3" color="primary">
                                        Parent and Guardian List


                                    </Typography>

                                </Box>
                                <Box mb={5} />
                            </Grid>

                        )}
                        {!isPending && commsData && commsData.length > 0 && commsData.map(data => (


                            <>
                                <Box mb={5} />
                                <Grid container bgcolor="primary.light" spacing={3} direction="row" justify="center" alignItems="stretch" sx={{ display: { xs: "block", sm: "none" }, }}>


                                    <Grid item xs={12} spacing={3} key={data.id}>
                                        <Grid>
                                            <Card className={classes.root} variant="outlined">
                                                <CardContent>


                                                    <Typography variant="h5" component="h3">
                                                        {data.fname} {data.lname}
                                                    </Typography>
                                                    {data.shareAccount === 0 && (
                                                        <Typography className={classes.pos} color="primary.main">
                                                            Account Access Level: <Typography color="error.main">Access Denied</Typography>

                                                        </Typography>

                                                    )}
                                                    {data.shareAccount === 1 && (
                                                        <Typography className={classes.pos} color="primary.main">
                                                            Account Access Level: <Typography color="success.main">Access Granted</Typography>

                                                        </Typography>

                                                    )}

                                                    <Box mb={3} />
                                                    <Grid item xs={6}>

                                                        <Button gutterBottom fullWidth color="primary" onClick={(e) => handleChange(data.parentId, data.shareAccount)} variant="contained">Edit <EditIcon /></Button>

                                                    </Grid>
                                                    <Box mb={1} />
                                                </CardContent>


                                            </Card>
                                        </Grid>
                                        <Box mb={3} />
                                    </Grid>

                                </Grid>
                                <Box mb={1} />





                            </>


                        ))}

                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{

                                            maxWidth: '95%',
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}
                                    >
                                        <Button fullWidth variant="contained" onClick={(e) => navigate('/accounts')}><ArrowBackIcon /> Back</Button>


                                    </Box>

                                    <Box mb={3} />
                                </Grid>

                            </Box>

                            <Box mb={3} />
                        </Grid>
                        <Box mb={5} />


                    </Grid >
                    <Box mb={5} />


                </Container >
            )}


        </>
    )
}
