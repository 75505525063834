import React from 'react'
import { OutlinedInput, InputLabel, FormControl, Select, MenuItem, TextField } from '@mui/material/'
import { useFetch } from '../hooks/useFetch'

function SelectedParent({ parentId, disabled }) {

    const { error, isPending, data } = useFetch(`https://parentapi.bi-oss.co.za/profile/selectedparent/${parentId}`)
    return (
        <FormControl fullWidth>

            {data && data.map(data => (


                <TextField

                    id="parentId"
                    label="Parent / Guardian"
                    InputProps={{
                        readOnly: true,
                    }}
                    value={data.fname + " " + data.lname}
                    fullWidth
                    required
                />

            )



            )}

        </FormControl>
    )
}

export default SelectedParent