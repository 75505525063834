import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../context/AuthContext'
import { useFetch } from '../../hooks/useFetch'
import { useEffect, useState } from 'react'
import { Container, Box, Grid, TextField, Typography, Button, Divider, Card, Paper, CardActions, CardContent } from '@mui/material/'
import DashboardIcon from '@mui/icons-material/Dashboard';
import DataGridView from '../../components/DataGridView';
import SelectedStudent from '../../components/SelectedStudent'
import SelectedCampus from '../../components/SelectedCampus'
import format from 'date-fns/format'
import CloseIcon from '@mui/icons-material/Close';
import { addDays } from 'date-fns'
import { OutlinedInput, InputLabel, FormControl, Select, MenuItem } from '@mui/material/'
import CircularProgress from '@mui/material/CircularProgress';
function ViewExamTimetable({ studentId, setViewTimetable, viewAll }) {

    const [tableData, setTableData] = useState([])

    const { postData, error, errorMessage, isPending, data: resultsData } = useFetch(`https://parentapi.bi-oss.co.za/students/examtimetable/${studentId}`, 'POST')

    useEffect(() => {
        if (studentId && viewAll) {
            postData({
                studentId,
                viewAll



            })
        }
    }, [studentId, viewAll])


    const navigate = useNavigate()

    const classes = {};

    document.title = 'BIC Parent Portal: Examination Timetable';


    useEffect((tableData) => {
        if (resultsData) {
            setTableData(resultsData)

        }


    }, [resultsData])

    const handleOnCellClick = (e) => {
        // window.open(e.row.fileURL, '_blank')
    }

    const openLink = (url) => {
        window.open(url, '_blank')
    }
    const columns = [
        { field: 'examDate', headerName: 'Date:', width: 120, valueFormatter: params => { return format(new Date(params.value), 'dd/MM/yy') } },

        { field: 'session', headerName: 'Session:', width: 120 },
        { field: 'category', headerName: 'Category:', width: 150 },
        { field: 'description', headerName: 'Examination:', width: 250 },
        { field: 'paper', headerName: 'Paper:', width: 200 },
        { field: 'name', headerName: 'Venue:', width: 150 },
    ];

    return (
        <Container maxWidth="lg" >
            <>
                <Box mb={5} />
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} sx={{ border: 'solid', borderWidth: '3px', borderColor: "primary.main", borderRadius: 2 }}>
                    {viewAll === 1 && (
                        < Grid item xs={12}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"

                            >




                                <Typography variant="h6" component="h6" color="primary">
                                    Students Examination Timetable
                                </Typography>

                            </Box>
                        </Grid>
                    )}
                    {viewAll === 2 && (
                        < Grid item xs={12}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"

                            >




                                <Typography variant="h6" component="h6" color="primary">
                                    Students Upcomming Examination Timetable
                                </Typography>

                            </Box>
                        </Grid>
                    )}

                    {!errorMessage && !isPending && studentId && (

                        <>




                            <Grid item xs={12}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        >
                                            <SelectedStudent studentId={studentId} />

                                        </Box>


                                    </Grid>
                                </Box>


                            </Grid>


                        </>
                    )}
                    {isPending && !error && (
                        <>
                            <Grid item xs={12}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >
                                    <Typography variant="h6" component="h3" color="primary">
                                        Fetching timetable data ...
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >
                                    <CircularProgress />
                                </Box>
                            </Grid>
                        </>
                    )}

                    {error && !errorMessage && (
                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"

                            >
                                <Typography variant="h6" component="h3" color="error">
                                    Unable to fetch timetable. You may be offline. Please try again.


                                </Typography>
                            </Box>
                        </Grid>
                    )}



                    {resultsData && resultsData.length === 0 && viewAll === 1 && (
                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"

                            >
                                <Typography variant="body2" component="p" color="primary">
                                    <b>There are currently no examinations timetabled selected student. Please check back at a later stage.</b>
                                </Typography>
                            </Box>
                        </Grid>

                    )}
                    {resultsData && resultsData.length === 0 && viewAll === 2 && (
                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"

                            >
                                <Typography variant="body2" component="p" color="primary">
                                    There are currently no upcomming examinations timetabled selected student. Please check back at a later stage.
                                </Typography>
                            </Box>
                        </Grid>

                    )}
                    {resultsData && resultsData.length > 0 && (
                        <Grid item xs={12} minWidth={0} fullWidth>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"

                            >
                                <DataGridView
                                    fullWidth
                                    tableData={tableData}
                                    error={error} isPending={isPending} getFormattedDate={null} columns={columns} handleOnCellClick={handleOnCellClick}
                                    getRowId={(row) => row.id}
                                    pageSize={20}

                                />
                            </Box>
                        </Grid>

                    )}

                    <Grid item xs={12}>

                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{

                                maxWidth: '95%',
                                marginLeft: 'auto',
                                marginRight: 'auto'
                            }}
                        >
                            <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <Button fullWidth variant="contained" onClick={(e) => setViewTimetable(0)}>Close <CloseIcon /></Button>


                                </Box>

                                <Box mb={3} />
                            </Grid>

                        </Box>

                        <Box mb={3} />
                    </Grid>
                </Grid>
                <Box mb={5} />
            </>
        </Container >
    )
}

export default ViewExamTimetable