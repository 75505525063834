import React, { useState, useEffect } from 'react'
import { useFetch } from '../../hooks/useFetch'
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
    GridHeader,
    GridFooter,
    GridFooterContainer
} from '@mui/x-data-grid';
import format from 'date-fns/format'
import { Container, Box, Grid, TextField, Typography, Button, Divider, Card, Paper, CardActions, CardContent } from '@mui/material/'

function QuoteTotalFooter({ studentId, campusId, sessionId, academicYear }) {


    const [totalDesc, setTotalDesc] = useState('')
    const { postData, error: error2, isPending: isPending2, data: totalData } = useFetch('https://parentapi.bi-oss.co.za/studcambridge/quotetotal', 'POST')


    useEffect(() => {
        if (studentId && campusId && sessionId && academicYear) {
            postData({
                studentId, campusId, sessionId, academicYear
            })
        }
    }, [studentId, campusId, sessionId, academicYear])


    useEffect(() => {
        if (totalData) {
            totalData.map(data => (

                setTotalDesc(data.SumOfexamTotall)

            ))
        }
    }, [totalData])


    return (
        <GridFooterContainer>
            <GridFooter sx={{
                border: 'none', // To delete double border.
            }} />

            {isPending2 && (
                <Typography fullWidth variant="h6" component="h6" color="primary.main" sx={{ textAlign: 'center', marginRight: "50px" }}>Calculating total….</Typography>

            )}

            {!isPending2 && (
                <Typography fullWidth variant="h6" component="h6" color="primary.main" sx={{ textAlign: 'center', marginRight: "50px" }}>Total Due: {totalDesc}</Typography>

            )}





        </GridFooterContainer>
    )
}

export default QuoteTotalFooter