import React from 'react'
import { OutlinedInput, InputLabel, FormControl, Select, MenuItem, TextField } from '@mui/material/'
import { useFetch } from '../hooks/useFetch'

function SelectedVenue({ venueId, disabled }) {

    const { error, isPending, data } = useFetch(`https://parentapi.bi-oss.co.za/shared/venue/${venueId}`)
    return (
        <FormControl fullWidth>

            {data && data.map(data => (


                <TextField

                    id="venudId"
                    label="Writing Location"
                    InputProps={{
                        readOnly: true,
                    }}
                    value={data.venue}
                    fullWidth
                    required
                />

            )



            )}

        </FormControl>
    )
}

export default SelectedVenue