import { auth } from '../firebase/config'
import { signOut } from 'firebase/auth'
import { useAuthContext } from '../hooks/useAuthContext'
import { useNavigate } from 'react-router-dom'
export const useLogout = () => {
    const navigate = useNavigate()
    const { dispatch } = useAuthContext()
    const logout = () => {


        signOut(auth)
            .then(() => {
                navigate('/')

                dispatch({ type: 'LOGOUT' })
                window.location.reload()
            })
            .catch((err) => {
                console.log(err.message)
            })

    }
    return { logout }
}