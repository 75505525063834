import React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import { useNavigate } from 'react-router-dom'
import { useLogout } from '../hooks/useLogout'
import ConstructionIcon from '@mui/icons-material/Construction'
import SchoolIcon from '@mui/icons-material/School'
import AssignmentIcon from '@mui/icons-material/Assignment'
import PersonIcon from '@mui/icons-material/Person'
import SummarizeIcon from '@mui/icons-material/Summarize'
import GradeIcon from '@mui/icons-material/Grade'
import HeadphonesIcon from '@mui/icons-material/Headphones'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import { useAuthContext } from '../hooks/useAuthContext'
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PublicIcon from '@mui/icons-material/Public';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import AssessmentIcon from '@mui/icons-material/Assessment';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { createTheme } from '@mui/material/styles';
import { lime, purple } from '@mui/material/colors';


import QuizIcon from '@mui/icons-material/Quiz';

import SickIcon from '@mui/icons-material/Sick';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import GradingIcon from '@mui/icons-material/Grading';

import AnnouncementIcon from '@mui/icons-material/Announcement';

const ResponsiveAppBar = () => {

    const { user,
        authIsReady,
        parentId, distance, fulltime
    } = useAuthContext()


    const [anchorElNav, setAnchorElNav] = React.useState(null)
    const [anchorElUser, setAnchorElUser] = React.useState(null)

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget)
    }


    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    }


    const navigate = useNavigate()
    const { logout } = useLogout()



    return (

        <AppBar position="static" >
            <Container maxWidth="100%">
                <Toolbar disableGutters>


                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex', l: 'flex', xl: 'flex' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block' },
                            }}
                        >

                            <MenuItem onClick={(event) => [navigate('/'), handleCloseNavMenu()]}>
                                <DashboardIcon />
                                <Typography textAlign="center">Dashboard</Typography>
                            </MenuItem>

                            {fulltime && (
                                <MenuItem onClick={(event) => [navigate('/absence'), handleCloseNavMenu()]}>
                                    <SickIcon />
                                    <Typography textAlign="center">Absence Log</Typography>
                                </MenuItem>

                            )}


                            <MenuItem onClick={(event) => [navigate('/accounts'), handleCloseNavMenu()]}>
                                <AccountBalanceIcon />
                                <Typography textAlign="center">Accounts</Typography>
                            </MenuItem>
                            <MenuItem onClick={(event) => [navigate('/cambridge'), handleCloseNavMenu()]}>
                                <QuizIcon />
                                <Typography textAlign="center">Cambrige Assessments</Typography>
                            </MenuItem>
                            <MenuItem onClick={(event) => [navigate('/comms'), handleCloseNavMenu()]}>
                                <AnnouncementIcon />
                                <Typography textAlign="center">Communications</Typography>
                            </MenuItem>
                            {distance && (
                                <MenuItem onClick={(event) => [navigate('/dcexam'), handleCloseNavMenu()]}>
                                    <QuizIcon />
                                    <Typography textAlign="center">DC Exam</Typography>
                                </MenuItem>

                            )}


                            <MenuItem onClick={(event) => [navigate('/profile'), handleCloseNavMenu()]}>
                                <PersonIcon />
                                <Typography textAlign="center">My Profile</Typography>
                            </MenuItem>

                            {fulltime && (

                                <>



                                    <MenuItem onClick={(event) => [navigate('/timetable'), handleCloseNavMenu()]}>
                                        <CalendarMonthIcon />
                                        <Typography textAlign="center">Timetable (Course/Class)</Typography>
                                    </MenuItem>

                                    <MenuItem onClick={(event) => [navigate('/examtimetable'), handleCloseNavMenu()]}>
                                        <CalendarMonthIcon />
                                        <Typography textAlign="center">Timetable (Examinations)</Typography>
                                    </MenuItem>
                                </>
                            )}

                            {distance && (

                                <MenuItem onClick={(event) => [navigate('/mts'), handleCloseNavMenu()]}>
                                    <CalendarMonthIcon />
                                    <Typography textAlign="center">Timetable (Midterm Seminars)</Typography>
                                </MenuItem>
                            )}


                            <MenuItem onClick={(event) => [navigate('/resources'), handleCloseNavMenu()]}>
                                <CloudDownloadIcon />
                                <Typography textAlign="center">Parent Resources</Typography>
                            </MenuItem>

                            <MenuItem onClick={(event) => [navigate('/registrations'), handleCloseNavMenu()]}>
                                <AppRegistrationIcon />
                                <Typography textAlign="center">Registration Details</Typography>
                            </MenuItem>

                            <MenuItem onClick={(event) => [navigate('/results'), handleCloseNavMenu()]}>
                                <GradingIcon />
                                <Typography textAlign="center">Results</Typography>
                            </MenuItem>

                        </Menu>
                    </Box>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 2, mr: 2, display: { xs: 'none', md: 'flex' } }}
                    >
                        <MenuItem onClick={() => navigate('/')}>
                            <Typography textAlign="center">BI Online Parent Portal</Typography>
                        </MenuItem>
                    </Typography>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 2, mr: 2, display: { xs: 'none', md: 'flex' } }}
                    >
                        <MenuItem onClick={() => navigate('/')}>
                            <Typography textAlign="center">Welcome: {user.displayName} ({user.email})</Typography>
                        </MenuItem>
                    </Typography>

                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 2, mr: 2, display: { xs: 'none', md: 'flex' } }}
                    >
                        <MenuItem onClick={() => navigate('/')}>
                            <Typography textAlign="center"> {new Date().toLocaleDateString('en-GB')} </Typography>
                        </MenuItem>
                    </Typography>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
                    >
                        <MenuItem onClick={() => navigate('/')}>
                            <Typography textAlign="center">BI Online Parent Portal</Typography>
                        </MenuItem>
                    </Typography>
                    <Box sx={{ flexGrow: 3, display: { xs: 'none', md: 'flex' } }}>


                    </Box>

                    <Box sx={{ flexGrow: 0, bgcolor: 'secondary.main', borderRadius: 2 }}>
                        <Tooltip title="Logout">
                            <MenuItem onClick={() => logout()}>
                                <Typography textAlign="center">Logout</Typography>
                            </MenuItem>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >


                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar >
    );
};
export default ResponsiveAppBar