import { useFetch } from '../../../hooks/useFetch'
import { useEffect, useState } from 'react'
import { useAuthContext } from '../../../hooks/useAuthContext'
import { Routes, useParams, Route } from 'react-router-dom'
import { Buffer } from 'buffer';
import { Container, Box, Grid, TextField, Typography, Button, Divider } from '@mui/material/'
import CircularProgress from '@mui/material/CircularProgress';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useNavigate } from 'react-router-dom'

function DCReturn() {


    const { user, parentID } = useAuthContext()
    const { id } = useParams()
    const returnId = Buffer.from(id, 'base64').toString('utf8')
    const navigate = useNavigate()
    const [code, setCode] = useState('')
    const [status, setStatus] = useState('')
    const [studentId, setStudentId] = useState('')
    const [amount, setAmount] = useState(0)
    const { postData, error, errorMessage, data, isPending } = useFetch('https://parentapi.bi-oss.co.za/studcambridge/status', 'POST')


    useEffect(() => {
        if (returnId && parentID) {
            postData({
                id: returnId,
                parentId: parentID
            })
        }
    }, [returnId, parentID])



    useEffect(() => {
        if (data && data.length > 0) {

            data.map(data => (




                setCode(data.transactionCode),
                setStatus(data.transactionStatus),
                setAmount(data.amount),
                setStudentId(data.studentId)



            ))

        }

        if (data && data.length === 0) {
            setStatus(0)
        }
    }, [data])

    return (
        <>

            < Container maxWidth="md"  >

                <Box mb={5} />


                {(status === "3" || status === "4") && (
                    <>
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} sx={{ border: 'solid', borderWidth: '3px', borderColor: 'primary.main', borderRadius: 2 }}>
                            <Grid item xs={12}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <Typography variant="h3" component="h3" color="primary.main" sx={{ textAlign: 'center' }}>
                                        Transaction Cancelled
                                    </Typography>



                                </Box>


                            </Grid>

                            <Grid item xs={12}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>
                                        <b>Response:</b> Transaction cancelled by user.
                                    </Typography>



                                </Box>


                            </Grid>

                            <Grid item xs={12}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        >
                                            <Button fullWidth variant="contained" onClick={(e) => navigate('/')}>Dashboard <DashboardIcon /></Button>


                                        </Box>

                                        <Box mb={3} />
                                    </Grid>

                                </Box>

                                <Box mb={3} />
                            </Grid>

                        </Grid>

                    </>

                )}

                {(status === "0" || status === 0) && (
                    <>
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} sx={{ border: 'solid', borderWidth: '3px', borderColor: 'primary.main', borderRadius: 2 }}>
                            <Grid item xs={12}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <Typography variant="h3" component="h3" color="primary.main" sx={{ textAlign: 'center' }}>
                                        Unprocessed Transactions
                                    </Typography>



                                </Box>


                            </Grid>

                            <Grid item xs={12}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <Typography variant="h6" component="h6" color="primary.main" sx={{ textAlign: 'center' }}>
                                        Distance College Examination Entry
                                    </Typography>



                                </Box>


                            </Grid>
                            <Grid item xs={12}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>
                                        Please note, your banking provider has sent us the above response and terminated the transaction. For more information, please contact your banking provider.
                                    </Typography>



                                </Box>


                            </Grid>
                            <Grid item xs={12}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        >
                                            <Button fullWidth variant="contained" onClick={(e) => navigate('/')}>Dashboard <DashboardIcon /></Button>


                                        </Box>

                                        <Box mb={3} />
                                    </Grid>

                                </Box>

                                <Box mb={3} />
                            </Grid>

                        </Grid>

                    </>

                )}

                {status === "2" && (
                    <>
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} sx={{ border: 'solid', borderWidth: '3px', borderColor: 'primary.main', borderRadius: 2 }}>
                            <Grid item xs={12}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <Typography variant="h3" component="h3" color="error.main" sx={{ textAlign: 'center' }}>
                                        Transaction Declined
                                    </Typography>



                                </Box>


                            </Grid>

                            <Grid item xs={12}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <Typography variant="h6" component="h6" color="primary.main" sx={{ textAlign: 'center' }}>
                                        Distance College Examination Entry
                                    </Typography>



                                </Box>


                            </Grid>




                            {code !== "900003" && status === "2" && code !== "900007" && code !== "900004" && (

                                <Grid item xs={12}>

                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{

                                            maxWidth: '95%',
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}
                                    >


                                        <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>
                                            Please note, your banking provider has declined the transaction. For more information, please contact your banking provider.
                                        </Typography>
                                    </Box>


                                </Grid>
                            )}
                            {code === "900004" && status === "2" && (

                                <>
                                    <Grid item xs={12}>

                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        >
                                            <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>
                                                <b>Response:</b> Invalid Card Number
                                            </Typography>



                                        </Box>


                                    </Grid>
                                    <Grid item xs={12}>

                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        >
                                            <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>
                                                Please note, your banking provider has sent us the above response and terminated the transaction. For more information about the cancelled transaction, please contact your banking provider.
                                            </Typography>



                                        </Box>


                                    </Grid>

                                </>
                            )}

                            {code === "900007" && status === "2" && (

                                <>

                                    <Grid item xs={12}>

                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        >
                                            <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>
                                                Please note, your banking provider has sent us the above response and terminated the transaction. For more information about the cancelled transaction, please contact your banking provider.
                                            </Typography>



                                        </Box>


                                    </Grid>
                                </>
                            )}


                            {code === "900003" && status === "2" && (
                                <>

                                    <Grid item xs={12}>

                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        >
                                            <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>
                                                <b>Response:</b> Insufficient Funds
                                            </Typography>



                                        </Box>


                                    </Grid>
                                    <Grid item xs={12}>

                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        >
                                            <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>
                                                Please note, your banking provider has sent us the above response and terminated the transaction. For more information about the cancelled transaction, please contact your banking provider.
                                            </Typography>



                                        </Box>


                                    </Grid>
                                </>
                            )}

                            <Grid item xs={12}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        >
                                            <Button fullWidth variant="contained" onClick={(e) => navigate('/')}>Dashboard <DashboardIcon /></Button>


                                        </Box>

                                        <Box mb={3} />
                                    </Grid>

                                </Box>

                                <Box mb={3} />
                            </Grid>

                        </Grid>

                    </>

                )}


                {status === "1" && (

                    <>
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} sx={{ border: 'solid', borderWidth: '3px', borderColor: 'primary.main', borderRadius: 2 }}>
                            <Grid item xs={12}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <Typography variant="h3" component="h3" color="success.main" sx={{ textAlign: 'center' }}>
                                        Transaction Approved
                                    </Typography>



                                </Box>


                            </Grid>
                            <Grid item xs={12}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>
                                        Thank you for the payment. An email containing the transactions details and confirmation of entry has been sent to your registered address.

                                    </Typography>



                                </Box>


                            </Grid>
                            <Grid item xs={12}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{

                                                maxWidth: '95%',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        >
                                            <Button fullWidth variant="contained" onClick={(e) => navigate('/')}>Dashboard <DashboardIcon /></Button>


                                        </Box>

                                        <Box mb={3} />
                                    </Grid>

                                </Box>

                                <Box mb={3} />
                            </Grid>

                        </Grid>


                    </>
                )}


                {isPending && (



                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} sx={{ border: 'solid', borderWidth: '3px', borderColor: 'primary.main', borderRadius: 2 }}>

                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="h3" component="h3" color="primary.main" sx={{ textAlign: 'center' }}>
                                    Checking payment status ...
                                </Typography>



                            </Box>


                        </Grid>
                        <Grid item xs={12}>

                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                sx={{

                                    maxWidth: '95%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                <Typography variant="h3" component="h3" color="primary.main" sx={{ textAlign: 'center' }}>
                                    <CircularProgress />
                                </Typography>



                            </Box>


                        </Grid>
                    </Grid>

                )}

            </Container>
        </>
    )
}

export default DCReturn