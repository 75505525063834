import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../../hooks/useAuthContext'
import { useFetch } from '../../../hooks/useFetch'
import { useEffect, useState } from 'react'
import { Container, Box, Grid, TextField, Typography, Button, Divider } from '@mui/material/'
import format from 'date-fns/format'
import EditIcon from '@mui/icons-material/Edit';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DataGridView from '../../../components/DataGridView';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';
function TransactionLog() {


    const { user, accessLevel, parentID, token } = useAuthContext()
    const [edit, setEdit] = useState(0)
    const [change, setChange] = useState(0)
    const [tableData, setTableData] = useState([])

    const { error, isPending, data: commsData } = useFetch(`https://parentapi.bi-oss.co.za/tuitionorders/orderlist/` + parentID)
    const navigate = useNavigate()



    useEffect((tableData) => {
        if (commsData) {
            setTableData(commsData)

        }


    }, [commsData])


    const handleOnCellClick = (e) => {
        // window.open(e.row.fileURL, '_blank')
    }

    const columns = [

        { field: 'createdOn', headerName: 'Date and Time:', width: 150, valueFormatter: params => { return format(new Date(params.value), 'dd/MM/yy - hh:mm') } },

        { field: 'amount', headerName: 'Amount:', width: 150, editable: true },

        { field: 'type', headerName: 'Type:', width: 200, editable: true },

        {
            field: "",
            headerName: "Status:",
            width: 150,
            renderCell: (params) => {

                if (params.row.paid === 0 && params.row.failed === 0 && params.row.cancelled === 0) {
                    return <TextField

                        id="accStatus"
                        InputProps={{
                            readOnly: true,

                        }}
                        value="Unprocessed"
                        fullWidth
                        required
                        sx={{ borderRadius: 1, input: { color: 'primary.main' } }}
                    />;
                }

                if (params.row.paid === 1) {
                    return <TextField

                        id="accStatus"
                        InputProps={{
                            readOnly: true,

                        }}
                        value="Paid"
                        fullWidth
                        required
                        sx={{ borderRadius: 1, input: { color: 'success.main' } }}
                    />;
                }

                if (params.row.failed === 1) {
                    return <TextField

                        id="accStatus"
                        InputProps={{
                            readOnly: true,

                        }}
                        value="Failed"
                        fullWidth
                        required
                        sx={{ borderRadius: 1, input: { color: 'error.main' } }}
                    />;
                }

                if (params.row.cancelled === 1) {
                    return <TextField

                        id="accStatus"
                        InputProps={{
                            readOnly: true,

                        }}
                        value="Cancelled"
                        fullWidth
                        required
                        sx={{ borderRadius: 1, input: { color: 'primary.main' } }}
                    />;
                }
            }

        },

        { field: 'academicYear', headerName: 'Academic Year:', width: 120, editable: true },
        { field: 'campus', headerName: 'Campus/School:', width: 200, editable: true },
        { field: 'paymentReqId', headerName: 'Transactions ID:', width: 320, editable: true },

    ];

    return (
        <>
            <Container maxWidth="md" >

                <Box mb={5} />
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} sx={{ border: 'solid', borderWidth: '3px', borderColor: "primary.main", borderRadius: 2 }}>


                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"

                        >
                            <Typography variant="h3" component="h3" color="primary.main" sx={{ textAlign: 'center' }}>
                                Online Transaction Log
                            </Typography>
                        </Box>
                    </Grid>


                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"

                        >
                            <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>
                                See below list of your online payments. Each transaction will be listed below with the associated status information. Please note, you can only view your online transaction log. No onsite credit/debit card or manual eft payments will display in this log.
                            </Typography>
                        </Box>
                    </Grid>



                    {isPending && (

                        <>
                            <Grid item xs={12}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >
                                    <Typography variant="h6" component="h3" color="primary">
                                        Fetching transaction logs ...
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} >
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >

                                    <CircularProgress />
                                    <Typography variant="h6" component="h3" color="secondary">

                                    </Typography>

                                </Box>
                            </Grid>

                        </>
                    )}
                    {error && (
                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"

                            >
                                <Typography variant="h6" component="h3" color="error">
                                    Unable to fetch transaction logs. You may be offline. Please try again.


                                </Typography>
                            </Box>
                        </Grid>
                    )}

                    {!isPending && !error && (
                        <>
                            <DataGridView

                                tableData={tableData}
                                error={error} isPending={isPending} getFormattedDate={null} columns={columns} pageSize={10} handleOnCellClick={handleOnCellClick}
                                getRowId={(row) => row.id}
                            />

                            <Grid item xs={12}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"

                                >
                                    <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>
                                        <b>Transaction Status.</b>
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box
                                    display="flex"
                                    justifyContent="left"
                                    alignItems="left"

                                >
                                    <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'left' }}>
                                        <b>Unprocessed</b>: These payment was <u>not</u> initiated. No funds transfered.
                                    </Typography>

                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box
                                    display="flex"
                                    justifyContent="left"
                                    alignItems="left"

                                >

                                    <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'left' }}>
                                        <b>Cancelled</b>: The transaction has either been cancelled by your banking provider or you have cancelled the transaction during confirmation or payment authorisation.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box
                                    display="flex"
                                    justifyContent="left"
                                    alignItems="left"

                                >

                                    <Typography variant="body2" component="p" color="error.main" sx={{ textAlign: 'left' }}>
                                        <b>Failed</b>: The transaction failed. No funds were transferred. Please contact your banking provider for more information.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box
                                    display="flex"
                                    justifyContent="left"
                                    alignItems="left"

                                >

                                    <Typography variant="body2" component="p" color="success.main" sx={{ textAlign: 'left' }}>
                                        <b>Paid</b>: The transaction has been initiated and payment is underway. Please wait for payment verification.
                                    </Typography>
                                </Box>
                            </Grid>

                        </>

                    )}
                    <Grid item xs={12}>

                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{

                                maxWidth: '95%',
                                marginLeft: 'auto',
                                marginRight: 'auto'
                            }}
                        >
                            <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{

                                        maxWidth: '95%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto'
                                    }}
                                >
                                    <Button fullWidth variant="contained" onClick={(e) => navigate(-1)}><ArrowBackIcon /> Back</Button>


                                </Box>

                                <Box mb={3} />
                            </Grid>

                        </Box>

                        <Box mb={3} />
                    </Grid>
                    <Box mb={5} />
                </Grid>
                <Box mb={5} />
            </Container >

        </>
    )
}

export default TransactionLog