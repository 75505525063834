import { useAuthContext } from '../../../hooks/useAuthContext'
import DashCard from '../../../components/DashCard'
import { Container, Box, Grid, Typography } from '@mui/material'
import DashboardIcon from '@mui/icons-material/Dashboard';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import { useEffect } from 'react'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import ApprovalIcon from '@mui/icons-material/Approval';
function CamDash() {
    document.title = 'BIC Parent Portal: Cambridge External Assessment';

    return (
        <Container maxWidth="xl">

            <Box mb={3} />



            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }}>

                <Grid item xs={12} >
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"

                    >
                        <Typography variant="h3" component="h3" color="primary.main" sx={{ textAlign: 'center' }}>
                            Cambridge External Assessment Dashboard
                        </Typography>
                    </Box>
                </Grid>




                <Grid item xs={4} sm={4} md={4} lg={4} xl={3}>

                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"

                    >
                        <DashCard link={"/cambridge/apply"} cardName={"Entry Application"} cardIcon={<BookOnlineIcon />} />
                    </Box>


                </Grid>

                <Grid item xs={4} sm={4} md={4} lg={4} xl={3}>

                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"

                    >
                        <DashCard link={"/cambridge/status"} cardName={"Entry Status"} cardIcon={<ApprovalIcon />} />
                    </Box>


                </Grid>







                <Grid item xs={4} sm={4} md={4} lg={4} xl={3}>

                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"

                    >
                        <DashCard link={"/"} cardName={"Dashboard"} cardIcon={<DashboardIcon />} />
                    </Box>

                </Grid>
            </Grid>

            <Box mb={5} />



        </Container >
    )
}

export default CamDash