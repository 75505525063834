import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../../hooks/useAuthContext'
import { useFetch } from '../../../hooks/useFetch'
import { useEffect, useState } from 'react'
import { Container, Box, Grid, TextField, Typography, Button, Divider } from '@mui/material/'
import DashboardIcon from '@mui/icons-material/Dashboard';
import SelectStudent from '../../../components/SelectStudent'
import { NumericFormat } from 'react-number-format';
import SendIcon from '@mui/icons-material/Send';
import SelectedStudent from '../../../components/SelectedStudent'
import CircularProgress from '@mui/material/CircularProgress';
import CancelIcon from '@mui/icons-material/Cancel';
import { ElectricScooterSharp } from '@mui/icons-material'
import SelectCambridgeExamSession from '../../../components/SelectCambridgeExamSession'
import ViewEntryStatus from '../../../components/cambridge/ViewEntryStatus'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
function CamEntryStatus() {

    const [studentId, setStudentId] = useState('')
    const [sessionId, setSessionId] = useState('')
    const navigate = useNavigate()
    const [processEntry, setProcessEntry] = useState(0)


    return (
        < Container maxWidth="lg"  >

            <Box mb={5} />
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }} sx={{ border: 'solid', borderWidth: '3px', borderColor: 'primary.main', borderRadius: 2 }}>

                <Grid item xs={12}>

                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{

                            maxWidth: '95%',
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}
                    >
                        <Typography variant="h3" component="h3" color="primary.main" sx={{ textAlign: 'center' }}>
                            Camrbidge Application Status
                        </Typography>



                    </Box>


                </Grid>
                <Grid item xs={12}>

                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{

                            maxWidth: '95%',
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}
                    >
                        <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>
                            Parents can view a linked students Cambridge External Assessment entry application status below
                        </Typography>



                    </Box>


                </Grid>

                <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{

                            maxWidth: '95%',
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}
                    >
                        <SelectStudent studentId={studentId} setStudentId={setStudentId} />


                    </Box>

                </Grid>




                <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{

                            maxWidth: '95%',
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}
                    >
                        <SelectCambridgeExamSession sessionId={sessionId} setSessionId={setSessionId} camOnly={1} />


                    </Box>

                </Grid>

                {(!studentId || !sessionId) && (



                    <Grid item xs={12}>

                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{

                                maxWidth: '95%',
                                marginLeft: 'auto',
                                marginRight: 'auto'
                            }}
                        >
                            <Typography variant="body2" component="p" color="primary.main" sx={{ textAlign: 'center' }}>
                                Select a student and associated examination session.
                            </Typography>



                        </Box>


                    </Grid>
                )}
                {studentId && sessionId && (



                    <ViewEntryStatus setProcessEntry={setProcessEntry} studentId={studentId} sessionId={sessionId} />
                )}

                <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{

                            maxWidth: '95%',
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}
                    >
                        <Button fullWidth variant="contained" onClick={(e) => navigate('/cambridge')}><ArrowBackIcon /> Back</Button>




                    </Box>
                    <Box mb={5} />
                </Grid>
                <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>

                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{

                            maxWidth: '95%',
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}
                    >

                        {processEntry === 0 && (
                            <Button fullWidth variant="contained" disabled>Process Entry<DashboardIcon /></Button>



                        )}
                        {processEntry > 0 && (
                            <Button fullWidth variant="contained" onClick={(e) => navigate('/cambridge/apply')}>Process Entry<DashboardIcon /></Button>



                        )}



                    </Box>

                </Grid>

            </Grid>

            <Box mb={5} />
        </Container>
    )
}

export default CamEntryStatus